import { tw } from "./tw";
import { us } from "./us";

export const macro = {
  zh: {
    us: us.zh,
    tw: tw.zh,
  },
  en: {
    us: us.en,
    tw: tw.en,
  },
};
