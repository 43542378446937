export const gaugeText = {
  zh: {
    value: ["低", "高"],
    trend: ["空", "多"],
    swing: ["弱", "強"],
    chip: ["弱", "強"],
    dividend: ["低", "高"],
  },
  en: {
    value: ["Low", "High"],
    trend: ["Short", "Long"],
    swing: ["Weak", "Strong"],
    chip: ["Low", "High"],
    dividend: ["Low", "High"],
  },
};
