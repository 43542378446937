import { LatestStatus } from "@/features/analysis/chip/tw/institutional-investors/services/institutional-investors-historical-status";
import { Lang } from "@/i18n-config";

export const buyingSellingLatestStatus: {
  [key in Lang]: Record<keyof LatestStatus, string>;
} = {
  zh: {
    dealersBuyingDays: "自營商",
    dealersSum: "自營商",
    foreignBuyingDays: "外資",
    foreignSum: "外資",
    trustBuyingDays: "投信",
    trustSum: "投信",
  },
  en: {
    dealersBuyingDays: "Dealers",
    dealersSum: "Dealers",
    foreignBuyingDays: "Foreign Investors",
    foreignSum: "Foreign Investors",
    trustBuyingDays: "Investment Trust",
    trustSum: "Investment Trust",
  },
};
