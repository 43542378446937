import { factor } from "./factor";
import { forecast } from "./forecast";
import { earnings } from "./revenue";

export const value = {
  zh: {
    unit: "單位",
    forceInfo: [
      "價值投資專注於選擇財務穩健、有持續穩定獲利能力的上市公司。當股價低於投資人認定的內含價值時，不論股價如何波動，一律持續進行買入的動作。這樣的投資方法相當考驗投資人的耐心以及逢低加碼的紀律，看似簡單的操作方法，對投資人心理往往是很大的挑戰。",

      "而 Growin 的價值力道分數協助投資人判斷股票擁有的價值多寡。搜集各產業的財報資訊，分析的數據包含公司的財務面、資金運用、盈利能力以及股東權益等財務指標。透過 AI 演算法綜合各種數據對公司未來成長的潛力給予評分，分數越高代表演算法判斷個股具有較深的價值，對未來的成長性高;反之，則是認為公司股價較不符合價值投資的條件。",

      "請注意：財報數據屬於較長期的指標，較適合長期投資人參考。一切資訊僅為輔助使用，投資人應建立適合自己的進場、出場、停損的策略來進行投資。",
    ],
    factor: factor.zh,
    forecast: forecast.zh,
    earnings: earnings.zh,
  },
  en: {
    unit: "Unit",
    forceInfo: [
      "Value investing focuses on selecting companies that demonstrate financial stability and sustained profitability. When stock prices fall below the investor's perceived intrinsic value, they continue to buy regardless of price fluctuations. This investment approach tests an investor's patience and discipline to consistently buy at lower prices, presenting a significant psychological challenge despite its seemingly straightforward execution.",

      "Growin's Value Score aids investors in assessing the relative value of stocks. By collecting financial data from various industries, the analysis includes metrics related to a company's financials, capital utilization, profitability, and shareholder equity. Through AI algorithms, the data is synthesized to assign a score reflecting the potential for the company's future growth. A higher score indicates a deeper perceived value and greater future growth potential according to the algorithm, while a lower score suggests that the company's stock price may not align well with the conditions of value investing.",

      "Note: Financial data pertains to longer-term indicators and is better suited for long-term investors. All information is meant for supplementary use only, and investors should establish their own strategies for entry, exit, and setting stop-loss levels based on their individual preferences when making investment decisions.",
    ],
    factor: factor.en,
    forecast: forecast.en,
    earnings: earnings.en,
  },
};
