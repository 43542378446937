export const form13F = {
  zh: {
    title: {
      overview: "13F 報告 | 13F 機構持倉 | 美股 13F 報告查詢 - Growin",
      investor: (investorName: string) =>
        `${investorName} | 最新持股分析 | 13F 報告 - Growin`,
    },
    description: {
      overview:
        "【13F 報告、13F 機構持倉】查詢：13F 報告是由美國證券交易委員會（SEC）要求管理資產超過 1 億美元的投資公司每季度提交的一份文件，列出投資公司持有股票、債券、期權等價值超過 1 億美元的證券投資組合。",
      investor: (investorName: string, investorNameEn?: string) =>
        `【${investorName} (${investorNameEn}) 13F 報告】${investorName}持股：包含公司介紹、持倉、績效推估、本季 13F 報告重點摘要、金額/個股季度變化數據、投資金額變化 Top5 等資訊。`,
    },
  },
  en: {
    title: {
      overview: "13F Fillings | Key Points from Recent Fillings - Growin",
      investor: (investorNameEn: string) =>
        `${investorNameEn} Portfolio | Summary of Latest Holding - Growin`,
    },
    description: {
      overview:
        "Unlock the secrets behind 13F filings, shedding light on institutional investment strategies and revealing the movements of market giants.",
      investor: (investorNameEn: string) =>
        `Detailed Profile of ${investorNameEn}, including Key Takeaway, Chg. of Investment AUM Top5, Estimated Cost of Top 20.`,
    },
  },
};
