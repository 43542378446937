export const myFooter = {
  zh: {
    disclaimer:
      "本免責聲明由 TradingValley Inc.所提供，包含本公司網站、應用程式「Growin App」以及通過本公司網站提供的其他服務的任何消息、新聞、研究、分析、價格或其他訊息提供，僅是作為教育及投資決策輔助參考之一般市場資訊，不構成任何投資建議。",
    disclaimerLink: "查看 Growin 免責說明",
  },
  en: {
    disclaimer:
      "This disclaimer is provided by TradingValley Inc. and includes any messages, news, research, analysis, prices or other information provided by the Company's website, the application \"Growin App\" and other services provided through the Company's website. It is only general market information for educational and investment decision-making reference, and does not constitute any investment advice.",
    disclaimerLink: " View Growin Disclaimer",
  },
};
