import { fundingLevelSimulation } from "./funding-level-simulation";
import { historicalSignal } from "./historical-signal";
import { indicator } from "./indicator";
import { layout } from "./layout";

export const warningIndicator = {
  zh: {
    layout: layout.zh,
    historicalSignal: historicalSignal.zh,
    fundingLevelSimulation: fundingLevelSimulation.zh,
    indicator: indicator.zh,
  },
  en: {
    layout: layout.en,
    historicalSignal: historicalSignal.en,
    fundingLevelSimulation: fundingLevelSimulation.en,
    indicator: indicator.en,
  },
};
