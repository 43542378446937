export const event = {
  zh: {
    title: "透過 AI 投資顧問 WinwinGPT，快速取得股市資訊！",
    description:
      "透過 Growin 的 AI 投資顧問 WinwinGPT，您可以獲取最新美國上市公司的財報分析資訊及股價走勢。WinwinGPT 搭載了 ChatGPT，可為您提供精準財報分析、實時股價走勢、人性化的互動體驗。",
    addToQueue: "加入我們",
    addToQueueImmediately: "立即加入排隊！ 體驗 WinwinGPT ",
    congratulation:
      "恭喜！您已排入 WinwinGPT 的 Waitlist 中囉！開放時會再以 Email 通知您！",
  },
  en: {
    title:
      "Get Stock Market Insights Easily with AI Investment Advisor WinwinGPT",
    description:
      "With Growin's AI investment advisor WinwinGPT, you can access the latest financial analysis and stock price trends for US-listed companies. WinwinGPT is powered by ChatGPT, providing you with accurate financial analysis, stock price trends analysis and Humanized interactive experience. ",
    addToQueue: "Join Us",
    addToQueueImmediately: "Join the waitlist now!",
    congratulation:
      "As a paying user, you can skip the waitlist and access WinwinGPT directly upon its launch!",
  },
};
