export const error = {
  zh: {
    somethingWentWrong: "回答問題時發生錯誤，是否要再詢問一次？",
    contactUs: "聯絡 Growin 團隊",
    retry: "好，再試一次",
  },
  en: {
    somethingWentWrong:
      "Something went wrong when answering the question. Would you like to try again?",
    contactUs: "Contact Us",
    retry: "Try Again",
  },
};
