export const investStyleDialog = {
  zh: {
    title: "投資風格說明",
    content: `為了提供更符合您投資決策時所需要的客製化內容，WinwinGPT 會依據您詢問的問題情境，<span style='color: #16A34AE6; font-weight: 600;'>判斷是否適合帶入您選擇的投資風格</span>，會根據投資風格客製化去回覆您的問題（當然，您也可不帶此設定）。關於投資風格種類簡易的解釋如下：<br><br>
- **長線投資**：專注於尋找被低估的股票並長期持有。
- **趨勢動能**：順勢而為，把握大趨勢，獲取大賺小賠的收益。
- **波段訊號**：捕捉市場價格波動，透過技術分析抓住短中線獲利。
- **存股升息**：長期持有，穩定收益，複利增長。<br><br>

更多投資風格的詳細介紹，可以參考 <a href='https://blog.growin.tv/growin-investment-style-introduction/' target='_blank' style='color: #0084C6; font-weight: 600;'>Growin 部落格文章。</a><br>
WinwinGPT 可能提供不準確資訊，請核對答覆。查看 <a href='https://file.growin.tv/policy/terms-and-conditions.html' target='_blank' style='color: #0084C6; font-weight: 600;'>Growin 免責聲明</a>`,
    close: "關閉",
  },
  en: {
    title: "Investment Style Instructions",
    content: `To provide more tailored content that aligns with your investment decision-making needs, <span style='color: #16A34AE6; font-weight: 600;'> WinwinGPT will assess whether incorporating your selected investment style is suitable based on the context of your inquiries.</span> It will customize its responses to your questions in accordance with your investment style (naturally, you can opt out of this setting). Here's a simplified explanation of the various investment styles:<br><br>
- **Long-term investment**: Focus on finding undervalued stocks and holding them for the long term.
- **Trend momentum**: Follow the trend, grasp the big trend, and get big profits and small losses.
- **Swing signal**: Capture market price fluctuations and use technical analysis to capture short-term and medium-term profits.
- **Incoming investing**: Hold for a long time, stable income, compound growth.<br><br>

For more detailed descriptions of different investment styles, please refer to the <a href='https://blog.growin.tv/growin-investment-style-introduction/' target='_blank' style='color: #0084C6; font-weight: 600;'>Growin blog article</a><br>
WinwinGPT may provide inaccurate information, please verify the answers. View <a href='https://file.growin.tv/policy/terms-and-conditions.html' target='_blank' style='color: #0084C6; font-weight: 600;'>Growin Disclaimer</a>`,
    close: "Close",
  },
};
