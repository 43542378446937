import { factor } from "./factor";
import { insider } from "./insider";
import { insiderHolding } from "./insider-holding";
import { institutionalInvestors } from "./institutional-investors";
import { investmentFirms13F } from "./investment-firms-13f";
import { marginTransactions } from "./margin-transactions";
import { shareholderDiversification } from "./shareholder-diversification";

export const chip = {
  zh: {
    forceInfo: [
      "正所謂新手看價、老手看量、高手看籌碼，參考籌碼分析讓投資人可以跟著大型機構的資金佈局，站在巨人的肩膀上找尋投資機會。大型機構擁有的專業技術團隊以及散戶投資人難以取得的大數據資料庫，是不可忽視的優勢。根據美國法規，大型機構需要定期繳交 13F 持倉報告，",

      "Growin 的 AI 籌碼力道，便是透過判斷大型機構籌碼的分配，為每檔股票進行評分。我們從全市場的標的池中，找出機構籌碼的流向，籌碼越集中的標的，通常也擁有比較高的報酬和比較小的風險。此外，我們也透過智能演算法，以籌碼面的數據預測標的未來是否高機率擁有超額報酬。",

      "請注意：此籌碼力道的評分僅為輔助使用，投資人應根據自己的投資風險，建立適合自己的進場、出場、停損的策略來進行投資。",
    ],
    factor: factor.zh,
    investmentFirms13F: investmentFirms13F.zh,
    insider: insider.zh,
    institutionalInvestors: institutionalInvestors.zh,
    shareholderDiversification: shareholderDiversification.zh,
    insiderHolding: insiderHolding.zh,
    marginTransactions: marginTransactions.zh,
  },
  en: {
    forceInfo: [
      "As the saying goes, beginners focus on price action, experienced traders watch volume, and experts analyze whale investor actions. Referring to whale investor actions allows investors to align with the capital deployment strategies of large institutions, enabling them to discover investment opportunities from the perspective of market giants. The professional technical teams and extensive databases that large institutions possess, which are not accessible to retail investors, are undeniable advantages.",

      "Growin's Whale Interest Score evaluates each stock by interpreting the distribution of capital by large institutions. From the entire market's pool of assets, we identify the movement of institutional capital. Assets with more concentrated capital distribution often yield higher returns and come with lower risks. Furthermore, our AI algorithms employ 13F data to predict the likelihood of assets outperforming in the future.",

      "Note: The provided Whale Interest Score is intended for supplementary use only. Investors should create their own strategies for entry, exit, and stop-loss levels based on their individual risk tolerance when making investment decisions.",
    ],
    factor: factor.en,
    investmentFirms13F: investmentFirms13F.en,
    insider: insider.en,
    institutionalInvestors: institutionalInvestors.en,
    shareholderDiversification: shareholderDiversification.en,
    insiderHolding: insiderHolding.en,
    marginTransactions: marginTransactions.en,
  },
};
