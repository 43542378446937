export const search = {
  zh: {
    title: "歡迎使用 AI 台、美股分析",
    description:
      "AI 演算法 x 大數據，數據導向個股分析系統：五大面向表現立即檢視！",
    placeHolder: "搜尋您感興趣的標的代碼，例如 : AAPL",
    searchSymbol: "搜尋標的",
    symbol: "標的",
    companyName: "公司名稱",
    classesAndCountry: "類股與國家",
    add: "加入追蹤",
    addToList: "加入清單",
    added: "已追蹤",
    noResultsFound: "查無結果",
    noSymbolsFound: "查無符合搜尋條件的標的，請重新輸入搜尋條件。",
  },
  en: {
    title: "Growin AI Stock Analysis",
    description:
      "AI Algorithm x Big Data, A Brand New Data-Driven Stock Analysis System",
    placeHolder: "Enter Symbol or Company Name",
    searchSymbol: "Search",
    symbol: "Symbol",
    companyName: "Company Name",
    classesAndCountry: "Sector & Country",
    add: "Watchlist",
    addToList: "Add",
    added: "Following",
    noResultsFound: "No results found",
    noSymbolsFound: "No symbols found for this search term. Please try again.",
  },
};
