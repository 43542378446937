export const investStyle = {
  zh: {
    value: "價值投資",
    trend: "趨勢投資",
    swing: "波段投資",
    dividend: "股息投資",
  },
  en: {
    value: "Long-term investment",
    trend: "Trend momentum",
    swing: "Swing signal",
    dividend: "Incoming investing",
  },
};
