export const winwingpt = {
  zh: {
    title: "Growin WinwinGPT｜AI 投資分析助手",
    description:
      "透過 Growin 的 AI 投資顧問 WinwinGPT，您可以獲取最新美國上市公司的財報分析資訊及股價走勢。WinwinGPT 搭載了 ChatGPT，可為您提供精準財報分析、實時股價走勢、人性化的互動體驗。",
  },
  en: {
    title: "Growin WinwinGPT | AI Investment Analysis Assistant",
    description:
      "With Growin's AI investment advisor WinwinGPT, you can get the latest financial analysis information and stock price trends of US-listed companies. WinwinGPT is equipped with ChatGPT, which can provide you with accurate financial analysis, real-time stock price trends, and a humanized interactive experience.",
  },
};
