export const logs = {
  zh: {
    signup: "前往註冊建立第一隻推播策略",
    readAll: "全部已讀",
    empty: "目前尚無觸發紀錄",
    tableCols: {
      type: "策略類型",
      triggerDate: "觸發日期",
      conditions: "條件設定",
      triggerSymbols: "觸發標的",
      details: "策略細節",
    },
    tableRows: {
      buyIn: "進場",
      sellOut: "出場",
    },
    dialog: {
      title: "觸發標的細節",
      date: "觸發日期：",
      notify: "通知方式：",
      condition: "條件設定：",
      name: "策略名稱：",
      symbols: "觸發標的",
      details: "策略細節",
      fiveDimension: "五力分數",
      techIndicatorDay: "技術指標（日）",
      techIndicatorWeek: "技術指標（週）",
      others: "其他",
    },
  },
  en: {
    signup: "Login to create your first notification!",
    readAll: "Mark all as read",
    empty: "There is no trigger record.",
    tableCols: {
      type: "Type",
      triggerDate: "Trigger Date",
      conditions: "Conditions",
      triggerSymbols: "Trigger Symbols",
      details: "Details",
    },
    tableRows: {
      buyIn: "Buy In",
      sellOut: "Sell Out",
    },
    dialog: {
      title: "Details",
      date: "Trigger Date: ",
      notify: "Notified by: ",
      condition: "Conditions: ",
      name: "Robot Name: ",
      symbols: "Trigger Symbols",
      details: "Details",
      fiveDimension: "5 Dimension",
      techIndicatorDay: "Technical Indicator (Day)",
      techIndicatorWeek: "Technical Indicator (Week)",
      others: "Others",
    },
  },
};
