import { patternInfo } from "./pattern-info";
import { strategy } from "./strategy";

export const screener = {
  zh: {
    title: "股票篩選器",
    symbolClass: {
      sector: "板塊",
      industry: "產業",
      marketCap: ({ currency }: { currency: string }) => `市值 (${currency})`,
      volume: "20 日均量",
    },
    day: "日",
    week: "週",
    month: "月",
    reset: "條件重置",
    search: "開始搜尋",
    power: "能量強度",
    powers: ["無累積", "弱", "中", "強"],
    cellPowers: ["無", "弱", "中", "強"],
    rise: "處於上漲",
    fall: "處於下跌",
    reversionUp: "跌深反彈",
    revertDown: "漲多拉回",
    resultTitle: ({ total }: { total: number }) =>
      `篩選結果（共 ${total} 個符合）`,
    noResult: "沒有符合篩選條件的股票",
    symbol: "股票代碼",
    squeezeDay: "Squeeze 日",
    squeezeWeek: "Squeeze 週",
    squeezeMonth: "Squeeze 月",
    surfingTrendDay: "Surfing Trend 日",
    surfingTrendWeek: "Surfing Trend 週",
    surfingTrendMonth: "Surfing Trend 月",
    dailyGrowinMeanRevertingRadar: "Mean Reverting Radar 日",
    weeklyGrowinMeanRevertingRadar: "Mean Reverting Radar 週",
    monthlyGrowinMeanRevertingRadar: "Mean Reverting Radar 月",
    addToWatchlist: "追蹤",
    volumeHeader: "20 日均量",
    tradingVolume: "成交金額 (TWD)",
    companyName: "公司名稱",
    macro: "總體面",
    fundamental: "基本面",
    technical: "技術面",
    pattern: "型態",
    patterns: "型態",
    growinIndicators: "Growin 獨家指標",
    gdp: "GDP",
    interestRate: "利率",
    inflation: "通膨",
    unemployment: "失業率",
    economicGrowth: "經濟成長",
    marketCap: ({ currency }: { currency: string }) => `市值 (${currency})`,
    sector: "板塊",
    industry: "產業",
    shortInterest: "Short Interest",
    analystRating: "分析師評級",
    rsRating: "RS Rating (Relative Strength)",
    selectAnOption: "請選擇",
    filterCriteriaAmount: (total: number) => `篩選條件（已選 ${total} 個條件）`,
    usStock: "美股",
    twStock: "台股",
    dailyPrice: "日線",
    weeklyPrice: "週線",
    monthlyPrice: "月線",
    bullish: "看多",
    bearish: "看空",
    patternInfo: patternInfo.zh,
    strategy: strategy.zh,
    selected: "已選條件",
  },
  en: {
    title: "Stock Screener",
    symbolClass: {
      sector: "Sector",
      industry: "Industry",
      marketCap: ({ currency }: { currency: string }) =>
        `Market Cap
(${currency})`,
      volume: "20 Days Avg Volume",
    },
    day: "Day",
    week: "Week",
    month: "Month",
    reset: "Reset",
    search: "Search",
    power: "Strength",
    powers: ["None", "Weak", "Medium", "Strong"],
    cellPowers: ["N", "W", "M", "S"],
    rise: "Up Trend",
    fall: "Down Trend",
    reversionUp: "Revert Up",
    revertDown: "Revert Down",
    resultTitle: ({ total }: { total: number }) => `Results (${total} matched)`,
    noResult: "No Result Found",
    symbol: "Symbol",
    squeezeDay: "Squeeze Day",
    squeezeWeek: "Squeeze Week",
    squeezeMonth: "Squeeze Month",
    surfingTrendDay: "Surfing Trend Day",
    surfingTrendWeek: "Surfing Trend Week",
    surfingTrendMonth: "Surfing Trend Month",
    dailyGrowinMeanRevertingRadar: "Mean Reverting Radar Day",
    weeklyGrowinMeanRevertingRadar: "Mean Reverting Radar Week",
    monthlyGrowinMeanRevertingRadar: "Mean Reverting Radar Month",
    addToWatchlist: "Add",
    volumeHeader: "20 Days Avg. Volume",
    tradingVolume: "Trading Volume (TWD)",
    companyName: "Company Name",
    macro: "Macro",
    fundamental: "Fundamental",
    technical: "Technical",
    pattern: "Pattern",
    patterns: "Patterns",
    growinIndicators: "Growin Indicators",
    gdp: "GDP",
    interestRate: "Interest Rate",
    inflation: "Inflation",
    unemployment: "Unemployment",
    economicGrowth: "Economic Growth",
    marketCap: ({ currency }: { currency: string }) =>
      `Market Cap (${currency})`,
    sector: "Sector",
    industry: "Industry",
    shortInterest: "Short Interest",
    analystRating: "Analyst Ratings",
    rsRating: "RS Rating (Relative Strength)",
    selectAnOption: "Select an option",
    filterCriteriaAmount: (total: number) => {
      return total > 1
        ? `Stock Screener（${total} conditions have been selected.）`
        : `Stock Screener（${total} condition has been selected.）`;
    },
    usStock: "US",
    twStock: "TW",
    dailyPrice: "Daily",
    weeklyPrice: "Weekly",
    monthlyPrice: "Monthly",
    bullish: "Bullish",
    bearish: "Bearish",
    patternInfo: patternInfo.en,
    strategy: strategy.en,
    selected: "Selected",
  },
};
