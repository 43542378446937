import { my13F } from "./13f";
import { about } from "./about";
import { account } from "./account";
import { analysis } from "./analysis";
import { auth } from "./auth";
import { blockDialog } from "./block-dialog";
import { blog } from "./blog";
import { contactUs } from "./contact-us";
import { country } from "./country";
import { currency } from "./currency";
import { error } from "./error";
import { help } from "./help";
import { home } from "./home";
import { layout } from "./layout";
import { marketAnalysis } from "./market-analysis";
import { mediaCenter } from "./media-center";
import { metadata } from "./metadata";
import { notification } from "./notification";
import { pricing } from "./pricing";
import { ranking } from "./ranking";
import { roboAdvisor } from "./robo-advisor";
import { screener } from "./screener";
import { screenerOptions } from "./screener-options";
import { stockMining } from "./stock-mining";
import { stripe } from "./stripe";
import { technology } from "./technology";
import { tradingview } from "./tradingview";
import { winwingpt } from "./winwingpt";

export type Translation = typeof zh;

export const zh = {
  error: error.zh,
  auth: auth.zh,
  technology: technology.zh,
  stripe: stripe.zh,
  roboAdvisor: roboAdvisor.zh,
  layout: layout.zh,
  home: home.zh,
  help: help.zh,
  contactUs: contactUs.zh,
  about: about.zh,
  analysis: analysis.zh,
  screener: screener.zh,
  stockMining: stockMining.zh,
  metadata: metadata.zh,
  account: account.zh,
  notification: notification.zh,
  my13F: my13F.zh,
  blockDialog: blockDialog.zh,
  pricing: pricing.zh,
  marketAnalysis: marketAnalysis.zh,
  ranking: ranking.zh,
  country: country.zh,
  mediaCenter: mediaCenter.zh,
  winwingpt: winwingpt.zh,
  currency: currency.zh,
  blog: blog.zh,
  screenerOptions: screenerOptions.zh,
  tradingview: tradingview.zh,
};

export const en: Translation = {
  error: error.en,
  auth: auth.en,
  technology: technology.en,
  stripe: stripe.en,
  roboAdvisor: roboAdvisor.en,
  layout: layout.en,
  home: home.en,
  help: help.en,
  contactUs: contactUs.en,
  about: about.en,
  analysis: analysis.en,
  screener: screener.en,
  stockMining: stockMining.en,
  metadata: metadata.en,
  account: account.en,
  notification: notification.en,
  my13F: my13F.en,
  blockDialog: blockDialog.en,
  pricing: pricing.en,
  marketAnalysis: marketAnalysis.en,
  ranking: ranking.en,
  country: country.en,
  mediaCenter: mediaCenter.en,
  winwingpt: winwingpt.en,
  currency: currency.en,
  blog: blog.en,
  screenerOptions: screenerOptions.en,
  tradingview: tradingview.en,
};
