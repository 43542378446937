import { factor } from "./factor";
import { tradingViewIndex } from "./trading-view-index";

export const trend = {
  zh: {
    forceInfo: [
      "趨勢指的是股價持續沿著一個方向行進，當在向上趨勢發生而買入股票，會預期股價將持續往上，讓獲利不斷累積，而當趨勢減緩或是轉變為向下趨勢時，則賣出股票結清部位。因此，趨勢交易者常有一個口訣“讓你的獲利持續奔跑，直到趨勢消失”，是為趨勢策略的核心精神。然而，在一個上漲的趨勢中，是否還能追買就需要考慮每一檔股票趨勢發生時的階段。",

      "Growin 的趨勢力道分數以 1 分到 5 分代表股價中長期的走勢強度與力道，來幫助投資人解決此問題。評分考慮多種技術指標的數值，如均線、ATR、波動率等，並且搭配團隊的股價型態辨識技術以及自行開發的 Power Squeeze 及 Surfing Trend 兩大動能指標，統整編制而成。『分數越高 (低) 代表個股處於較強勁的多頭 (空頭) 趨勢，而中間的 3 分則代表處於趨勢模糊的盤整狀態』，用以幫助投資人快速判別出具備趨勢力道的投資標的。",

      "股價型態辨識技術：",

      "https://octopus.tradingvalley.com/stockpattern/?fbclid=IwAR3tP54gi8YGOuYSBZhbNTAUsHEBUMTouS_FkuT8rksm_IXy8gPrei5CKA8",

      "請注意：此趨勢力道的評分僅為輔助使用，投資人應根據自己的投資風險，建立適合自己的進場、出場、停損的策略來進行投資。",
    ],
    factor: factor.zh,
    tradingViewIndex: tradingViewIndex.zh,
  },
  en: {
    forceInfo: [
      'Trend refers to the continuous movement of stock prices in a particular direction. When buying stocks during an uptrend, investors anticipate that the prices will continue to rise, allowing profits to accumulate. Conversely, when the trend slows down or transitions into a downtrend, stocks are sold to liquidate positions. Therefore, trend traders often follow the mantra of " Cut your losses short and let your profits run," which is the core principle of trend following strategy.',

      "Growin's Trend Score rates the strength and momentum of medium to long-term price trends on a scale from 1 to 5. The evaluation considers various technical indicators' values, such as moving averages, ATR, volatility, etc. It also incorporates stock chart pattern and two proprietary momentum indicators developed by Growin: Power Squeeze and Surfing Trend. The score reflects the overall assessment. A higher (lower) score indicates a stronger bullish (bearish) trend, while a middle score of 3 suggests a trend-neutral consolidation phase. This scoring system helps investors swiftly identify investment targets with substantial trend momentum.",

      "Stock chart pattern：",

      "https://octopus.tradingvalley.com/stockpattern/?fbclid=IwAR3tP54gi8YGOuYSBZhbNTAUsHEBUMTouS_FkuT8rksm_IXy8gPrei5CKA8",

      "Note: This Trend Trading Score is intended for supplementary use only. Investors should establish their own entry, exit, and stop-loss strategies based on their investment risk tolerance.",
    ],
    factor: factor.en,
    tradingViewIndex: tradingViewIndex.en,
  },
};
