export const layout = {
  zh: {
    banner: "美國 CNN 恐懼貪婪指標",
    fearAndGreedIndex: "恐懼貪婪指數",
    latestStatus: "當前狀態",
    extremeGreed: "極度貪婪",
    extremeFear: "極度恐懼",
    greed: "貪婪",
    fear: "恐懼",
    neutral: "中立",
    performanceOver5Days: "極端情緒後 5 天的表現統計",
    performanceOver5DaysDescription:
      "當市場從極端情緒，在短期內會出現反轉走勢。例如：市場在「極度貪婪」，依 2021/07 至今的歷史統計數據可以發現，短期內（近五日）容易出現下跌情形，而「極度恐懼」則反之，短期內（近五日）有很高的機率會出現上漲的行情。",
  },
  en: {
    banner: "CNN Fear & Greed Index",
    fearAndGreedIndex: "Fear & Greed Index",
    latestStatus: "What emotion is driving the market now?",
    extremeGreed: "Extreme Greed",
    extremeFear: "Extreme Fear",
    greed: "Greed",
    fear: "Fear",
    neutral: "Neutral",
    performanceOver5Days: "Extreme Emotion Performance Over 5 Days",
    performanceOver5DaysDescription: `When the market experiences extreme emotions, a short-term reversal trend often follows. For example, when the market is in a state of "extreme greed," historical data from 2021/07 to the present shows that a decline is likely to occur in the short term (within the next five days). Conversely, when the market is in "extreme fear," there is a high probability of an upward trend in the short term (within the next five days).`,
  },
};
