export const screener = {
  zh: {
    title: (market?: string) =>
      `${market}股選股工具｜多功能 AI 智慧選股網站 - Growin`,
    description: (market: string) =>
      `透過 Growin 多功能 AI 智慧選股工具，幫助您迅速找到強勢潛力股。簡單易用的選股網站，成就您的${market}股投資。`,
  },
  en: {
    title: () => "Stock Screener | Swiftly Find Momentum Stock - Growin",
    description: (market: string) =>
      `Simply click on your selected criteria in the Growin Stock Screener. It will rapidly filter out stocks that align with your preferences, boosting your ${market} stock research and decision-making efficiency!`,
  },
};
