export const technology = {
  zh: {
    title: "技術 | AI 人工智慧演算法 x 投資理財應用 - Growin",
    description:
      "Growin 運用多重人工智慧演算法與機器學習技術，建構出能有效分散風險於 ETF 與股票的投資組合。無論您是投資新手或老手，我們都能提供最適合您的自動化投資策略。",
  },
  en: {
    title: "Technology - Growin",
    description:
      "Growin uses multiple artificial intelligence algorithms and machine learning technologies to construct investment portfolios that can effectively diversify risks in ETFs and stocks. Whether you are an investment novice or an old hand, we can provide you with the most suitable automated investment strategy.",
  },
};
