export const contactUs = {
  zh: {
    title: "聯絡我們 - Growin",
    description:
      "請告訴我們需要協助的地方，我們將盡快與您聯繫。也歡迎加入 LINE 官方帳號，與我們即時互動！",
  },
  en: {
    title: "Contact Us - Growin",
    description:
      "Please tell us where you need help, and we will contact you as soon as possible. You are also welcome to join the official LINE account and interact with us in real time!",
  },
};
