export const scoreOption = {
  zh: {
    getScore: (score: number) => `${score} 分`,
    getScoreLabel: (score: number) => `${score} 分`,
    getScoreMore: (score: number) => `${score} 分以上`,
    getScoreLess: (score: number) => `${score} 分以下`,
  },
  en: {
    getScore: (score: number) => score.toString(),
    getScoreLabel: (score: number) => `Score ${score}`,
    getScoreMore: (score: number) => `Score ${score} or above`,
    getScoreLess: (score: number) => `Score ${score} or below`,
  },
};
