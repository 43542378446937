export const shared = {
  zh: {
    newHigh: "新高",
    newLow: "新低",
    under: "低於",
    over: "高於",
    to: "至",
    price: "股價",
    below: "低於",
    above: "高於",
    crossesBelow: "死亡交叉",
    crossesAbove: "黃金交叉",
    sma20: "20 日均線",
    sma50: "50 日均線",
    sma100: "100 日均線",
    sma150: "150 日均線",
    sma200: "200 日均線",
    aboveLow: "高於低點",
    belowHigh: "低於高點",
    orMoreAboveLow: ({ value }: { value: number }) => `高於低點 ${value}% 以上`,
    orMoreBelowHigh: ({ value }: { value: number }) =>
      `低於高點 ${value}% 以上`,
    priceIs: "股價",
    between: "介於",
    and: "和",
    SMA: "20 日均線",
    ATR: "ATR",
    strongBuy: "強力買入",
    buy: "買入",
    hold: "持有",
    sell: "賣出",
    strongSell: "強力賣出",
    patternPeriod: {
      dayPattern: "日線",
      weekPattern: "週線",
      monthPattern: "月線",
    },
    cancelSelection: "取消選擇",
  },
  en: {
    newHigh: "New High",
    newLow: "New Low",
    under: "Under",
    over: "Over",
    to: "to",
    price: "Price",
    below: "below",
    above: "above",
    crossesBelow: "crosses below",
    crossesAbove: "crosses above",
    sma20: "SMA20",
    sma50: "SMA50",
    sma100: "SMA100",
    sma150: "SMA150",
    sma200: "SMA200",
    aboveLow: "above Low",
    belowHigh: "below High",
    orMoreAboveLow: ({ value }: { value: number }) =>
      `${value}% or more above Low`,
    orMoreBelowHigh: ({ value }: { value: number }) =>
      `${value}% or more below High`,
    priceIs: "Price is",
    between: "between",
    and: "and",
    SMA: "SMA",
    ATR: "ATR",
    strongBuy: "Strong Buy",
    buy: "Buy",
    hold: "Hold",
    sell: "Sell",
    strongSell: "Strong Sell",
    patternPeriod: {
      dayPattern: "Day",
      weekPattern: "Week",
      monthPattern: "Month",
    },
    cancelSelection: "Cancel Selection",
  },
};
