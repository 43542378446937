export const country = {
  zh: {
    us: "美",
    tw: "台",
  },
  en: {
    us: "US",
    tw: "TW",
  },
};
