import { winwinGpt } from "./winwin-gpt";

export const event = {
  zh: {
    winwinGpt: winwinGpt.zh,
  },
  en: {
    winwinGpt: winwinGpt.en,
  },
};
