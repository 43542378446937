export const account = {
  zh: {
    accountInfo: "基本資料",
    accountInfoDesc:
      "您的個人資料僅授權提供 Growin 團隊使用，相關資訊皆有受到保護",
    signUpAt: "註冊日期",
    signUpMethod: "註冊方式",
    password: "密碼",
    cancel: "取消",
    save: "儲存",
    subInfo: "訂閱資訊",
    subInfoDesc:
      "此區資訊為您目前使用「Growin 個股探勘」的方案詳細資訊，亦可在此區做方案升級或修改",
    plan: "當前方案",
    upgrade: "升級",
    change: "修改",
    period: "訂閱期間",
    payment: "付款紀錄",
    unsubscribe: "取消訂閱",
    viewBill: "查看帳單",
    original: "一般登入",
    business: "企業登入",
    passwordChanged: "密碼修改成功",
    resume: "恢復訂閱",
    subscribing: "訂閱中",
    canceled: "已取消訂閱",
    nonRenewal: "下次週期停止扣款",
    status: "訂閱狀態",
    neverSubscribed: "未訂閱",
    userSetting: "個人設定",
  },
  en: {
    accountInfo: "Profile",
    accountInfoDesc:
      "Personal data for TradingValley team only and all information are protected.",
    signUpAt: "Registration Date",
    signUpMethod: "Login Method",
    password: "Password",
    cancel: "Cancel",
    save: "Save",
    subInfo: "Subscription",
    subInfoDesc:
      "Here is your current plan details for using “Growin Stock Mining”.",
    plan: "Current Plan",
    upgrade: "Upgrade",
    change: "Edit",
    period: "Duration",
    payment: "Billing History",
    unsubscribe: "Unsubscribe",
    viewBill: "Check the Receipt",
    original: "Email",
    business: "Enterprise",
    passwordChanged: "Password changed successfully",
    resume: "Resume",
    subscribing: "Subscribing",
    canceled: "Unsubscribed",
    nonRenewal: "Suspend Payments",
    status: "Status",
    neverSubscribed: "Unsubscribed",
    userSetting: "User Setting",
  },
};
