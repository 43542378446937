export const forecast = {
  zh: {
    title: (symbol: string) => `${symbol} 財務預測`,
    pastData: "過去資料",
    maxForecast: "最大預估",
    avgForecast: "平均預估",
    minForecast: "最小預估",
    eps: "每股盈餘",
    revenue: "營收",
    forecast: "預測",
    range: "範圍",
    reported: "",
  },
  en: {
    title: (symbol: string) => `${symbol} Financial Forecast`,
    pastData: "Past Data",
    maxForecast: "Max Forecast",
    avgForecast: "Avg. Forecast",
    minForecast: "Min Forecast",
    eps: "EPS",
    revenue: "Revenue",
    forecast: " Forecast",
    range: "",
    reported: "Reported ",
  },
};
