export const support = {
  zh: {
    returnLogin: "返回一般會員登入",
    forgotPassword: "忘記密碼",
    forgotPasswordMessage:
      "請輸入您的電子郵件地址，我們將會寄送密碼重設連結給您。",
    resetPassword: "重設密碼",
    enterNewPassword: "輸入新密碼",
    passwordResetSuccess: "密碼重設成功！",
    ok: "確定",
    emailVerification: "驗證電子郵件",
    emailVerificationMessage: ({ email }: { email: string }) =>
      `我們已將驗證碼寄送至 ${email}，請輸入電子郵件中的驗證碼：`,
    didNotReceiveEmail: "未收到驗證信嗎？",
    resendEmail: "重寄驗證信",
    enterVerificationCode: "輸入驗證碼",
    emailVerified: "電子郵件驗證成功！",
    emailVerifiedFailed: "驗證失敗，請再重試一次！",
    emailVerifiedSending: "重寄認證信中...",
    emailVerifiedSent: "重寄認證信成功！",
    emailVerifiedSendFailed: "重寄認證信失敗，請再重試一次！",
    sendResetPasswordEmailSuccess: "重設密碼信已寄出，請至電子信箱查收！",
    sendResetPasswordEmailFailed: "重設密碼信寄送失敗，請再重試一次！",
    forgotPasswordStillFailed: "一直無法寄出連結？",
    contactUs: "聯絡我們",
  },
  en: {
    returnLogin: "Return to login page",
    forgotPassword: "Forgot password",
    forgotPasswordMessage:
      "Please enter your email address and we will send you a link to reset your password.",
    resetPassword: "Reset password",
    enterNewPassword: "Enter new password",
    passwordResetSuccess: "Password reset successfully!",
    ok: "OK",
    emailVerification: "Email Verification",
    emailVerificationMessage: ({ email }: { email: string }) =>
      `We have sent a verification code to ${email}, please enter the verification code in the email:`,
    didNotReceiveEmail: "Didn't receive the email?",
    resendEmail: "Resend email",
    enterVerificationCode: "Enter verification code",
    emailVerified: "Email verified successfully!",
    emailVerifiedFailed: "Verification failed, please try again!",
    emailVerifiedSending: "Sending verification email...",
    emailVerifiedSent: "Verification email sent successfully!",
    emailVerifiedSendFailed:
      "Failed to send verification email, please try again!",
    sendResetPasswordEmailSuccess:
      "Reset password link has been sent, please check your email!",
    sendResetPasswordEmailFailed:
      "Failed to send reset password link, please try again!",
    forgotPasswordStillFailed: "Still unable to send the link?",
    contactUs: "Contact us",
  },
};
