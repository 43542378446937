export const illustrationDialog = {
  zh: {
    title: "使用說明",
    content: ({
      basicUsage,
      premiumUsage,
    }: {
      basicUsage: number;
      premiumUsage: number;
    }) => `
- WinwinGPT 只回答與金融投資相關的問題
- WinwinGPT 目前無法記住前一題的答覆完成連續聊天
- 基本方案可問 ${basicUsage * 2} 題 / 2 週、進階方案可問 ${
      premiumUsage * 2
    } 題 / 2 週
- WinwinGPT 可能提供不準確資訊，請核對答覆。查看 <a href='https://file.growin.tv/policy/terms-and-conditions.html' target='_blank' style='color: #0084C6; font-weight: 600;'>Growin 免責聲明</a>`,
    confirm: "確認",
  },
  en: {
    title: "Instructions",
    content: ({
      basicUsage,
      premiumUsage,
    }: {
      basicUsage: number;
      premiumUsage: number;
    }) => `
- WinwinGPT only answers questions related to financial investment
- WinwinGPT Currently unable to remember the previous answer to complete continuous chat
- Basic plan can ask ${
      basicUsage * 2
    } questions/ 2 weeks, premium plan can ask ${
      premiumUsage * 2
    } questions/ 2 weeks
- WinwinGPT may provide inaccurate information, please verify the answers. View <a href='https://file.growin.tv/policy/terms-and-conditions.html' target='_blank' style='color: #0084C6; font-weight: 600;'>Growin Disclaimer</a>`,
    confirm: "Confirm",
  },
};
