export const lineBinding = {
  zh: {
    title: "LINE 綁定 Growin 推播服務",
    titleError: "LINE 綁定發生錯誤",
    message: "請點擊下方按鈕完成帳號綁定",
    messageSuccess: "您已完成帳號綁定囉！",
    messageError: "請再試一次或聯絡 Growin 團隊",
    button: "立即綁定",
    buttonSuccess: "已完成綁定",
    retry: "重試",
  },
  en: {
    title: "Bind LINE to Growin Push Service",
    titleError: "Error Binding LINE",
    message: "Please click the button below to complete the account binding",
    messageSuccess: "You have successfully bound your account!",
    messageError: "Please try again or contact the Growin team",
    button: "Bind Now",
    buttonSuccess: "Binding Completed",
    retry: "Retry",
  },
};
