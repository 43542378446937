export const footer = {
  zh: {
    activeInvestment: "主動投資",
    stockMining: "個股探勘",
    pricing: "付費方案",
    passiveInvestment: "被動投資",
    roboAdvisor: "智能理財",
    technology: "技術說明",
    help: "幫助中心",
    about: "關於我們",
    company: "公司介紹",
    contact: "聯絡我們",
    socialMedia: "社群媒體",
    line: "LINE 即時客服",
    telegram: "Telegram 美股速報",
    facebook: "Facebook 粉絲專頁",
    discord: "Discord 投資交流社群",
    privacyPolicy: "隱私條款",
    termsOfUse: "服務條款",
    disclaimer:
      "TradingValley Inc., California corporate number: C4098652 is an SEC registered online advisor (801-114112) CRD number is 298542. Brokerage services provided to clients of Growin by Interactive Brokers, an SEC-registered broker-dealer and member of FINRA /SIPC. Investments in securities including but not limited to stocks, ETFs: Not FDIC Insured • No Bank Guarantee • May Lose Value. Investing in securities involves risks, and there is always the potential of losing principle you invested. Before investing, consider your investment objectives, financial situation and Growin's charges and expenses. Growin's advisory services are designed to assist clients in achieving discrete financial goals. They are not intended to provide comprehensive tax advice or financial planning with respect to every aspect of a client's financial situation. For more details, see our Form ADV and other disclosures. Past performance does not guarantee future results, and the likelihood of investment outcomes are hypothetical in nature.",
    allRightReserved: "© TradingValley. All Rights Reserved.",
    downloadRoboApp: "智能理財 APP 下載",
  },
  en: {
    activeInvestment: "Active Investment",
    stockMining: "Stock Mining",
    pricing: "Pricing",
    passiveInvestment: "Passive Investment",
    roboAdvisor: "Robo Advisor",
    technology: "Technology",
    help: "Help Center",
    about: "About Us",
    company: "Company",
    contact: "Contact Us",
    socialMedia: "Social Media",
    line: "LINE Chat",
    telegram: "Telegram US Stock News",
    facebook: "Facebook Fan Page",
    discord: "Discord Investment Discussion Community",
    privacyPolicy: "Privacy Policy",
    termsOfUse: "Terms of Use",
    disclaimer:
      "TradingValley Inc., California corporate number: C4098652 is an SEC registered online advisor (801-114112) CRD number is 298542. Brokerage services provided to clients of Growin by Interactive Brokers, an SEC-registered broker-dealer and member of FINRA /SIPC. Investments in securities including but not limited to stocks, ETFs: Not FDIC Insured • No Bank Guarantee • May Lose Value. Investing in securities involves risks, and there is always the potential of losing principle you invested. Before investing, consider your investment objectives, financial situation and Growin's charges and expenses. Growin's advisory services are designed to assist clients in achieving discrete financial goals. They are not intended to provide comprehensive tax advice or financial planning with respect to every aspect of a client's financial situation. For more details, see our Form ADV and other disclosures. Past performance does not guarantee future results, and the likelihood of investment outcomes are hypothetical in nature.",
    allRightReserved: "© TradingValley. All Rights Reserved.",
    downloadRoboApp: "Robo Advisor Dowload",
  },
};
