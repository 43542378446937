import { form13F } from "./13f";
import { account } from "./account";
import { analysis } from "./analysis";
import { blog } from "./blog";
import { marketAnalysis } from "./market-analysis";
import { mediaCenter } from "./media-center";
import { notification } from "./notification";
import { ranking } from "./ranking";
import { screener } from "./screener";
import { tradingView } from "./trading-view";
import { winwingpt } from "./winwingpt";

export const my = {
  zh: {
    analysis: analysis.zh,
    screener: screener.zh,
    form13F: form13F.zh,
    marketAnalysis: marketAnalysis.zh,
    notification: notification.zh,
    account: account.zh,
    ranking: ranking.zh,
    mediaCenter: mediaCenter.zh,
    winwingpt: winwingpt.zh,
    blog: blog.zh,
    tradingView: tradingView.zh,
  },
  en: {
    analysis: analysis.en,
    screener: screener.en,
    form13F: form13F.en,
    marketAnalysis: marketAnalysis.en,
    notification: notification.en,
    account: account.en,
    ranking: ranking.en,
    mediaCenter: mediaCenter.en,
    winwingpt: winwingpt.en,
    blog: blog.en,
    tradingView: tradingView.en,
  },
};
