export const contactUs = {
  zh: {
    text: ["聯絡我們", "請告訴我們需要協助的地方，我們將盡快與您聯繫。"],
    input: ["姓名", "電子郵件", "撰寫訊息給我們", "提交"],
    success: "已成功寄出信件，我們會盡快與您聯繫！",
    fail: "寄出信件失敗，請重新提供您的寶貴建議，謝謝！",
    validation: ["必填欄位", "電子郵件格式錯誤"],
    username: "姓名",
    email: "電子郵件",
    category: "問題類別",
    content: "撰寫訊息給我們",
    submit: "提交",
    stockMining: "個股探勘",
    roboAdvisor: "智能理財",
    join: "加入 Line 官方帳號「",
    liveCustomerService: "」即時客服",
    questionCategory: "問題類別",
  },
  en: {
    text: ["Contact Us", "Let us know your question, we will get back to you."],
    input: ["Name", "Email address", "state your question here", "Submit"],
    success: "Submitted successfully, we will get back to you ASAP!",
    fail: "Email delivery failed, Please try it again. Thank you.",
    validation: ["Required", "Please make sure your email is correct"],
    username: "Name",
    email: "Email Address",
    category: "Category",
    content: "State your question here",
    submit: "Submit",
    stockMining: "Stock Mining",
    roboAdvisor: "Robo-advisor",
    join: "Get live customer service on 「",
    liveCustomerService: "」LINE official account!",
    questionCategory: "Question Category",
  },
};
