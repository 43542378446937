export const investor = {
  zh: {
    analysisOfInvestor: "13F 申報機構分析",
    intro: "簡介",
    representative: "代表人物",
    style: "投資風格",
    reportedAmount: "申報金額",
    institutionalInvestingStyle: "13F 機構投資風格",
    styleIllustration: "共有 8 種投資風格，說明如下",
    styleDescriptions: [
      {
        title: "成長型策略",
        description:
          "以投資高成長性的股票為主，通常會是高本益比、較少配息的股票",
      },
      {
        title: "價值型策略",
        description:
          "以投資股價被低估的股票為主，通常會是高股價淨值比、較高配息的股票",
      },
      {
        title: "GARP 策略",
        description: "以投資高成長性但價格合理的股票為主",
      },
      {
        title: "存股型策略",
        description: "以投資產生穩定現金流，而非追求高報酬的股票為主",
      },
      {
        title: "避險基金",
        description: "避險基金的投資風格多元，包含使用槓桿、做空、衍生品等",
      },
      {
        title: "指數型",
        description: "以追隨大盤表現為主",
      },
      {
        title: "動能策略",
        description: "以追逐股票趨勢為主",
      },
      {
        title: "其他",
        description: "無法歸類於以上的策略",
      },
    ],
    close: "關閉",
    highlights: (investorName: string) => `${investorName} 13F 重點整理`,
    quarterlyReportConclusions: "本季報告結論",
    quarterlyChanges: "季度變化",
    amountChange: "金額變化",
    stockChange: "個股變化",
    stockUnit: "檔",
    investingAmountChange: "投資金額變化 Top5",
    all: "全部",
    openPosition: "建倉",
    overweight: "加碼",
    flat: "持平",
    underweight: "減碼",
    closePosition: "清倉",
    detailedInfo: (investorName: string) => `${investorName} 13F 詳細資訊`,
    quarterlyHoldings: "本季持有",
    proportion: "投組佔比",
    holdingAmount: "持有金額",
    holdingShares: "持有股數",
    holdingChange: "持股變化",
    portfolioHistory: "投資組合歷史分布",
    top20Holdings: "前 20 大持股成本推估",
    industryDistribution: "產業分布",
    holdingsDistribution: "持有佔比",
    top20Description: (investorName: string) =>
      `透過每季${investorName}的 13F 資料來推算其本季前 20 大持股的未實現獲利。而每季推估成本的計算方式是以每季的成交量加權 (VWAP) 均價當作估計，資料最早回溯自 2013 年。`,
    lineChart: "線圖呈現",
    table: "表格呈現",
    costEstimation: "成本推估",
    volumeWeightedEstimatedCosts: "交易量加權推估成本",
    estimation: "推估成本",
    estimatedCost: "推估成本",
    estimatedPAndL: "推估 P&L(%)",
    date: "日期",
    noStock: "本季沒有股票申報紀錄",
    others: "其他",
  },

  en: {
    analysisOfInvestor: "13F Reports",
    intro: "",
    representative: "Representative",
    style: "Style",
    reportedAmount: "AUM",
    institutionalInvestingStyle: "Investment Styles of 13F Institutions",
    styleIllustration: "There are 8 investment styles, described as follows:",
    styleDescriptions: [
      {
        title: "Growth",
        description:
          "Focuses on high-growth stocks, with high P/E ratios and lower dividends.",
      },
      {
        title: "Value",
        description:
          "Emphasizes investing in undervalued stocks, with high P/B ratios and higher dividends.",
      },
      {
        title: "GARP",
        description:
          "Focuses on stocks with high growth potential at reasonable prices.",
      },
      {
        title: "Dividend",
        description:
          "Primarily invests in stocks that generate stable cash flow.",
      },
      {
        title: "Hedge Funds",
        description:
          "Utilizes various strategies, including leverage, short-selling, and derivatives.",
      },
      {
        title: "Index",
        description: "Mainly follows the performance of the overall market.",
      },
      {
        title: "Momentum",
        description: "Primarily pursues stocks that exhibit positive trends.",
      },
      {
        title: "Others",
        description:
          "Strategies that cannot be classified into the above categories.",
      },
    ],
    close: "Close",
    highlights: (investorName: string) => `13F Summary of ${investorName}`,
    quarterlyReportConclusions: "Summary",
    quarterlyChanges: "Quarter on Quarter",
    amountChange: "AUM Chg.",
    stockChange: "Shares Chg.",
    stockUnit: "",
    investingAmountChange: "Chg. of Investment AUM Top5",
    all: "All",
    openPosition: "New Buy",
    overweight: "Add",
    flat: "Neutral",
    underweight: "Reduce",
    closePosition: "Sold Out",
    detailedInfo: (investorName: string) => `13F Details of ${investorName}`,
    quarterlyHoldings: "Holdings",
    proportion: "Weight",
    holdingAmount: "Amount",
    holdingShares: "Shares",
    holdingChange: "Chg. of Shares",
    portfolioHistory: "Portfolio Historical Statistics",
    top20Holdings: "Estimated Cost of Top 20",
    industryDistribution: "Sector",
    holdingsDistribution: "Weight",
    top20Description: (investorName: string) =>
      `We use ${investorName}'s 13F reports to calculate the current quarter's unrealized gain for their top 20 holdings. The cost estimation relies on VWAP for each quarter, dating back to 2013.`,
    lineChart: "Charts",
    table: "Data",
    costEstimation: "Buying Cost Estimation",
    volumeWeightedEstimatedCosts: "Volume-Weighted Cost Estimation",
    estimation: "Estimation",
    estimatedCost: "Estimated Cost",
    estimatedPAndL: "Estimated P&L(%)",
    date: "Date",
    noStock: "No stock declaration records this quarter",
    others: "Others",
  },
};
