import { dateOption } from "./date-option";
import { dateTime } from "./date-time";
import { gaugeText } from "./gauge-text";
import { oriented } from "./oriented";
import { scoreOption } from "./score-option";

export const shared = {
  zh: {
    close: "關閉",
    date: "日期",
    price: "收盤價",
    analysis: "分析",
    instructions: "說明",
    factor: "參考因子",
    rwdTitle: "貼心小提醒",
    rwdMessage: "目前服務透過網頁介面操作會比較合適呦！",
    rwdSkip: "不再顯示此訊息",
    understood: "了解",
    days: ({ count }: { count: number }) => `${count}天`,
    occurrence: ({ count }: { count: number }) => `${count}次`,
    dateOption: dateOption.zh,
    dateTime: dateTime.zh,
    gaugeText: gaugeText.zh,
    oriented: oriented.zh,
    scoreOption: scoreOption.zh,
  },
  en: {
    close: "Close",
    date: "Date",
    price: "Price",
    analysis: " Score",
    instructions: " Instructions",
    factor: " Reference Factors",
    rwdTitle: "Reminder",
    rwdMessage: "The service is more suitable for web browser!",
    rwdSkip: "Don't show again",
    understood: "OK",
    days: ({ count }: { count: number }) =>
      `${count} ${count > 1 ? "days" : "day"}`,
    occurrence: ({ count }: { count: number }) =>
      `${count} ${count > 1 ? "times" : "time"}`,
    dateOption: dateOption.en,
    dateTime: dateTime.en,
    gaugeText: gaugeText.en,
    oriented: oriented.en,
    scoreOption: scoreOption.en,
  },
};
