export const login = {
  zh: {
    title: "註冊成為免費會員",
    description: ["免費成為 Growin 會員或直接", "，就可以查看更多內容！"],
    login: "登入",
    emailSignup: "Email 註冊",
    googleSignup: "Google 註冊",
  },
  en: {
    title: "Sign up for free!",
    description: ["You can sign up for free or ", " to read more information!"],
    login: "sign in",
    emailSignup: "Sign up with Email",
    googleSignup: "Sign up with Google",
  },
};
