export const indicator = {
  zh: {
    normal: "正常",
    cautionary: "異常",
    pmi: "製造業採購經理人指數",
    cpiYoy: "通膨率",
    treasurySpread: "殖利率差",
    skewIndex: "SKEW 指標",
    roroIndex: "RORO 指標",
    putCallRatio: "Put Call Ratio",
    vix: "VIX",
    moveIndex: "美林公債波動率指數",
    referenceFactors: "燈號參考元素",
    latest: "當前狀態",
    baseline: "基準線",
  },
  en: {
    normal: "Normal",
    cautionary: "Cautionary",
    pmi: "PMI",
    cpiYoy: "CPI YoY",
    treasurySpread: "10Y Minus 1Y Treasury Spread",
    skewIndex: "SKEW Index",
    roroIndex: "RORO Index",
    putCallRatio: "Put Call Ratio",
    vix: "VIX",
    moveIndex: "MOVE Index",
    referenceFactors: "Reference Factor",
    latest: "Latest",
    baseline: "Baseline",
  },
};
