export const mediaCenter = {
  zh: {
    mediaCenter: "影音專區",
    tradingCourses: "交易課程",
    liveTradingSession: "美股分析直播",
    weeklyMarketAnalysis: "每週市場分析",
    websiteTutorials: "新手教學",
    goBack: "回到上一頁",
    showMore: "顯示更多",
    add: "加入收藏",
    added: "已加入收藏",
    about: "關於影片",
    progress: "完成進度",
    freeTrial: "免費試閱",
    relatingSymbols: "相關標的",
    upgrade: "升級看完整內容",
    notAllowed: "目前方案無法觀看內容",
  },
  en: {
    mediaCenter: "Media Center",
    tradingCourses: "Trading Courses",
    liveTradingSession: "Live Trading Session",
    weeklyMarketAnalysis: "Weekly Market Analysis",
    websiteTutorials: "Tutorials Zone",
    goBack: "Back",
    showMore: "Show more",
    add: "Add to my favorite",
    added: "Added to my favorite",
    about: "About Video",
    progress: "Completion Progress",
    freeTrial: "Free Trial",
    relatingSymbols: "Symbols",
    upgrade: "Upgrade",
    notAllowed: "You are not allowed to view this content",
  },
};
