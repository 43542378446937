export const settings = {
  zh: {
    cancelAdd: "取消新增",
    cancelEdit: "取消編輯",
    save: "儲存",
    close: "關閉",

    basicSettings: "一般設定",
    robotName: "策略名稱",
    type: "策略類型",
    expiration: "偵測結束時間",
    notifyBy: "通知管道",
    name: "名稱",
    buyIn: "進場訊息",
    sellOut: "出場訊息",

    followingContents: "追蹤內容設定",
    chooseWatchlists: "請選擇追蹤清單",
    searchSymbols: "搜尋股票",
    addWatchlistOrSymbols: "請從左邊加入你想追蹤的清單或股票",
    clean: "清空",
    watchlistsSelected: (count: number) => `已選擇 ${count} 個追蹤清單`,
    alreadyExists: (symbol: string) => `${symbol} 已經存在`,
    importedSuccessfully: (symbol: string) => `${symbol} 匯入成功`,

    advancedSettings: "進階設定",
    customizeYourStrategy:
      "您可以依據市值規模、交易均量、五力分數... 等，自行設定條件，建立專屬於自己的推播策略",
    notifyWhenConditionMet: "以上條件都達到才會觸發通知喔！",
    customization: "自訂",
    valueRobot: "價值機器人",
    swingRobot: "波段機器人",
    trendRobot: "趨勢機器人",

    fiveDimension: "五力分數",
    fiveDimensionIntro: "五力分數說明",
    technicalIndicator: "技術指標",
    others: "其他",
    value: "價值",
    trend: "趨勢",
    swing: "波段",
    chip: "籌碼",
    dividend: "股利",
    status: "狀態",
    score: "數值",

    daily: "日線",
    weekly: "週線",
    crossAbove: "向上突破",
    crossBelow: "向下跌破",
    greaterThan: "大於等於",
    lessThan: "小於等於",
    turnsPositive: "負轉正",
    turnsNegative: "正轉負",
    isTrendingUpward: "處於上漲",
    isTrendingDownward: "處於下跌",
    squeezing: "能量累積",
    strength: "能量爆發",
    power: "能量強度",
    any: "全部",
    nonePower: "無累積",
    minorPower: "弱",
    moderatePower: "中",
    strongPower: "強",
    positiveMomentum: "動能向上",
    negativeMomentum: "動能向下",

    microCap: "微型股 (< 300M)",
    smallCap: "小型股 (300M - 2B)",
    mediumCap: "中型股 (2B - 10B)",
    largeCap: "大型股 (> 10B)",

    volume: "20 日交易均量",
    marketCap: "市值",
    greaterThanVolume20MA: "交易量大於 20 日均量",
    lessThanVolume20MA: "交易量小於 20 日均量",

    addedSuccess: "成功新增機器人",
    failedToAdd: "新增機器人失敗，請稍後再試",
    updatedSuccess: "成功更新機器人",
    failedToUpdate: "更新機器人失敗，請稍後再試",

    instruction: "說明",
    addNew: "新增設定",
    addConditions: "點擊 + 來新增你的條件",

    orientedValue: ({
      oriented,
      variation,
      score,
    }: {
      oriented: string;
      variation: string;
      score: number;
    }) => `${oriented}：${variation} ${score} 分`,
    surfingTrendValue: (surfingTrend: string) =>
      `Surfing Trend：${surfingTrend}`,
    squeezeSqueezingValue: "Squeeze 能量累積：",
    squeezeStrengthValue: (energyMoving: string) =>
      `Squeeze 能量爆發${energyMoving ? `：${energyMoving}` : ""}`,
    marketCapValue: (marketCap: string) => `市值：${marketCap}`,
  },
  en: {
    cancelAdd: "Cancel",
    cancelEdit: "Cancel",
    save: "Save",
    close: "Close",

    basicSettings: "Basic Settings",
    robotName: "Robot Name",
    type: "Type",
    expiration: "Expiration",
    notifyBy: "Notify By",
    name: "Name",
    buyIn: "Buy In",
    sellOut: "Sell Out",

    followingContents: "Following Contents",
    chooseWatchlists: "Choose Watchlist",
    searchSymbols: "Search Symbols",
    addWatchlistOrSymbols: "Add watchlists or symbols",
    clean: "Clean",
    watchlistsSelected: (count: number) =>
      `${count} ${count > 1 ? "watchlists" : "watchlist"} selected`,
    alreadyExists: (symbol: string) => `${symbol} already exists.`,
    importedSuccessfully: (symbol: string) =>
      `${symbol} imported successfully.`,

    advancedSettings: "Advanced Setting",
    customizeYourStrategy:
      "Customize your own notification strategy based on market cap, average trading volume, 5 dimension scores.",
    notifyWhenConditionMet: "Notify when all conditions met!",
    customization: "Customization",
    valueRobot: "Value Investing",
    swingRobot: "Swing Trading",
    trendRobot: "Trend Following",

    fiveDimension: "5 Dimension Scores",
    fiveDimensionIntro: "Introduction of 5-Dimensional Scores",
    technicalIndicator: "Technical Indicator",
    others: "Others",
    value: "Value",
    trend: "Trend",
    swing: "Swing Trading",
    chip: "Whale Interest",
    dividend: "Dividend",
    status: "Status",
    score: "Value",

    daily: "Daily",
    weekly: "Weekly",
    crossAbove: "Crosses Above",
    crossBelow: "Crosses Below",
    greaterThan: "≥",
    lessThan: "≤",
    turnsPositive: "turns positive",
    turnsNegative: "turns negative",
    isTrendingUpward: "is trending upward",
    isTrendingDownward: "is trending downward",
    squeezing: "Squeezing",
    strength: "Strength",
    power: "Power",
    any: "Any",
    nonePower: "None",
    minorPower: "Minor",
    moderatePower: "Moderate",
    strongPower: "Strong",
    positiveMomentum: "Positive momentum",
    negativeMomentum: "Negative momentum",

    microCap: "Micro Cap (<300M)",
    smallCap: "Small Cap (300M-2B)",
    mediumCap: "Medium Cap (2B-10B)",
    largeCap: "Large Cap (>10B)",

    volume: "Volume",
    marketCap: "Market Cap",
    greaterThanVolume20MA: "20D Avg. Volume ≥",
    lessThanVolume20MA: "20D Avg. Volume <",

    addedSuccess: "Robot added successfully.",
    failedToAdd: "Failed to add robot, please try again later.",
    updatedSuccess: "Robot updated successfully.",
    failedToUpdate: "Failed to update robot, please try again later.",

    instruction: "Instruction",
    addNew: "Add New",
    addConditions: "Add Your Conditions",

    orientedValue: ({
      oriented,
      variation,
      score,
    }: {
      oriented: string;
      variation: string;
      score: number;
    }) => `${oriented}: ${variation} ${score}`,
    surfingTrendValue: (surfingTrend: string) =>
      `Surfing Trend: ${surfingTrend}`,
    squeezeSqueezingValue: "Squeeze Squeezing: ",
    squeezeStrengthValue: (energyMoving: string) =>
      `Squeeze Strength${energyMoving ? `: ${energyMoving}` : ""}`,
    marketCapValue: (marketCap: string) => `Market Cap: ${marketCap}`,
  },
};
