import { Country } from "@/data/constants/country";

export const backtest = {
  zh: {
    title: (symbol: string, orientedName: string) =>
      `${symbol} ${orientedName}回測模擬`,
    description:
      "在下方的股價線圖中標註了進、出場的時間、統計顯示累積報酬率及回測統計數據。",
    cumulativeROI: "對應的累積報酬率",
    ROI: "報酬率",
    enter: "進場時機",
    exit: "出場時機",
    run: "試算",
    result: ({ symbol, startDate }: { symbol: string; startDate: string }) =>
      `${symbol} ${startDate}至今回測結果`,
    historyAndEntryExit: "歷史價格 & 進出場點",
    similarPerformance: "相同類股產業",
    marketPerformance: (country: Uppercase<Country>): string =>
      country === "TW" ? "台股整體市場" : "美股整體市場",
    winningProbability: "獲利機率",
    averageProfitLoss: "平均損益",
    averageProfit: "平均獲利",
    averageLoss: "平均虧損",
    averageRiskRewardRatio: "平均賺賠比",
    cumulativeReturn: "總累積報酬率",
    annualReturn: "年化報酬率",
    volatility: "波動率",
    sharpeRatio: "Sharpe Ratio",

    statisticsDescription: (
      startTime: string,
      endTime: string,
      entry: string,
      exit: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      orientedName: string
    ) =>
      `自 ${startTime} 到 ${endTime} 在 ${entry} 分以上進場且在 ${exit} 分以下出場的統計數據`,
    total: (occurrence: number) => `（進出場共發生 ${occurrence} 次）`,
  },
  en: {
    title: (symbol: string, orientedName: string) =>
      `${symbol} ${orientedName} Strategy Backtest`,
    description:
      "The following backtest is based on specified rules since 2007. Price chart shows buy/sell time, cumulative returns, and backtesting statistics.",
    cumulativeROI: "Cumulative Returns",
    ROI: "Return",
    enter: "Entry",
    exit: "Exit",
    run: "Calculate",
    result: ({ symbol, startDate }: { symbol: string; startDate: string }) =>
      `The Backtest Result of ${symbol} from ${startDate} till Now`,
    historyAndEntryExit: "Historical Price & Buy/Sell Time",
    similarPerformance: "Sector",
    marketPerformance: (country: Uppercase<Country>): string =>
      country === "TW" ? "TW Overall Market" : "US Overall Market",
    winningProbability: "Wining Rate",
    averageProfitLoss: "Avg Return",
    averageProfit: "Avg Profit",
    averageLoss: "Avg Loss",
    averageRiskRewardRatio: "Avg Risk-Reward Ratio",

    cumulativeReturn: "Cumulative Return",
    annualReturn: "Annual Return",
    volatility: "Volatility",
    sharpeRatio: "Sharpe Ratio",

    statisticsDescription: (
      startTime: string,
      endTime: string,
      entry: string,
      exit: string,
      orientedName: string
    ) =>
      `Statistics data for entering at or above a ${orientedName} score of ${entry} and exiting at or below a ${orientedName} score of ${exit} from ${startTime} to ${endTime}.`,
    total: (occurrence: number) => `(Total Trades: ${occurrence})`,
  },
};
