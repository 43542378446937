export const auth = {
  zh: {
    title: {
      login: "Growin | 登入",
      loginBusiness: "Growin | 企業單位登入",
      signup: "Growin | 註冊",
      businessSupport: "Growin | 企業單位服務",
      forgotPassword: "Growin | 忘記密碼",
      resetPassword: "Growin | 重設密碼",
      emailVerification: "Growin | 驗證電子郵件",
    },
    description:
      "Growin 個股健診、個股走勢、個股分析工具：財報基本面及量價技術面分析，診斷美國個股標的價值、籌碼、股利、走勢、波段五大面向，掌握美股標的表現，找出投資潛力價值股！",
  },
  en: {
    title: {
      login: "Growin | Login",
      loginBusiness: "Growin | Login Business",
      signup: "Growin | Sign Up",
      businessSupport: "Growin | Business Support",
      forgotPassword: "Growin | Forgot Password",
      resetPassword: "Growin | Reset Password",
      emailVerification: "Growin | Email Verification",
    },
    description:
      "Growin combines AI and trading strategies, utilizing big data to offer passive financial services and active investment tools for investors.",
  },
};
