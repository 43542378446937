export const watchlist = {
  zh: {
    removeFromWatchlist: "從追蹤清單移除",
    addedToWatchlist: "已加入追蹤清單",
    addToWatchlist: "加入追蹤清單",
    empty: "您還沒有加入任何標的！",
    guest: ["免費註冊", "，加入關注標的！"],
    symbol: "代碼",
    create: "建立",
    newWatchlist: "建立新清單",
    limit: "清單數已達上限",
    seePlans: "查看其他訂閱方案",
    defaultWatchlistName: "我的追蹤清單",
    renameWatchlistSuccessToast: (newName: string) =>
      `成功更改名稱為 ${newName}`,
    renameWatchlistFailToast: "修改名稱失敗，請稍後再試",
    addWatchlistSuccessToast: (name: string) => `新增 ${name} 成功`,
    addWatchlistFailToast: (name: string) => `新增 ${name} 失敗，請稍後再試`,
    removeWatchlistSuccessToast: "刪除追蹤清單成功",
    removeWatchlistFailToast: "刪除追蹤清單失敗，請稍後再試",
    addSymbolSuccessToast: (symbol: string) => `成功追蹤 ${symbol}`,
    removeSymbolSuccessToast: (symbol: string) => `成功取消追蹤 ${symbol}`,
    removeConfirmDialog: {
      title: (name: string) => `確定要刪除 ${name} 嗎？`,
      description: (name: string) => `刪除後將無法復原 ${name} 的追蹤清單`,
      cancel: "取消",
      confirm: "確定",
    },
  },
  en: {
    removeFromWatchlist: "Remove from Watchlist",
    addedToWatchlist: "Following",
    addToWatchlist: "Add to Watchlist",
    empty: "There is no following symbol in this watchlist.",
    guest: ["Sign up/Login", " to see your symbols."],
    symbol: "Ticker",
    create: "Create",
    newWatchlist: "New watchlist",
    limit: "Upgrade for more list.",
    seePlans: "See plans",
    defaultWatchlistName: "My Watchlist",
    renameWatchlistSuccessToast: (newName: string) => `Renamed to ${newName}`,
    renameWatchlistFailToast: "Failed to rename, please try again later",
    addWatchlistSuccessToast: (name: string) => `Added ${name}`,
    addWatchlistFailToast: (name: string) =>
      `Failed to add ${name}, please try again later`,
    removeWatchlistSuccessToast: "Removed watchlist",
    removeWatchlistFailToast:
      "Failed to remove watchlist, please try again later",
    addSymbolSuccessToast: (symbol: string) => `Added ${symbol} to watchlist`,
    removeSymbolSuccessToast: (symbol: string) =>
      `Removed ${symbol} from watchlist`,
    removeConfirmDialog: {
      title: (name: string) => `Are you sure you want to delete ${name}?`,
      description: (name: string) => `Deleting ${name} will be irreversible.`,
      cancel: "Cancel",
      confirm: "Confirm",
    },
  },
};
