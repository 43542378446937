import { plan } from "./plan";

export const stripe = {
  zh: {
    loading: "資料處理中，請稍候...",
    successTitle: "訂閱成功",
    checkReceipt: "查看明細",
    homepage: "回到首頁",
    thanks: (planName: string) =>
      `感謝您訂閱${planName}，您可前往查看帳單或開始使用個股探勘服務！`,
    plan: plan.zh,
  },
  en: {
    loading: "Processing, please wait...",
    successTitle: "Subscription successful",
    checkReceipt: "Check the bill",
    homepage: "Homepage",
    thanks: (planName: string) =>
      `Thank you for subscribing to ${planName}, you can check the bill or start using the stock mining service!`,
    plan: plan.en,
  },
};
