export const us = {
  zh: {
    title: {
      pe: "本益比",
      forwardPe: "預期本益比",
      peg: "本益成長比",
      ps: "本銷比",
      pb: "股價淨值比",
      epsGrowthQoq: "EPS 季增長率",
      epsGrowthYoy: "EPS 年增長率",
      epsGrowthNextYear: "下一年 EPS 增長率",
      epsGrowthPastFiveYears: "過去5年 EPS 增長率",
      revenueGrowthQoq: "營收季增長率",
      revenueGrowthYoy: "營收年增長率",
      revenueGrowthPastFiveYears: "過去5年營收增長率",
      evOverEbitda: "企業價值/EBITDA",
      roe: "股東權益報酬率",
      roa: "資產報酬率",
      roic: "投資資本回報率",
      currentRatio: "流動比率",
      quickRatio: "速動比率",
      debtEquityRatio: "負債權益比",
      grossMargin: "毛利率",
      operatingMargin: "營業利益率",
      netProfitMargin: "淨利率",
      payoutRatio: "配息比率",
      institutionalOwnership: "機構持股比例",
      dividendYield: "股息收益率",
      netProfitGrowthQoq: "淨利季增長率",
      netProfitGrowthYoy: "淨利年增長率",
      netProfitGrowthPastFiveYears: "過去5年淨利增長率",
      avgYoyRevenueGrowthLastThreeQuarters: "近3季平均營收增長率",
      avgYoyEpsGrowthLastThreeQuarters: "近3季平均EPS增長率",
      avgYoyNetProfitGrowthLastThreeQuarters: "近3季平均淨利增長率",
    },
  },
  en: {
    title: {
      pe: "P/E Ratio",
      forwardPe: "Forward P/E",
      peg: "PEG Ratio",
      ps: "P/S Ratio",
      pb: "P/B Ratio",
      epsGrowthQoq: "EPS Growth (QoQ)",
      epsGrowthYoy: "EPS Growth (YoY)",
      epsGrowthNextYear: "EPS Growth Next Year",
      epsGrowthPastFiveYears: "EPS Growth Past 5 Years",
      revenueGrowthQoq: "Revenue Growth (QoQ)",
      revenueGrowthYoy: "Revenue Growth (YoY)",
      revenueGrowthPastFiveYears: "Revenue Growth Past 5 Years",
      evOverEbitda: "EV/EBITDA",
      roe: "Return on Equity",
      roa: "Return on Assets",
      roic: "Return on Invested Capital",
      currentRatio: "Current Ratio",
      quickRatio: "Quick Ratio",
      debtEquityRatio: "Debt/Equity Ratio",
      grossMargin: "Gross Margin",
      operatingMargin: "Operating Margin",
      netProfitMargin: "Net Profit Margin",
      payoutRatio: "Payout Ratio",
      institutionalOwnership: "Institutional Ownership",
      dividendYield: "Dividend Yield",
      netProfitGrowthQoq: "Net Profit Growth (QoQ)",
      netProfitGrowthYoy: "Net Profit Growth (YoY)",
      netProfitGrowthPastFiveYears: "Net Profit Growth Past 5 Years",
      avgYoyRevenueGrowthLastThreeQuarters: "Average Revenue Growth Last 3Q",
      avgYoyEpsGrowthLastThreeQuarters: "Average EPS Growth Last 3Q",
      avgYoyNetProfitGrowthLastThreeQuarters:
        "Average Net Profit Growth Last 3Q",
    },
  },
};
