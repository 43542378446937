export const us = {
  zh: {
    title: {
      marketCap: "市值",
      shortInterest: "Short Interest",
      analystRating: "分析師評分",
      rsRating: "RS Rating (Relative Strength)",
    },
    marketCap: {
      microCap: "微型股 (50M - 300M)",
      smallCap: "小型股 (300M - 2B)",
      midCap: "中型股 (2B - 10B)",
      largeCap: "大型股 (10B - 200B)",
      megaCap: "巨型股 (超過 200B)",
      lteLargeCap: "<= 大型股 (低於 10B )",
      lteMidCap: "≤ 中型股 (低於 2B)",
      lteSmallCap: "≤ 小型股 (低於 300M)",
      lteMicroCap: "≤ 微型股 (低於 50M)",
      gteMicroCap: "≥ 微型股 (超過 50M)",
      gteSmallCap: "≥ 小型股 (超過 300M)",
      gteMidCap: "≥ 中型股 (超過 2B)",
      gteLargeCap: "≥ 大型股 (超過 10B )",
    },
    shortInterest: {
      under30: "低於 30%",
      under25: "低於 25%",
      under20: "低於 20%",
      under15: "低於 15%",
      under10: "低於 10%",
      under5: "低於 5%",
      over5: "超過 5%",
      over10: "超過 10%",
      over15: "超過 15%",
      over20: "超過 20%",
      over25: "超過 25%",
      over30: "超過 30%",
    },
    analystRating: {
      strongSell: "強力賣出",
      sell: "賣出",
      hold: "持有",
      buy: "買進",
      strongBuy: "強力買進",
    },
    rsRating: {
      oneToForty: "1 到 40",
      fortyToSeventy: "40 到 70",
      seventyToNinety: "70 到 90",
      overFifty: "超過 50",
      overSeventy: "超過 70",
      overNinety: "超過 90",
    },
    volume20MA: {
      under50K: "低於 50K",
      under50Kto1M: "50K - 1M",
      under1Mto5M: "1M - 5M",
      under5Mto10M: "5M - 10M",
      under10Mto50M: "10M - 50M",
      over1M: "超過 1M",
      over5M: "超過 5M",
      over10M: "超過 10M",
      over50M: "超過 50M",
    },
  },
  en: {
    title: {
      marketCap: "Market Cap",
      shortInterest: "Short Interest",
      analystRating: "Analyst Rating",
      rsRating: "RS Rating (Relative Strength)",
    },
    marketCap: {
      microCap: "Micro (50M to 300M)",
      smallCap: "Small (300M to 2B)",
      midCap: "Mid (2B to 10B)",
      largeCap: "Large (10B to 200B)",
      megaCap: "Mega (Over 200B)",
      lteLargeCap: "≤ Large (under 10B)",
      lteMidCap: "≤ Mid (under 2B)",
      lteSmallCap: "≤ Small (under 300M)",
      lteMicroCap: "≤ Micro (under 50M)",
      gteMicroCap: "≥ Micro (over 50M)",
      gteSmallCap: "≥ Small (over 300M)",
      gteMidCap: "≥ Mid (over 2B)",
      gteLargeCap: "≥ Large (over 10B )",
    },
    shortInterest: {
      under30: "Under 30%",
      under25: "Under 25%",
      under20: "Under 20%",
      under15: "Under 15%",
      under10: "Under 10%",
      under5: "Under 5%",
      over5: "Over 5%",
      over10: "Over 10%",
      over15: "Over 15%",
      over20: "Over 20%",
      over25: "Over 25%",
      over30: "Over 30%",
    },
    analystRating: {
      strongSell: "Strong Sell",
      sell: "Sell",
      hold: "Hold",
      buy: "Buy",
      strongBuy: "Strong Buy",
    },
    rsRating: {
      oneToForty: "1 to 40",
      fortyToSeventy: "40 to 70",
      seventyToNinety: "70 to 90",
      overFifty: "over 50",
      overSeventy: "over 70",
      overNinety: "over 90",
    },
    volume20MA: {
      under50K: "Under 50K",
      under50Kto1M: "50K to 1M",
      under1Mto5M: "1M to 5M",
      under5Mto10M: "5M to 10M",
      under10Mto50M: "10M to 50M",
      over1M: "Over 1M",
      over5M: "Over 5M",
      over10M: "Over 10M",
      over50M: "Over 50M",
    },
  },
};
