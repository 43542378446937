export const login = {
  zh: {
    blankAccount: "帳號不得為空白",
    blankEmail: "電子郵件不得為空白",
    invalidEmail: "請輸入有效電子郵件",
    blankPassword: "密碼不得為空白",
    emailPasswordError: "帳號或密碼輸入錯誤，請再試一次",
    genericError: "登入失敗，請稍後再試一次",
    forgotPassword: "忘記密碼？",
    notAMember: "還不是會員嗎？",
    notBusinessUnit: "還不是企業單位嗎？",
    signUp: "註冊",
    signUpBusiness: "聯絡業務",
    login: "登入",
    password: "密碼",
    email: "電子郵件",
    account: "帳號",
    pleaseLogin: "請登入 Growin 帳號",
    googleLogin: "使用 Google 登入",
    continueUsing: "或繼續使用",
    pleaseLoginBusiness: "請登入你的企業帳號",
    serviceDescription:
      "透過大量的數據解析，檢視投資市場及標的表現狀況，協助您提高投資勝率",
    openExternalBrowser: "請開啟外部瀏覽器使用 Google 登入",
  },
  en: {
    blankAccount: "Account can not be blank",
    blankEmail: "Email Address can not be blank",
    invalidEmail: "Should be a valid email",
    blankPassword: "Password can not be blank",
    emailPasswordError:
      "The account and password combination you entered is incorrect",
    genericError: "Login failed, please try again later",
    forgotPassword: "Forgot password ?",
    notAMember: "Don’t have an account ?",
    notBusinessUnit: "Not a business unit ?",
    signUp: "Sign up",
    signUpBusiness: "Contact us",
    login: "Sign in",
    password: "Password",
    email: "Email Address",
    account: "Account",
    pleaseLogin: "Please login to your account",
    googleLogin: "Login with Google",
    continueUsing: "or continue using",
    pleaseLoginBusiness: "Please login to your business account",
    serviceDescription:
      "We can help you improve your investment performance by analyzing a large amount of data and reviewing the performance of the investment market and the underlying.",
    openExternalBrowser: "Please open external browser to login with Google",
  },
};
