import { questions } from "./questions";

export const help = {
  zh: {
    title: "幫助中心 - Growin",
    description:
      "Growin 幫助中心專區，提供 Growin 介紹、投資策略、開戶注資、收費機制和稅務等相關問題解答。",
    questions: questions.zh,
  },
  en: {
    title: "Help Center - Growin",
    description:
      "Growin Help Center, providing answers to questions about Growin introduction, investment strategies, account opening, funding, charging mechanism and taxation.",
    questions: questions.en,
  },
};
