export const layout = {
  zh: {
    banner: "市場預警燈號",
    latestSignal: "最新預警燈號",
    current4Signals: "前四次燈號",
    week: "週前",
    updateTo: "資料更新至",
    subscribe: (content: string) => `訂閱任一方案，解鎖查看「${content}」`,
    tabs: {
      historicalSignal: "燈號歷史數據",
      referenceFactor: "燈號參考元素",
      fundingLevelSimulation: "資金水位模擬",
    },
  },
  en: {
    banner: "Market Warning Indicator",
    latestSignal: "The Latest Signal",
    current4Signals: "Current 4 Signals",
    week: "Week",
    updateTo: "Update to",
    subscribe: (content: string) =>
      `Subscribe for any plan to unlock「${content}」`,
    tabs: {
      historicalSignal: "Historical Signal",
      referenceFactor: "Reference Factor",
      fundingLevelSimulation: "Funding Level Simulation",
    },
  },
};
