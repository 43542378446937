import { Country } from "@/data/constants/country";
import { Lang } from "@/i18n-config";

export const currency: Record<Lang, Record<Uppercase<Country>, string>> = {
  zh: {
    US: "美元",
    TW: "新台幣",
  },
  en: {
    US: "USD",
    TW: "TWD",
  },
};
