export const questions = {
  zh: {
    title: {
      "securities-account": "開戶 | 透過 Growin 開的證券戶會開在哪裡？",
      "fund-position": "證券戶 | Growin 是否會持有客戶的資金部位？",
      "account-security": "證券戶 | 我的資金在 Growin 的證券帳戶中安全嗎？",
      "trading-stocks": "證券交易 | Growin 如何幫客戶下單股票？",
      fee: "收費機制 | 證券戶費用有哪些？會收手續費嗎？",
      "management-fee": "收費機制 | 管理費怎麼計算和收取？",
      "investment-threshold": "收費機制 | 最低投資門檻為何？",
      "buying-instructions": "注資與撤資 | 如何注資？流程有哪些？",
      remittance: "注資與撤資 | 每次電匯帳號相同嗎？",
      "ai-driven-asset-allocation":
        "注資與撤資 | 可以先注資一筆資金，再分批進場嗎？",
      "selling-instructions": "注資與撤資 | 如何撤資？流程有哪些？",
      "minimum-balance":
        "注資與撤資 | 撤資後，帳戶金額低於最低投資門檻怎麼辦？",
      "usage-steps": "使用步驟 | 如何開始使用機器人理財 Growin APP？",
      features: "全自動化 AI 智能理財服務 | 產品特色",
      "investment-strategies": "投資策略 | 資產配置 x 價值選股投資",
      "ai-investment-plans": "如何運用 AI 打造投資策略？ | 投資策略",
      "ai-trading-timing": "進場時機 | 投資策略",
      "protection-mechanism": "避險機制 | 投資策略",
      "portfolio-adjustment": "投資組合 調整時機與頻率 | 投資策略",
      "us-tax-resident-form-1999":
        "Growin 提供什麼資料用以報稅？ | 美國身份稅務",
      "us-resident-download-tax-information":
        "稅務資料何時能存取？ | 美國身份稅務",
      "us-resident-tax-filing-service":
        "Growin 會幫客戶報稅嗎？ | 美國身份稅務",
      "us-resident-get-tax-information":
        "如何取得報稅所需相關資訊？ | 美國身份稅務",
      "non-us-resident-tax-payment":
        "外國人投資美股，需被美國政府課稅嗎？ | 他國身份稅務",
      "non-us-resident-form-1042-S":
        "從 Growin 獲取稅務相關資訊 | 他國身份稅務",
      "non-us-resident-get-tax-information":
        "海外投資人如何取得稅務相關資訊？ | 他國身份稅務",
    },
    description: {
      "securities-account":
        "透過 Growin App，我們會引導您在美國券商 Interactive Brokers (IBKR) 開立一個證券戶，透過 IBKR 做帳務管理、執行證券交易及清算服務。",
      "fund-position":
        "透過 Growin 在美國券商 Interactive Brokers (IBKR) 開一個屬於您自己名下的證券戶，資金及部位皆會在您自己的證券帳戶中，Growin 團隊不會經手或持有。",
      "account-security":
        "Growin 幫投資人開立的美國券商 Interactive Brokers，連續十年獲評為美國頂級互聯網券商，您可以安心把資金放在證券帳戶中。",
      "trading-stocks":
        "Growin 提供智能投資顧問服務，會透過在美國券商 InteractiveBrokers 的主帳戶來操作客戶帳戶。我們會以此主帳戶交易下單，再統一配單回每個客戶帳戶做股票交割。",
      fee: "Growin 堅持透明收費，費用簡單，只需年管理費，管理費費率是依照每個用戶的投資計畫風險等級來收取，約介於 0.5%~1%，且不會再向客戶收取證券交易相關手續費。",
      "management-fee":
        "Growin 不收任何手續費與績效費，所有的交易費用由 Growin 承擔，只會向客戶收取年管理費，依據用戶風險的不同，一年的管理費用介於 0.5%~1%，按每月從帳戶扣款。",
      "investment-threshold":
        "Growin 目前提供風險分數為 1~10 分投資計畫。風險 1~5 分的投資計畫最低投資門檻為 2,000 美金；風險 6~10 分的計畫因一次擁有 20 檔以上投資標的，最低門檻為 8,\b\b000 美金。",
      "buying-instructions":
        "由 Growin App 內發起『注資通知』，其內容會包含您欲匯款之金額以及預計由哪個銀行進行匯款。發起後，您會得到電匯所需的相關資訊：收款人戶名、地址、帳號及收款銀行相關資訊。",
      remittance:
        "您看到的電匯帳號專屬您擁有，並不會跟任何人重複，每一次您均是透過相通的帳號進行電匯。",
      "ai-driven-asset-allocation":
        "目前 Growin 並無提供分批進場的機制，Growin 演算法會將所有投入的資金，按照現行最佳配置來做投資，所有的資金均會投資進場。",
      "selling-instructions":
        "Growin 視您的資金帳戶安全為最優先的考量，因此在使用電匯撤資時，我們會需要一些步驟程序來認證您的身份。",
      "minimum-balance":
        "提醒您，取款後的帳戶價值仍須符合 Growin 的最低投資門檻。若欲撤資的金額導致帳戶價值低於投資門檻需求，Growin 會請您進行全額撤資。",
      "usage-steps":
        "Growin 機器人理財透過智能理財 APP，提供投資人多重投資策略。開始使用 Growin APP 有以下 4 個步驟：1. 風險衡量、2. 投資計畫推薦、3. 開證券戶、4. 注資證券戶",
      features:
        "Growin 機器人理財提供自動化 AI 智能理財服務，投資於美國 ETF 及個股上，我們會依據客戶可承受的風險，客製化提供 4.5%~18% 年化報酬率的 AI 投資計畫。",
      "investment-strategies":
        "Growin 的投資策略有兩大重點：資產配置＆價值選股投資。我們會運用 AI 找出全球上漲機率高的市場，考量風險分散，並依照價值投資策略，產出最合適的投資組合。",
      "ai-investment-plans":
        "Growin 運用兩種 AI 演算法打造投資策略：(1) 市場指數預測 AI：預測全球主要市場未來的趨勢；(2) AI 選股：透過 AI 運算，找尋中長期有價值的個股進行投資。",
      "ai-trading-timing":
        "只要演算法預測、判斷還有投資的價值與空間，就會持續投資到市場上；反之，則啟動保護資產機制。Growin 所有投資策略均以中長期為主，為您打造穩健、持續上漲的投資組合。",
      "protection-mechanism":
        "Growin 避險機制主要透過債券及黃金 ETF 商品，具有與股市呈現負相關或低度相關的特性，涵蓋這些資產工具在投資組合策略之中，將可預防股市大跌時造成的投資組合下跌風險。",
      "portfolio-adjustment":
        "Growin 會自動監控投資組合表現，依據投資市場變化，自動觸發投資組合的調整。調整的頻率每個投資策略會略微不同，但皆遵循「時間觸發」、「條件式觸發」這兩種機制。",
      "us-tax-resident-form-1999":
        "因 Growin 目前提供的是一般帳戶的投資服務，您可預期取得 Form-1099，其中包含了收到的股息 (Dividends) 以及已實現損益的相關資訊",
      "us-resident-download-tax-information":
        "您的稅務相關資料會由 Growin 為您開證券戶的券商產出。",
      "us-resident-tax-filing-service":
        "Growin 會為您在 InteractiveBrokers 這間美國券商開戶，您的稅務資料也是由 InteractiveBrokers 產出。Growin 會協助您取得報稅所需相關資訊，但不提供報稅相關諮詢。",
      "us-resident-get-tax-information":
        "Growin 為您開戶的券商 InteractiveBrokers 提供兩種方式取得報稅相關資訊，首先登入該券商的後台：1. 與報稅軟體自動同步資料，2. 自行下載報稅所需資料。",
      "non-us-resident-tax-payment":
        "股票獲利分為資本利得和股票配息。美國政府為鼓勵其他國家投資人投資美股，不會課外國人資本利得稅，但不論是外國人或美國人，都須繳納 30 ％的股利所得稅",
      "non-us-resident-form-1042-S":
        "Growin 為您開戶的券商為 InteractiveBrokers，您的稅務相關資料也是由 InteractiveBrokers 負責產出。",
      "non-us-resident-get-tax-information":
        "由於 Growin 為您開戶的券商為 InteractiveBrokers，需要登入至券商後台，選取『 報告/稅務文件 』>『 稅務 』，即可以下載相關資料。",
    },
  },
  en: {
    title: {
      "securities-account": "Securities Account Position - Growin",
      "fund-position": "Fund Position - Growin",
      "account-security": "The Safety of Securities Account - Growin",
      "trading-stocks": "Trading Stocks with US Broker- Growin",
      fee: "Other Fees - Growin",
      "management-fee": "Management Fee Charge - Growin",
      "investment-threshold": "Investment Threshold - Growin",
      "buying-instructions": "The Instruction of Deposit- Growin",
      remittance: "The Remittance Information - Growin",
      "ai-driven-asset-allocation": "AI-driven Asset Allocation - Growin",
      "selling-instructions": "The Instruction of Withdrawal- Growin",
      "minimum-balance": "The Minimum Balance after Withdrawing Funds - Growin",
      "usage-steps": "Usage Steps - Growin",
      features: "Features - Growin",
      "investment-strategies": "Investment Strategy - Growin",
      "ai-investment-plans": "Investment Strategy with AI - Growin",
      "ai-trading-timing": "AI-trading Buying Timing Strategy - Growin",
      "protection-mechanism": "Protection Mechanism Growin",
      "portfolio-adjustment": "AI-driven Rebalance - Growin",
      "us-tax-resident-form-1999":
        "US Resident Get Form1999 from Growin - Growin",
      "us-resident-download-tax-information":
        "US Resident Download Tax Information- Growin",
      "us-resident-tax-filing-service":
        "US Resident Tax Filling Service - Growin",
      "us-resident-get-tax-information":
        "US Resident Get Tax Information - Growin",
      "non-us-resident-tax-payment": "US Resident Get Tax Information - Growin",
      "non-us-resident-form-1042-S":
        "Non-US Resident Get Form1042 from Growin - Growin",
      "non-us-resident-get-tax-information":
        "Non-US Resident Get Tax Information - Growin",
    },
    description: {
      "securities-account":
        "We will help you to open a securities account of IBKR through Growin APP. Account management, trade execution and clearing will be done through IBKR.",
      "fund-position":
        "Your Growin account will be maintained by Interactive Brokers (IBKR). The account that you open with Interactive Brokers (IBKR) is under your name and will not be hold by Growin in any way anytime.",
      "account-security":
        "Growin account is maintained by Interactive Brokers (IBKR). IBKR is reviewed and ranked by Barron’s as the best online broker of 2020. The protection to the accounts in IBKR is better than the industry standard.",
      "trading-stocks":
        "Growin is an automated investment advisory service. Growin has a master Account to monitor and control every transactions in connection with each client’s account (sub account). Growin master account will place the orders that are aggregated across all the sub accounts. And then the allocation and settlement to each sub account will be proportional.",
      fee: "Only annual management fee is needed. Each investment plan with different risk level has corresponding annual fee which is in the range of 0.5% to 1% of you account value.",
      "management-fee":
        "Growin doesn’t charge transaction fee and performance fee. All transaction fees will be covered by Growin. Only annual management fee will be charged. Each investment plan with different risk level has corresponding annual fee which is in the range of 0.5%~1% of you account value.",
      "investment-threshold":
        "Growin ranks 10 investment plans in the order of risk level. A minimum of $2,000 for the investment plans with lower risk level 1 to 5 is required. If the investment plan with higher risk level (6 to 10) is chosen, a minimum of $6,000 is required.",
      "buying-instructions": `You can make a "deposit notification" request through Growin APP. The information includes name of receiving party, address of receiving party, account number of recipient.`,
      remittance:
        "The account that you wire transfer to is only for you and will remain the same.",
      "ai-driven-asset-allocation":
        "Once the money you deposit has arrived Growin account, it will all be allocated to the investment portfolio accordantly at once.",
      "selling-instructions":
        "Your account security is our first priority. Therefore, we need a further ID verification when a withdrawal is requested.",
      "minimum-balance":
        "Please note that the balance has to meet the minimum requirement to maintain the account after withdrawal. If the balance is lower than the threshold, you will be asked to withdraw all your money in your Growin account.",
      "usage-steps":
        "Growin's robo advisor provides various investment strategies. You can follow the 4 main steps as below to get started :1. Investment Risk Questionnaire, 2. Risk Calculator, 3. Account Opening, 4. Fund Deposit",
      features:
        "Growin is an automatic investment service that helps you invest your money on US ETFs and stocks. With different risk levels, the historical performance of the plan delivers 4.5%~18% annual returns in average.",
      "investment-strategies":
        "There are 2 main investment strategies of Growin: Asset allocation & Value investing. We will utilize AI to identify global markets with a high probability of upward movement, taking into account risk diversification, and generate the most suitable investment portfolio according to a value investing strategy.",
      "ai-investment-plans":
        "Growin investment plan is forged by 2 types of AI engines. (1) Market index AI: This AI engine will give us a view to the global market sentiment. (2) Stock investing AI: Growin use this stock investing AI engine plus quantitative strategies to aim to find out the stocks that have the most potential to grow.",
      "ai-trading-timing":
        "The protection mechanism automatically adjusts the portfolio to control the risk. However, the risk exists either way. Our customers are suggested to keep long term investment thinking in mind.",
      "protection-mechanism":
        "Growin’s protection mechanism is using bond and gold ETFs to ease the risk that comes along with the stock market. These assets usually have inverse or little correlation to the stock-related assets.",
      "portfolio-adjustment":
        "Growin will monitor the performance of your portfolio automatically. A rebalance will be triggered based on the market circumstances. Rebalancing is a process of realigning the weight of a portfolio of assets when the prospective return and volatility of an asset deviates to a certain level.",
      "us-tax-resident-form-1999":
        "Growin is providing normal account investment service. So you can expect to get Form-1099 which will include the information of dividends and the realized returns or loss.",
      "us-resident-download-tax-information":
        "Your tax information will be prepared and provided by the brokerage firm who maintains your Growin account.",
      "us-resident-tax-filing-service":
        "The tax filing however is not covered by Growin’s service. Growin will lead you to get access to your tax information from the brokerage firm.",
      "us-resident-get-tax-information":
        "The brokerage firm, interactive Brokers (IBKR) provides 2 options to get the tax information. Firstly, you have to login to your IBKR account. (please see here for how to login your brokerage account) Then you can access to the tax information.",
      "non-us-resident-tax-payment":
        "You will earn profit from capital gain and dividends when you do the stock investment. As an incentive to invest in the US stock market, a foreign investor doesn’t need to pay the tax for capital gain to US government. However, a 30% tax for the dividends is required no matter which country resident you are.",
      "non-us-resident-form-1042-S":
        "You will get Form 1042-S from the brokerage firm (IBKR) indicating how many interests, dividends you have received and the applicable tax rate. ",
      "non-us-resident-get-tax-information":
        "Your brokerage firm, interactive Brokers (IBKR) will prepare and provide tax information. Two steps to show you how to get access to your tax information.",
    },
  },
};
