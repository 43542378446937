export const factor = {
  zh: {
    title: (symbol: string) => `${symbol} 趨勢參考因子`,
    exclusive: "獨家指標",
    description:
      "趨勢力道分數依據多種數據統合而成，主要包含價量、技術指標等資訊，以下列出評價時其中兩個重要的因子。",
    daily: "日線",
    recent60Days: "近 60 日",
    weekly: "週線",
    recent52Weeks: "近 52 週",
    exclusiveData: "獨家數據",
    backtestStatistics: "回測統計結果",
    backtestData: "回測數據",
    trendScore: "趨勢分數",
    occurrence: "發生次數",
    averagePeriod: "平均期間天數",
    averageReturn: "平均期間報酬",
    upward: "上漲次數",
    upRate: "上漲比率",
    upAverage: "上漲平均幅度",
    downward: "下跌次數",
    downRate: "下跌比率",
    downAverage: "下跌平均幅度",
    powerSqueezeRadarInfo: [
      "用來偵測股價是否處於累積能量的狀態，橫軸上的小點顏色代表累積能量強度狀態，能量累積訊號分為強",
      "、中",
      "、弱",
      "、無能量累積",
      "。通常能量累積越強，代表股價後續可能會有較大的趨勢產生！當量能增加代表可能出現股價向上爆發的現象。反之，則可能出現股價向下爆發的現象。",
    ],
    surfingTrendRadarInfo:
      "用來衡量股價趨勢的方向，當顯示為綠色時，表示有較高的機率上漲的趨勢將持續;反之，當顯示的顏色為紅色時，表示有較高的機率為下降的趨勢。若投資人做多，需注意由綠轉紅，代表上漲趨勢或終結;若投資人作空，須注意由紅轉綠，代表下降趨勢或終結。",
    instructionalVideos: "前往教學影片參考",
    historicalResult: (symbol: string) =>
      `以下為 ${symbol} 在各種趨勢分數期間表現情形。`,
  },
  en: {
    title: (symbol: string) => `${symbol} Trend Reference Factors`,
    exclusive: "Exclusive",
    description:
      "The Trend score is derived from various data, mainly including price, volume, technical indicators and other information. The following lists two important factors when evaluating.",
    daily: "Daily",
    recent60Days: "60 Days",
    weekly: "Weekly",
    recent52Weeks: "52 Weeks",
    exclusiveData: "Exclusive",
    backtestStatistics: "Trend Backtest",
    backtestData: "Backtest Data",
    trendScore: "Trend Score",
    occurrence: "All Trades",
    averagePeriod: "Avg Days Held",
    averageReturn: "Avg Return",
    upward: "Winning Trades",
    upRate: "Winning Rate",
    upAverage: "Avg Profit%",
    downward: "Losing Trades",
    downRate: "Losing Rate",
    downAverage: "Avg Losing%",
    powerSqueezeRadarInfo: [
      "The PowerSqueeze indicator is employed to identify whether a stock's price is undergoing an energy accumulation phase. The colors of the small dots along the horizontal axis signify the intensity of accumulated energy. These energy accumulation signals are classified as strong",
      ", moderate",
      ", weak",
      ", or absent",
      ". Typically, the more robust the energy accumulation, the higher the potential for a substantial trend in the stock price to emerge. Elevated momentum bar often suggests the likelihood of an upward price breakout. Conversely, a decline in momentum bar could indicate a potential downward price breakout.",
    ],
    surfingTrendRadarInfo:
      "The Surfing Trend indicator is used to gauge the direction of a stock price trend. When it displays as green, it signifies a higher probability of a continuing upward trend. Conversely, when the color displayed is red, it suggests a higher likelihood of a descending trend. For investors taking long positions, it's important to be cautious if the color shifts from green to red, indicating a potential end to the upward trend. Similarly, for investors taking short positions, it's crucial to be attentive if the color shifts from red to green, signifying a potential end to the downward trend.",
    instructionalVideos: "Refer to instructional videos for more information",
    historicalResult: (symbol: string) =>
      `The following is the performance of ${symbol} in Trend score 1 to 5.`,
  },
};
