export const insiderHolding = {
  zh: {
    title: "董監事持股",
    weeks: "週",
    holdingRatio: "董監事持股比率",
    closePrice: "股價",
    ratioChange: ({ rate }: { rate: number }) =>
      `近一個月董監事持股比率${rate !== 0 ? (rate > 0 ? "增加" : "減少") : ""}`,
    unchanged: "不變",
  },
  en: {
    title: "Holdings of Directors and Supervisors",
    weeks: "weeks",
    holdingRatio: "Holding Ratio",
    closePrice: "Price",
    ratioChange: ({ rate }: { rate: number }) => `Change in last month:
    ${rate !== 0 ? (rate > 0 ? "" : "") : ""}`,
    unchanged: "No Change",
  },
};
