export const about = {
  zh: {
    missionSection: [
      "Growin 使命",
      "透過量化投資經驗與 AI 大數據的結合，提供主動及被動投資人都可輕鬆擁有更好的投資環境。Growin 相信不論資產的多寡，只要透過正確、有效率的方法並持續累積，就會與眾不同！",
    ],
    companySection: [
      "公司介紹",
      "TradingValley Inc. 是一間由美國 SEC 核可的線上投資顧問公司，主要是由量化交易員、資料科學家、財務分析、工程師、設計師及成長行銷組成。TradingValley 致力將數十年的量化交易經驗結合先進的 AI 技術，打造了「Growin 個股探勘」及「Growin 智能理財」兩套服務，希望幫助處在人生各階段的主動及被動投資人可以找到專屬於自己的理財計畫做其後盾！",
    ],
    historySection: [
      "公司歷史沿革",
      "2014.11.19",
      "TradingValley 團隊成立",
      "2015 美國矽谷",
      "獲選矽谷台灣天使群 (SVTA) 新創企業 / 團隊",
      "從 42 個團隊中入選為前 3 名團隊參加 SVTA Program ( Silicon Valley Taiwan Angel )，在美國知名加速器 Plug & Play 進行三個月交流。",
      "2017 香港",
      "BNP Startup Challenge 獲獎",
      "代表台灣金融科技新創團隊參加法國巴黎人壽金融科技競賽，以 AI 演算提供客製化財務管理服務，獲得了 Customer Centricity 獎項。",
      "2018.10 美國",
      "成為 SEC 註冊合格的線上投資顧問",
      "與美國 SEC 註冊為線上投資顧問，為自建的智能理財服務做準備。團隊的工程師們開始將自行研發的投資智能與券商的系統整合。",
      "2019.07- 09 澳洲",
      "墨爾本 Startupbootcamp 加速計畫",
      "從全球 900 多家金融科技新創中脫穎而出至澳洲參加 Startupbootcamp 加速計畫，拓展國際市場。",
      "2019.10 臺灣",
      "於數位時代採訪中提出團隊的理念與未來計畫，致力打造一流的智能財富管理服務，並且可為每個人所擁有。 ",
      "媒體報導連結",
      "2020 美國",
      "Growin 智能理財 上線",
      "在 2020 年為被動投資人打造了智能理財 APP 服務！透過大數據 AI 演算法，讓任何投資人都可以做全自動資產管理，專注生活也擁有更穩健的投資！",
      "2022 美國/台灣",
      "Growin 個股探勘 上線",
      "2022 年，團隊決定將智能理財背後的 AI 演算法加以活化應用，希望讓主動投資人也能得到大數據分析的量化服務，因此打造了個股探勘，讓 AI 大數據為投資人分析上市公司於未來短、中、長期的體質狀態，進而提升投資人的投資勝率！",
    ],
    founderSection: [
      "彭瀚生",
      "執行長 & 創辦人",
      "紐約哥倫比亞大學財務數學碩士，擁有 12 年以上的量化交易經驗，非常熱衷於開發投資交易相關的演算法，並應用於投資中。同時本身也是一個夢想家，目標打造一個能對大眾有意義並影響力深遠的產品及服務。 ",
    ],
    departmentSection: [
      "部門介紹",
      "TradingValley Inc. 是由計量、工程、設計、行銷、業務團隊所組成，各部門發揮各自的專業技術，合力攜手打造出 Growin 智能理財服務。由執行長帶領的計量團隊，負責開創核心演算法，提供穩定增長的投資策略；工程團隊負責應用程式、交易系統、資訊安全等建置，提供全自動化的市場監控及調整交易；而設計團隊依照使用者的需求提供簡易上手的操作介面及優質體驗；最後，行銷及業務團隊，帶領 Growin 走入大眾市場，提供專業的引導及解說，讓用戶更能安心理財。",
    ],
  },
  en: {
    missionSection: [
      "Mission of Growin",
      "Growin is dedicated to using the best technology to enable everyone easy access to the best financial management. We believe that regardless of the amount of assets, as long as the correct and efficient investment methods are adopted, and continue to accumulate your assets through such methods, your financial well-being will be outstanding.",
    ],
    companySection: [
      "Company Introduction",
      "TradingValley Inc. is a SEC registered online investment advisor. Our team are formed by Quants, Data Scientists, Financial Analysts, Engineers, and Designers. We are dedicated to using advanced technology to reshape the field of wealth management. Combined with our decades of quantitative investing experiences with AI, ",
      "we launched Growin - an ai-driven automated investing service",
      ", and aim to help every user to have excellent investment plans  in different life stages, such that each user will be prepared and equipped with the best available gears to take on and pursue his or her own dreams.",
    ],
    historySection: [
      "The History of Growin",
      "2014.11.19",
      "TradingValley Founded",
      "2015 Silicon Valley, USA",
      "Top 3 Selected Startups for SVTA Program",
      "One of three teams winning the ticket (out of 42 teams competitions) to work and engage with successful entrepreneurs and VCs in the Silicon Valley.",
      "2017 Hong Kong",
      "BNP Fintech Challenge Awards",
      "As the No.1 of Taiwan BNP Startup candidate, our team went to HK for the finalist pitch. Our product showed how to use AI to provide a customized wealth management service, and won the prize of 'customer centricity'.",
      "2018.10 USA",
      "Registered as An Online Investment Advisor with SEC",
      "Became a SEC registered online investment advisor, and prepared for our own the self-brand investment service. Our developer team started to integrated our investing intelligence with brokerage system.",
      "2019.07 - 09 Melbourne, Australia",
      "Startupbootcamp Fintech Accelerator Program",
      "Selected as top 10 startups among more than 900 fintech startups from all over the globe. Our team started to expand into the global market.",
      "2019.10 Taiwan",
      "In the interview of Meet, TradingValley presented their vision and future plans, dedicated to creating top-tier intelligent wealth management services accessible to everyone.",
      "Media Coverage",
      "2020 USA",
      "Growin APP Launch",
      "In 2020, we developed an intelligent financial app service tailored for passive investors! Utilizing big data and AI algorithms, it allows any investor to engage in fully automated asset management, enabling a focus on life while achieving a more robust investment portfolio!",
      "2022 TWN/USA",
      "Growin Stock Mining Launch",
      "In 2022, team decided to leverage and apply the AI algorithms behind intelligent finance. We developed stock screening, allowing AI big data to analyze the fundamental condition of publicly listed companies in the short, medium, and long term, ultimately enhancing investors' investment winning rate!",
    ],
    founderSection: [
      "Han Sheng Peng",
      "Co-Founder & CEO",
      "Han Sheng is the Co-Founder and CEO of TradingValley. Passionate about quantitative trading and have been in this field over 12 years, he founded TradingValley in 2014, and dedicated himself to solve problems that general investors face in investing . Han Sheng has a master degree of Mathematics of Finance Program at Columbia University.  His a dreamer and doer. What excites him most is making a great product that can totally change people's life. And he believes that's what he's doing right now. ",
    ],
    departmentSection: [
      "Team",
      "TradingValley Inc. is formed by a group of people with diverse backgrounds that specialize in finance, quant, engineering, design, and marketing. Each team member contributes his and her expertise and together build up Growin investment service. Our quant team in charge of developing intelligent algorithm and investment strategies to provide stable and superior investing performance. In addition, they continue to research and develop new AI technology and put it into investing. Our engineering team in charge of trade and execution system, information security, and monitoring system so that Growin can monitor the stock market movement 24/7  and adjust clients’ positions accordingly. Our design team focuses on delivering great user experience and greatly simplify the complexity of finance and investment; and finally our marketing team presents you with the core idea and mission of Growin, enabling everyone to understand Growin better, such that you can comfortably rely on us.",
    ],
  },
};
