import { Category } from "@/features/ranking/data/category-data";
import { Lang } from "@/i18n-config";

type Metadata = {
  title: Record<Category, (market: string) => string>;
  description: Record<Category, (market: string) => string>;
};

export const ranking: Record<Lang, Metadata> = {
  zh: {
    title: {
      "top-gainers": (market) =>
        `${market}股漲幅排行榜｜今日強勢股前 20 名 - Growin`,
      "top-losers": (market) =>
        `${market}股跌幅排行榜｜今日大跌股票前 20 名 - Growin`,
      yield: (market) =>
        `${market}股殖利率排行榜｜股票股利前 20 名排行 - Growin`,
      "pe-ratio": (market) =>
        `${market}股本益比排行｜低本益比前 20 名股票 - Growin`,
      eps: (market) => `${market}股每股盈餘排行榜｜EPS 前 20 名股票 - Growin`,
      "monthly-revenue-yoy": (market) =>
        `${market}股月營收年增率排行｜月營收年增率前 20 名 - Growin`,
      "hot-search": (market) =>
        `${market}股熱門搜尋排行榜｜熱門搜尋前 20 名 - Growin`,
      "net-flow": (market) =>
        `${market}股資產凈流入排行榜｜籌碼表現前 20 名股票 - Growin`,
    },
    description: {
      "top-gainers": (market) =>
        `Growin 個股探勘提供當日${market}股股票最高漲幅前 20 名排行，以及每一檔股票的 20 日均量、漲幅幅度及 AI 分析，幫助投資人找到強勢潛力股。`,
      "top-losers": (market) =>
        `Growin 個股探勘提供當日${market}股股票最高跌幅前 20 名排行，以及每一檔股票的 20 日均量、跌幅幅度及 AI 分析。`,
      yield: (market) =>
        `幫你整理出${market}股股票現金殖利率前 20 名排行，並提供股票的填權息率、漲跌幅及 AI 給予的股利面評分，讓你精準找出${market}股高股息股票！`,
      "pe-ratio": (market) =>
        `幫你整理出${market}股股票本益比最低的前 20 名排行，以及每一檔股票的股價、股票板塊、每股盈餘跟獨家 AI 價值分析，幫助你快速選出本益比較低且基本面較強的股票。`,
      eps: (market) =>
        `幫你整理出${market}股股票每股盈餘 (EPS) 最高的前 20 名排行，以及每一檔股票的股價、近一季 EPS、EPS 年增率跟獨家 AI 價值分析，幫助你快速選出基本面較強的股票。`,
      "monthly-revenue-yoy": (market) =>
        `幫你整理出${market}股股票中月營收年增率最高的前 20 名股票，包含該公司的月營收、月營收年增率以及獨家 AI 價值評分，幫你選出近一個月營收暴增的強勢股。`,
      "hot-search": (market) =>
        `幫你整理出 Growin 個股探勘中近一週搜尋量最高的前 20 名${market}股標的，幫你篩選出市場中的投資人都在看哪些熱門股票。`,
      "net-flow": (market) =>
        `幫你整理出${market}股股票資產淨流入最高的前 20 名股票，以及每一檔股票的股價、市值及 AI 籌碼面評分，讓你快速找出這一季美股籌碼淨流入表現最好的前十檔標的。`,
    },
  },
  en: {
    title: {
      "top-gainers": (market) =>
        `${market} Stock Market Top Gainers Today - Growin`,
      "top-losers": (market) =>
        `${market} Stock Market Top Losers Today - Growin`,
      yield: (market) =>
        `${market} Stock Market Dividend Yield Rankings - Growin`,
      "pe-ratio": (market) =>
        `${market} Stock Market P/E Ratio Rankings - Growin`,
      eps: (market) => `${market} Stock Market EPS Rankings - Growin`,
      "monthly-revenue-yoy": (market) =>
        `${market} Stock Market Monthly Revenue YOY Rankings - Growin`,
      "hot-search": (market) =>
        `${market} Stocks Weekly Hot Search Rankings - Growin`,
      "net-flow": (market) =>
        `${market} Stock Market Net Flow Rankings - Growin`,
    },
    description: {
      "top-gainers": (market) =>
        `Discover the top gainers stocks in ${market} today! Growin reveals the highest daily stock gains, providing 20-day average volume, price changes, and AI analysis. Find potential winners now!`,
      "top-losers": (market) =>
        `Explore the top losers in ${market}'s stock market today! Growin ranks the top declining stocks, offering 20-day average volume, price changes, and AI analysis.`,
      yield: (market) =>
        `Uncover the top dividend yield stocks in ${market}! Growin lists the highest cash dividend rates, along with ex-dividend yield, price changes, and AI dividend ratings. Identify high-yield stocks accurately!`,
      "pe-ratio": (market) =>
        `Find stocks with the lowest P/E ratios in ${market}! Growin organizes the top ten stocks with lowest P/E ratios, providing stock prices, sector information, EPS, and exclusive AI value analysis.`,
      eps: (market) =>
        `Get insights into ${market} stocks with the highest Earnings Per Share (EPS)! Growin compiles the top ten stocks with the highest EPS, including stock prices, recent quarterly EPS, EPS growth rates, and AI value analysis.`,
      "monthly-revenue-yoy": (market) =>
        `Discover ${market} stocks with the highest monthly revenue YOY! Growin presents the top ten stocks with soaring revenue, including monthly revenue, YoY growth rates, and exclusive AI value ratings. Spot strong performers!`,
      "hot-search": (market) =>
        `Curated list of the hottest ${market} stocks! Growin Stock Explorer reveals the top ten most searched stocks of the week, helping you identify the hot search in the market.`,
      "net-flow": (market) =>
        `Explore ${market} stocks with the highest net flow! Growin organizes the top ten stocks with the highest net flows, providing stock prices, market capitalization, and AI chip performance ratings. Identify strong performers quickly!`,
    },
  },
};
