export const tradingView = {
  zh: {
    title: (symbol: string) => `${symbol} 股價 — ${symbol} 圖表 — TradingView`,
    description: (symbol: string) =>
      `查看即時 ${symbol} 圖表以追踪其股票的價格行為。查找市場預測，${symbol} 財務和市場新聞。`,
  },
  en: {
    title: (symbol: string) =>
      `${symbol} Stock Price - ${symbol} Charts - TradingView`,
    description: (symbol: string) =>
      `View real-time ${symbol} charts to track its stock price behavior. Find market predictions, ${symbol} financials, and market news.`,
  },
};
