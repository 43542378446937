import { category } from "./category";

export const blog = {
  zh: {
    title: "部落格文章",
    readMore: "閱讀更多",
    updatedAt: "最後更新日期：",
    popularArticles: "熱門文章",
    author: "作者：",
    summary: "摘要",
    category: category.zh,
    noData: "找不到任何文章",
    searchPlaceholder: "搜尋文章",
  },
  en: {
    title: "Blog",
    readMore: "Read More",
    updatedAt: "Last Updated: ",
    popularArticles: "Popular Articles",
    author: "author: ",
    summary: "Summary",
    category: category.en,
    noData: "No articles found",
    searchPlaceholder: "Search articles",
  },
};
