import {
  BASIC_ROBO_COUNT,
  FREE_ROBO_COUNT,
} from "@/data/constants/service-limitation";

export const roboSection = {
  zh: {
    activeRobot: "啟用中",
    addNew: "新增設定",
    addNewRobot: "新增推播策略",
    expiration: "偵測結束時間",
    notifyBy: "通知方式",
    fiveDimension: "五力分數",
    techIndicatorDay: "技術指標（日）",
    techIndicatorWeek: "技術指標（週）",
    others: "其他",
    noRobot: "目前沒有啟用中的策略",
    inactiveRobot: "停用中",
    reachLimit: "推播策略數量已達上限！",
    reachLimitAndUpgrade: "推播策略數量已達上限，立即升級方案來新增！",
    activatedSuccess: "成功啟用機器人",
    deactivatedSuccess: "成功停用機器人",
    deletedSuccess: "成功刪除機器人",
    failedToActivate: "啟用機器人失敗，請稍後再試",
    failedToDeactivate: "停用機器人失敗，請稍後再試",
    failedToDelete: "刪除機器人失敗，請稍後再試",
  },
  en: {
    activeRobot: "Active Robot",
    addNew: "Add New",
    addNewRobot: "Add A New Robot",
    expiration: "Expiration",
    notifyBy: "Notify By",
    fiveDimension: "Five Dimension",
    techIndicatorDay: "Technical Indicator (Day)",
    techIndicatorWeek: "Technical Indicator (Week)",
    others: "Others",
    noRobot: "There is no active robot strategy now.",
    inactiveRobot: "Pause",
    reachLimit: `The number of robot target has reached ${BASIC_ROBO_COUNT} sets`,
    reachLimitAndUpgrade: `The number of robot target has reached ${FREE_ROBO_COUNT} sets. Please upgrade for more.`,
    activatedSuccess: "Successfully activated robot",
    deactivatedSuccess: "Successfully deactivated robot",
    deletedSuccess: "Successfully deleted robot",
    failedToActivate: "Failed to activate robot, please try again later",
    failedToDeactivate: "Failed to deactivate robot, please try again later",
    failedToDelete: "Failed to delete robot, please try again later",
  },
};
