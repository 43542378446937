export const factor = {
  zh: {
    title: (symbol: string) => `${symbol} 價值參考因子`,
    description:
      "以下為部分的價值力道因子，價值分數是依據多種財報數據資訊，透過 AI 演算法統合而成。",

    interval: {
      annual: "年報",
      quarter1: "季報 (單季)",
      quarter4: "季報 (近四季)",
    },

    averageMonthlyPrice: "月均價",
    value: "數值",
    revenueYOY: {
      title: "營收年增率",
      description:
        "與前年度同季的營收變化率，由於季節的不同可能影響到營收，所以將同季的營收做比較比較能夠了解當年度營收變化的狀況",
    },
    NCFO: {
      title: "營業現金流對稅後淨利比",
      description:
        "營業現金流 / 稅後淨利，一般認為稅後淨利到最終至少有 8 成實際收到帳上",
    },
    EPS: {
      title: "每股盈餘 (GAAP)",
      description:
        "以企業凈收入（Net Income）除上流通在外股數，可以得知企業的獲利能力",
    },
    PERatio: {
      title: "本益比河流圖",
      description:
        "參考過去一段時間的企業本益比高低點，以不同本益比區間回算股價，查看目前股價是否過高或過低；若股價在本益比河流圖越下方，代表股票越有機會上漲",
    },
    returnRate: {
      title: "ROE, ROA, ROS, ROIC",
      description:
        "分別為不同的報酬率，可以衡量銷貨收入上的收益水準（ROS）、企業在資產上的使用效率（ROA）、為股東創造獲利的效率（ROE）及統合而言，公司投入資本創造盈餘的能力（ROIC），了解企業在不同方面的獲利能力",
    },
    margin: {
      title: "三率圖",
      description:
        "分別為毛利率、營業利益率、淨利率，可以衡量企業在不同方面的獲利能力",
      grossMargin: "毛利率",
      operatingMargin: "營收利益率",
      netMargin: "稅後淨利率",
    },
  },
  en: {
    title: (symbol: string) => `${symbol} Value Reference Factors`,
    description:
      "The Value score is derived from various data and factor information, integrated through AI algorithms. The following factors are part of the evaluation used by AI value scores.",

    interval: {
      annual: "Annually",
      quarter1: "1 Quarter",
      quarter4: "4 Quarter",
    },

    averageMonthlyPrice: "Average Monthly Price",
    value: "Value",
    revenueYOY: {
      title: "Revenue YOY",
      description:
        "Comparing the revenue growth rate with the same quarter of the previous year allows for a better understanding of the annual revenue changes, as seasonal variations could potentially impact revenue.",
    },
    NCFO: {
      title: "Net CFO / Net Income",
      description:
        "The ratio of Net Cash Flow from Operating (Net CFO) divided by Post-tax Net Income is commonly believed to indicate that at least 80% of post-tax net profit is realized as actual cash on hand.",
    },
    EPS: {
      title: "EPS (GAAP)",
      description:
        "Dividing Net Income by the Outstanding Shares reveals a company's profitability.",
    },
    PERatio: {
      title: "PE Ratio River Chart",
      description:
        "By referencing the historical high and low channel of a company's Price-to-Earnings ratio (P/E ratio) over a certain period, you can retroactively calculate stock prices at different P/E ratio ranges. This allows you to determine whether the current stock price is either overvalued or undervalued.  If the stock price is positioned below the P/E ratio waterfall chart, it suggests a higher likelihood of potential price increase.",
    },
    returnRate: {
      title: "ROE, ROA, ROS, ROIC",
      description:
        "Observing various ratios can assist us in understanding the profitability of a company across different aspects: Return on Sales (ROS) representing the profit level on sales, Return on Assets (ROA) indicating the efficiency of asset utilization, Return on Equity (ROE) measuring the efficiency in generating profits for shareholders, and overall, Return on Invested Capital (ROIC) gauging the company's ability to generate earnings from invested capital. This helps us comprehend the company's profitability from diverse angles",
    },
    margin: {
      title: "GPM, OPM, NPM",
      description:
        "Gross Margin, Operating Margin, and Net Margin are metrics that measure a company's profitability from different aspects.",
      grossMargin: "Gross Margin",
      operatingMargin: "Operating Margin",
      netMargin: "Net Margin",
    },
  },
};
