import { Lang } from "@/i18n-config";

export const processingStatus: Record<Lang, Record<string, string>> = {
  zh: {
    "initialization-language-key": "初始化配置",
    "fetching-data-key": "取得資料",
    "calculating-key": "客製化投資組合計算中",
    "fetching-balance-sheet-key": "取得 資產負債表 數據",
    "fetching-income-statement-key": "取得 損益表 數據",
    "fetching-cash-flow-statement-key": "取得 現金流量表 數據",
    "fetching-growin-insights-key": "取得 Growin 洞察",
    "fetching-technical-analysis-key": "取得 技術分析",
    "fetching-13f-data-key": "取得 13F 數據",
    "fetching-earnings-transcript-key": "取得 逐字稿重點",
    "fetching-financial-ratios-key": "取得 財務比率",
    "fetching-financial-reports-key": "取得 財務、營運資訊",
  },
  en: {
    "initialization-language-key": "Initializing Configuration",
    "fetching-data-key": "Fetching Data",
    "calculating-key": "Customized Portfolio Calculation",
    "fetching-balance-sheet-key": "Fetching Balance Sheet Data",
    "fetching-income-statement-key": "Fetching Income Statement Data",
    "fetching-cash-flow-statement-key": "Fetching Cash Flow Statement Data",
    "fetching-growin-insights-key": "Fetching Growin Insights Data",
    "fetching-technical-analysis-key": "Fetching Technical Analysis Data",
    "fetching-13f-data-key": "Fetching 13F Data",
    "fetching-earnings-transcript-key":
      "Fetching Earnings Transcripts Highlights Data",
    "fetching-financial-ratios-key": "Fetching financial Ratios",
    "fetching-financial-reports-key":
      "Fetching Financial & Operational Information",
  },
};
