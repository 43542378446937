export const error = {
  zh: {
    somethingWentWrong: "發生錯誤",
    serverError: "系統發生一些問題... 請再試一次或聯繫 Growin 團隊",
    noData: "沒有相關的資料記錄",
    pageNotFound: "找不到頁面",
    pageNotFoundMessage: "很抱歉，我們找不到您要找的頁面。",
    goBackHome: "回到首頁",
    contactSupport: "聯絡我們",
    retry: "再試一次",
    networkError:
      "發生網路錯誤。請檢查您的連線，重新載入頁面以查看回覆是否已生成，或再次嘗試發問。如果問題持續發生，請聯繫 Growin 團隊。",
  },
  en: {
    somethingWentWrong: "Something went wrong",
    serverError:
      "Something went wrong with the system... Please try again or contact the Growin team",
    noData: "No related data records",
    pageNotFound: "Page not found",
    pageNotFoundMessage: "Sorry, we couldn’t find the page you’re looking for.",
    goBackHome: "Go back home",
    contactSupport: "Contact support",
    retry: "Retry",
    networkError:
      "A network error occurred. Please check your connection, reload the page to see if the reply is generated, or try again. If this issue persists, please contact the Growin team.",
  },
};
