export const help = {
  zh: {
    needHelp: "我們要如何幫助你？",
    needAssist: "需要協助？",
    helpCenter: "幫助中心",
    search: "快速搜尋",
    contactUs: "聯絡我們",
  },
  en: {
    needHelp: "How Can We Help You?",
    needAssist: "Still Need Help?",
    helpCenter: "Help Center",
    search: "search",
    contactUs: "Contact Us",
  },
};
