export const dateOption = {
  zh: {
    all: "全部",
    ytd: "今年迄今",
    singleSeason: "單季",
    fourSeasons: "近四季",
    quarterlyReport: "季報",
    annualReport: "年報",
    fiveYears: "五年",
    tenYears: "十年",
    fifteenYears: "十五年",
    custom: "自訂",
    pastDay: "近一日",
    pastMonth: "近一月",
    pastQuarter: "近一季",
    pastHalfYear: "近半年",
    pastYear: "近一年",
  },
  en: {
    all: "All",
    ytd: "YTD",
    singleSeason: "1 Quarter",
    fourSeasons: "4 Quarters",
    quarterlyReport: "Quarterly",
    annualReport: "Annually",
    fiveYears: "5 Y",
    tenYears: "10 Y",
    fifteenYears: "15 Y",
    custom: "Custom",
    pastDay: "1D",
    pastMonth: "1M",
    pastQuarter: "3M",
    pastHalfYear: "6M",
    pastYear: "1Y",
  },
};
