export const shareholderDiversification = {
  zh: {
    title: "股權分散",
    largeThreshold: "大戶",
    smallThreshold: "散戶",
    tradingStatus: "大戶散戶買賣超狀態",
    largeRetailOverbought: "大戶買超",
    retailInvestorOverbought: "散戶買超",
    weeks: "週",
    largeThresholdShares: "大戶持股",
    largeThresholdShareChanges: "大戶持股增減",
    date: "日期",
    noChange: "不變",
    closePrice: "股價",
    largeThresholdRatio: "大戶持股比例",
    smallThresholdRatio: "散戶持股比例",
  },
  en: {
    title: "Holdings of Investors",
    largeThreshold: "Large Retail Investor",
    smallThreshold: "Retail Investor",
    tradingStatus: "Net Buying of Investors",
    largeRetailOverbought: "Large Retail: ",
    retailInvestorOverbought: "Retail Investor: ",
    weeks: "Weeks",
    largeThresholdShares: "Holding of Large Retail Investor",
    largeThresholdShareChanges: "Holding Chg. of Large Retail Investor",
    date: "Date",
    noChange: "No Change",
    closePrice: "Price",
    largeThresholdRatio: "Holding Ratio of Large Retail",
    smallThresholdRatio: "Holding Ratio of Retail Investor",
  },
};
