export const navbar = {
  zh: {
    watchList: "查看清單",
    search: "股票搜尋",
    stockMining: "個股探勘",
    stockMiningPricing: "付費方案",
    roboAdvisor: "智能理財",
    technology: "技術說明",
    help: "幫助中心",
    product: "產品",
    activeInvestment: "主動投資",
    passiveInvestment: "被動投資",
    companyIntro: "公司介紹",
    contactUs: "聯絡我們",
    login: "登入",
    logout: "登出",
    userMenu: "會員專區",
    getStarted: "立即使用",
    myScreener: "個股篩選",
    my13F: "13F 專區",
    myNotification: "台美股推播通知",
    marketAnalysis: "市場趨勢",
    analysis: "AI 個股分析",
    screener: "股票篩選器",
    pricing: "訂閱方案",
    upgrade: "升級",
    marketAnalysisFearGreed: "CNN 恐懼與貪婪指數",
    marketAnalysisWarningIndicator: "市場預警燈號",
    telegram: "Telegram 美股速報",
    discord: "Discord 投資交流社群",
    userSetting: "個人設定",
    loginRegister: "登入 / 註冊",
    language: "語言",
    searchPlaceholder: "股票代碼、公司名稱",
    notLoggedIn: "未登入",
    aiStockSelection: "AI 選股",
    twStockRanking: "台股排行榜",
    usStockRanking: "美股排行榜",
    usage: "今日點擊",
    times: "次",
    myMediaCenter: "金融影音",
    myWinwinGpt: "WinwinGPT",
    comingSoon: "即將上線",
    line: "Line 即時客服",
    blog: "部落格",
    knowledgeBase: "知識專區",
    assetManager: "基金經理人",
  },
  en: {
    watchList: "Watchlist",
    search: "Symbol, Company",
    stockMining: "Stock Mining",
    stockMiningPricing: "Pricing",
    roboAdvisor: "Robo Advisor",
    technology: "Technology",
    help: "Help",
    product: "Product",
    activeInvestment: "Active Investment",
    passiveInvestment: "Passive Investment",
    companyIntro: "About Us",
    contactUs: "Contact Us",
    login: "Login",
    logout: "Logout",
    userMenu: "User Menu",
    getStarted: "Get Started",
    myScreener: "Screener",
    my13F: "13F",
    myNotification: "US/TW Notification",
    marketAnalysis: "Market Indicator",
    analysis: "AI Analysis",
    screener: "Stock Screener",
    pricing: "Pricing",
    upgrade: "Upgrade",
    marketAnalysisFearGreed: "Fear & Greed Index",
    marketAnalysisWarningIndicator: "Market Warning Indicator",
    telegram: "Telegram",
    discord: "Discord Investment Discussion Community",
    userSetting: "User Setting",
    loginRegister: "Login / Register",
    language: "Language",
    searchPlaceholder: "Symbol, Company",
    notLoggedIn: "Not Logged In",
    aiStockSelection: "AI Stock Selection",
    twStockRanking: "TW Stock Ranking",
    usStockRanking: "US Stock Ranking",
    usage: "times of your Free Plan today",
    times: "times",
    myMediaCenter: "Media Center",
    myWinwinGpt: "WinwinGPT",
    comingSoon: "Coming Soon",
    line: "Line Chat",
    blog: "Blog",
    knowledgeBase: "Knowledge Base",
    assetManager: "Asset Manager",
  },
};
