export const swing = {
  zh: {
    forceInfo: [
      "股市有約 80% 的時間都在盤整累積量能，剩下 20% 的時間，累積的量能釋放帶動股價大漲或大跌的走勢，決定了股價的長期方向。當股票發生大漲並且持續了一段時間，我們稱為上漲的波段;反之，我們稱為下跌的波段。而波段交易的投資人總是期望能在波段開始前或是波段早期時入場，來參與股價整段後續的行情。",

      "Growin 的波段多頭力道分數就是要協助投資人找出這樣的機會，我們利用股價、價量資訊萃取出重要因子，並透過 AI 演算法進行學習與分析，找出股價未來出現波段起漲的可能性。波段交易的特色是在多次的交易中，只要其中一次抓住大的上漲行情來獲得高報酬，就可以抵銷較多次的小虧損。也是因為這樣的特性，波段投資人並非追求高勝率，而是專注在交易風險上的管理，達到大賺少賠的目標。Growin 的多頭波段力道評分，分數越高代表演算法判斷個股更有機會形成向上漲的多頭波段。",

      "請注意：此波段力道的評分僅為輔助使用，投資人應根據自己的投資風險，建立適合自己的進場、出場、停損的策略來進行投資。",
    ],
  },
  en: {
    forceInfo: [
      "Around 80% of the time, the stock market is in a phase of consolidation to accumulate energy. The remaining 20% of the time, the accumulated energy is released, driving significant upward or downward price movements that determine the stock's long-term direction. When a stock experiences a sustained period of substantial price increase, it's referred to as an upward swing; conversely, a sustained decrease is called a downtrend swing. Investors engaged in swing trading always seek to enter before or early in these swing phases to participate in the subsequent price movement.",

      "Growin's Swing Trading Score is designed to assist investors in identifying such opportunities. We extract essential factors from price and volume information and employ AI algorithms for learning and analysis to predict the likelihood of a stock entering an upward swing phase in the future. The essence of swing trading lies in capturing one major upward movement among several trades, thus offsetting smaller losses. Due to this characteristic, swing trading investors don't necessarily aim for a high success rate; instead, they focus on risk management, aiming for significant gains and minimal losses. A higher score in Growin's Swing Trading Score indicates a higher probability of a stock forming an upward swing.",

      "Note: This Swing Trading Score provided is for supplementary use only. Investors should establish their own strategies for entry, exit, and stop-loss levels based on their individual risk tolerance when making investment decisions.",
    ],
  },
};
