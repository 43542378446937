export const tradingViewIndex = {
  zh: {
    title: "領取 Growin 獨家指標！",
    popperTitle: "為什麼要提供 TradingView 帳號？",
    popperContent:
      "因為此兩項獨家指標在 TradingView 平台上為邀請制才可取用，需授權給您的 TradingView 帳號，才能開放給您。一旦權限開通，即可透過 TradingView 介面的加入『技術指標』→ 『僅限邀請腳本』，看到此兩項指標囉！",
    description:
      "以上兩項 Growin 獨家指標均有上架在 TradingView 平台上，告訴我們您的 TradingView 帳號，便可取得兩項指標的使用權限喔！",
    free: "免費方案無法使用",
    account: "TradingView 帳號",
    successMsg: "我們已成功送出您的 TradingView 帳號",
    errorMsg: "送出時發生了一些錯誤，請稍後再試",
    upgrade: "升級方案",
    submit: "送出",
    edit: "修改",
  },
  en: {
    title: "Get Growin's Proprietary Indicators!",
    popperTitle: "Why provide a TradingView account?",
    popperContent:
      'To access the proprietary indicators developed by Growin and available on the TradingView platform, we require your TradingView account for authorization. After permissions are granted, you can find these indicators by navigating to "Technical Indicators" → "Invite-Only Scripts" within the TradingView interface.',
    description:
      "The above two proprietary indicators are available on the TradingView. Provide your TradingView username and get access to both indicators!",
    free: "Free plan cannot use",
    account: "TradingView Username",
    successMsg: "We have successfully sent your TradingView username",
    errorMsg: "Something went wrong when sending, please try again later",
    upgrade: "Upgrade plan",
    submit: "Send",
    edit: "Edit",
  },
};
