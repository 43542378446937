export const sessions = {
  zh: {
    getQuota: "立即取得問題額度！",
    askQuestion:
      "哈囉！我是 Winwin！將陪您一起在投資道路上成長的小夥伴！可以問我股票相關問題或先從下列問題著手喔！",
    reachedFreeQuota: ({ quota }: { quota: number }) =>
      `嗨！您的帳號額度 ${quota} 次已經用完囉！ >0<<br/><br/>您可以購買我們的入門 / 基本 / 進階方案立即取得更多問題的額度喔！`,
    reachedBeginningQuota: ({ quota }: { quota: number }) =>
      `嗨！您的帳號額度 ${quota} 次已經用完囉！ >0<<br/><br/>您可以購買我們的基本 / 進階方案立即取得更多問題的額度喔！`,
    reachedBasicQuota: ({ quota }: { quota: number }) =>
      `嗨！您的帳號額度 ${quota} 次已經用完囉 >0<<br/><br/>您可以購買我們的進階方案立即取得更多問題的額度喔！`,
    reachedPremiumQuota: ({ quota }: { quota: number }) =>
      `嗨！您的帳號額度 ${quota} 次已經用完囉 >0<<br/><br/>需等待至下週有新額度才可繼續使用～`,
  },
  en: {
    getQuota: "Get More Quota Now!",
    askQuestion:
      "Hi there! I'm Winwin, your friendly investment companion. I'm here to help you grow your wealth and navigate the world of stocks. Feel free to ask me any questions, or start with the following topics:",
    reachedFreeQuota: ({ quota }: { quota: number }) =>
      `Hi! You've used up your ${quota} free question credits! >0<<br/><br/>You can purchase our Beginning, Basic or Advanced plan to get more question credits instantly!`,
    reachedBeginningQuota: ({ quota }: { quota: number }) =>
      `Hi! You've used up your ${quota} question credits! >0<<br/><br/>You can purchase our Basic or Advanced plan to get more question credits instantly!`,
    reachedBasicQuota: ({ quota }: { quota: number }) =>
      `Hi! You've used up your ${quota} question credits! >0<<br/><br/>You can purchase our Advanced plan to get more question credits instantly!`,
    reachedPremiumQuota: ({ quota }: { quota: number }) =>
      `Hi! You have reached the limit of ${quota} questions this week! >0<<br><br> You need to wait for new quota next week to continue using~`,
  },
};
