import { business } from "./business";
import { login } from "./login";
import { multiDevices } from "./multi-devices";
import { signup } from "./signup";
import { support } from "./support";

export const auth = {
  zh: {
    normalAccount: "一般會員",
    businessAccount: "企業單位",
    login: login.zh,
    signup: signup.zh,
    business: business.zh,
    support: support.zh,
    multiDevices: multiDevices.zh,
  },
  en: {
    normalAccount: "Member",
    businessAccount: "Business",
    login: login.en,
    signup: signup.en,
    business: business.en,
    support: support.en,
    multiDevices: multiDevices.en,
  },
};
