export const history = {
  zh: {
    dividend: "股利配發及殖利率",
    EPS: "EPS 及盈餘分配率",
    fillingDays: (value: number) => `花費 ${value} 天填息 `,
    stockDividend: (value: number) => ` (含股票股利 $${value})`,
    payoutRatio: "盈餘分配",
  },
  en: {
    dividend: "Dividend Distribution and Dividend Yield",
    EPS: "EPS and Earnings Distribution Ratio",
    fillingDays: (value: number) => `Filling Days: ${value}`,
    stockDividend: (value: number) => ` (Incl. Stock Dividend $${value})`,
    payoutRatio: "Payout Ratio",
  },
};
