export const scoreDescription = {
  zh: {
    value: {
      1: "成長性差",
      2: "成長性有限",
      3: "成長性一般",
      4: "成長性佳",
      5: "成長性優",
    },
    trend: { 1: "強勁空頭", 2: "空頭", 3: "中立", 4: "多頭", 5: "強勁多頭" },
    swing: {
      1: "行情疲弱",
      2: "行情趨弱",
      3: "走勢持平",
      4: "強烈看漲",
      5: "爆發性上揚",
    },
    chip: {
      1: "乏人問津",
      2: "稍有關注",
      3: "穩定持有",
      4: "增加配置",
      5: "積極佈局",
    },
    dividend: {
      1: "無配息",
      2: "不宜存股",
      3: "股利不穩定",
      4: "宜存股",
      5: "適合存股",
    },
  },
  en: {
    value: {
      1: "Poor Growth",
      2: "Limited Growth",
      3: "Average Growth",
      4: "Good Growth",
      5: "Excellent Growth",
    },
    trend: {
      1: "Strong Bearish",
      2: "Bearish",
      3: "Consolidation",
      4: "Bullish",
      5: "Strong Bullish",
    },
    swing: {
      1: "Languishing Action",
      2: "Weakening Action",
      3: "Stable Action",
      4: "Strong Bullish",
      5: "Explosive Bullish",
    },
    chip: {
      1: "Neglected",
      2: "Slight Interest",
      3: "Stable Holding",
      4: "Increased Allocation",
      5: "Aggressive Positioning",
    },
    dividend: {
      1: "No Dividend",
      2: "Not for Holding",
      3: "Unstable Dividend",
      4: "Suitable for Holding",
      5: "Ideal for Holding",
    },
  },
};
