export const category = {
  zh: {
    "growin-stock-exploration": "Growin 個股探勘",
    "market-reports": "市場報告",
    "investment-knowledge": "投資知識",
    "user-guide": "使用攻略",
    "13f-report": "13F 報告",
    "market-analysis": "市場關鍵數據",
    "weekly-market-analysis": "每週市場分析",
    "us-stock-after-hours": "美股盤後",
    "industry-trends": "產業趨勢",
    "earnings-report": "財報分析",
    "stock-analysis": "個股關鍵數據",
    "overall-market": "總體市場",
    "investment-strategy": "投資策略",
    "investment-psychology": "投資心理",
    "quantitative-analysis": "量化分析",
  },
  en: {
    "growin-stock-exploration": "Growin Stock Exploration",
    "market-reports": "Market Reports",
    "investment-knowledge": "Investment Knowledge",
    "user-guide": "User Guide",
    "13f-report": "13F Report",
    "market-analysis": "Market Analysis",
    "weekly-market-analysis": "Weekly Market Analysis",
    "us-stock-after-hours": "US Stock After Hours",
    "industry-trends": "Industry Trends",
    "earnings-report": "Earnings Report",
    "stock-analysis": "Stock Analysis",
    "overall-market": "Overall Market",
    "investment-strategy": "Investment Strategy",
    "investment-psychology": "Investment Psychology",
    "quantitative-analysis": "Quantitative Analysis",
  },
};
