import { ColumnKey } from "@/features/ranking/services/get-ranking-list";
import { Lang } from "@/i18n-config";

export const columnKey: Record<Lang, Record<ColumnKey, string>> = {
  zh: {
    peRatio: "本益比",
    yield: "近 5 年平均殖利率",
    monthlyRevenueYoy: "月營收年增率",
    monthlyRevenue: "月營收",
    rank: "排名",
    symbol: "股票代碼",
    companyName: "公司名稱",
    mainCategory: "股票板塊",
    price: "股價",
    oneDayReturn: "1 日漲跌幅",
    volume20MA: "20 日均量",
    value: "價值分數",
    trend: "趨勢分數",
    swing: "波段分數",
    chip: "籌碼分數",
    dividend: "股利分數",
    filledRatio: "填權息率",
    eps: "每股盈餘",
    epsYoy: "每股盈餘年增率",
    marketCap: "市值",
    netFlow: "資產淨流入",
  },
  en: {
    peRatio: "PE Ratio",
    yield: "5Y Avg.Yield",
    monthlyRevenueYoy: "Monthly Revenue YOY",
    monthlyRevenue: "Monthly Revenue",
    rank: "Ranking",
    symbol: "Symbol",
    companyName: "Company Name",
    mainCategory: "Sector",
    price: "Price",
    oneDayReturn: "1D Price Change",
    volume20MA: "20D Avg Volume",
    value: "Value",
    trend: "Trend",
    swing: "Swing Trading",
    chip: "Whale Interest",
    dividend: "Dividend",
    filledRatio: "Avg. Filling Rate",
    eps: "EPS",
    epsYoy: "EPS YOY",
    marketCap: "Market Cap",
    netFlow: "Capital Net Inflow",
  },
};
