export const about = {
  zh: {
    title: "公司介紹 | About TradingValley - Growin",
    description:
      "TradingValley Inc. 是一間 SEC 註冊的線上投資顧問，我們將數十年的量化投資經驗結合 AI 演算法，打造 Growin 機器人理財 APP；運用理財機器人，規劃智能投資計畫，聰明投資理財！",
  },
  en: {
    title: "About Us - Growin",
    description:
      "TradingValley Inc. is an SEC-registered online investment advisor. We combine decades of quantitative investment experience with AI algorithms to create the Growin Robo Advisor APP; use the Robo Advisor to plan smart investment plans and smart investment management!",
  },
};
