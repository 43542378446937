export const multiDevices = {
  zh: {
    title: "偵測到新設備登入",
    description:
      "我們已偵測到您在另一個設備上登入。請確認是否維持在本設備上登入。如果新的設備不是您本人使用，請與我們聯繫。",
    latestDevice: "最新設備詳情",
    ip: "IP 地址",
    device: "設備",
    browser: "瀏覽器",
    allowConnection:
      "點擊下方按鈕允許本設備連接您的帳戶。這將防止其他設備存取您的帳戶。",
    confirmConnection: "確認本設備連接",
    updateConnectionFailed: "更新本設備連接失敗",
    logout: "登出",
    contact: "我們聯繫",
  },
  en: {
    title: "New Device Login Detected",
    description:
      "We've detected a login attempt from a new device. Please contact us if this was not you.",
    latestDevice: "Latest Device Details",
    ip: "IP Address",
    device: "Device",
    browser: "Browser",
    confirmConnection: "Connect",
    allowConnection:
      "Click 'Connect' button to allow this device to connect to your account. This will prevent other devices from accessing your account.",
    updateConnectionFailed: "Failed to update device connection",
    logout: "Logout",
    contact: "contact us",
  },
};
