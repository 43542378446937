export const historicalSignal = {
  zh: {
    historicalSignal: "歷史燈號",
    historyDetails: "詳細歷史數據",
    date: "日期",
    sp500Index: "S&P 500 指數",
    updateTo: "資料更新至",
    signals: {
      warning: "警訊",
      notice: "注意",
      stable: "穩定",
    },
    tableCols: {
      startDate: "發生日期",
      endDate: "結束日期",
      signal: "燈號類型",
      duration: "期間天數",
      return: "期間報酬",
      maxDrawdown: "期間最大跌幅",
      event: "對應事件",
    },
  },
  en: {
    historicalSignal: "Historical Signal",
    historyDetails: "History Details",
    date: "Date",
    sp500Index: "S&P 500 Index",
    updateTo: "Update to",
    signals: {
      warning: "Warning",
      notice: "Notice",
      stable: "Stable",
    },
    tableCols: {
      startDate: "Start Date",
      endDate: "End Date",
      signal: "Signal",
      duration: "Duration",
      return: "Return",
      maxDrawdown: "Max. Drawdown",
      event: "Event",
    },
  },
};
