import { chart } from "./chart";
import { error } from "./error";
import { event } from "./event";
import { illustrationDialog } from "./illustration-dialog";
import { investStyle } from "./invest-style";
import { investStyleDialog } from "./invest-style-dialog";
import { processingStatus } from "./processing-status";
import { questionType } from "./question-type";
import { sessions } from "./sessions";

export const winwingpt = {
  zh: {
    event: event.zh,
    investStyle: investStyle.zh,
    illustrationDialog: illustrationDialog.zh,
    investStyleDialog: investStyleDialog.zh,
    questionType: questionType.zh,
    sessions: sessions.zh,
    error: error.zh,
    processingStatus: processingStatus.zh,
    chart: chart.zh,

    inquiryRecord: "詢問紀錄",
    noQuestion: "您尚未跟 WinwinGPT 詢問過問題！",
    freeQuota: ({ counts, available }: { counts: number; available: number }) =>
      `(本帳號已詢問：${counts}/${available})`,
    quota: ({ counts, available }: { counts: number; available: number }) =>
      `( 2 週內已詢問：${counts}/${available})`,
    deleteRecord: "刪除此紀錄",
    withStyle: "帶入投資風格的回答：",
    inputQuestion: "請輸入問題",
    riskDisclosure: "WinwinGPT 可能提供不準確資訊，請核對答覆。",
    disclaimer: "免責聲明",
    like: "讚！喜歡",
    dislike: "我不喜歡",
    close: "關閉",
    processingDesc:
      "Winwin 正在搜集資訊並解析整理您的答案，這需要一些時間，請稍候！",
    processing: " 處理中",
    done: " 完成",
    backToMain: "回預設問題頁",
    streaming: "WinwinGPT 正在努力回答您的問題中...",
  },
  en: {
    event: event.en,
    investStyle: investStyle.en,
    illustrationDialog: illustrationDialog.en,
    investStyleDialog: investStyleDialog.en,
    questionType: questionType.en,
    sessions: sessions.en,
    error: error.en,
    processingStatus: processingStatus.en,
    chart: chart.en,

    inquiryRecord: "Records",
    noQuestion: "You haven't asked any questions yet!",
    freeQuota: ({ counts, available }: { counts: number; available: number }) =>
      `(Queries of this account: ${counts}/${available})`,
    quota: ({ counts, available }: { counts: number; available: number }) =>
      `(Queries of this 2 weeks: ${counts}/${available})`,
    deleteRecord: "Delete",
    withStyle: "With investment type:",
    inputQuestion: "Start from here!",
    riskDisclosure: "WinwinGPT may be inaccurate, please check the content.",
    disclaimer: "Disclaimer",
    like: "Like",
    dislike: "Dislike",
    close: "Close",
    processingDesc:
      "Winwin is collecting information and analyzing your answers, this will take some time, please wait!",
    processing: "",
    done: " Done",
    backToMain: "Back to Default QA Page",
    streaming: "WinwinGPT is answering your question...",
  },
};
