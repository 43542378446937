export const notification = {
  zh: {
    title: {
      home: "推播策略 - Growin",
      settings: (isEdit: boolean) =>
        `推播策略 | ${isEdit ? "編輯" : "新增"}策略 - Growin`,
      logs: "推播策略 | 觸發日誌 - Growin",
      line: "推播策略 | LINE 綁定 - Growin",
    },
    description: {
      home: "Growin 推播通知替您即時監控美股最新動向，透過 AI 策略搭配市值規模、交易均量、五力分數等量化指標自行設定條件，建立專屬於您的推播策略掌握黃金進出場時機！",
    },
  },
  en: {
    title: {
      home: "Notification Bot | Promptly notify any fluctuation of the scores - Growin",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      settings: (_isEdit: boolean) => `Notification Bot | Settings - Growin`,
      logs: "Notification Bot | Trigger Logs - Growin",
      line: "Notification Bot | Line - Growin",
    },
    description: {
      home: "With the help of Growin's Notification Bot, you can now promptly receive updates on any fluctuations in the Growin Five-Dimensional scores of the stocks you are tracking, as well as any emerging trends or momentum shifts!",
    },
  },
};
