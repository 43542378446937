export const tradingview = {
  zh: {
    title: "合作夥伴",
    description: `Growin 使用 TradingView 技術來顯示交易數據圖表。TradingView 是一個受交易者和投資者歡迎的圖表平台。
    Growin 利用其先進的圖表功能和股票篩選工具，為用戶提升交易體驗。`,
    disclaimer: "本圖表功能由 TradingView 提供",
  },
  en: {
    title: "Partnership",
    description:
      "Growin uses TradingView technology to display trading data on charts. TradingView is a popular charting platform among traders and investors. Growin leverages its advanced charting capabilities and stock screener widgets to enhance the trading experience for users.",
    disclaimer: "This charting function is provided by TradingView",
  },
};
