export const questionType = {
  zh: {
    default: "預設問題",
    nonDefault: "個人提問",
    error: "未完整回覆",
  },
  en: {
    default: "Default",
    nonDefault: "Personal",
    error: "Incomplete",
  },
};
