export const fundingLevelSimulation = {
  zh: {
    title: "模擬功能正在建置中...",
    desc: "此功能可讓您測試在不同的景氣狀況下，資金水位如何分布，可以得到更好的投資報酬，敬請期待！",
  },
  en: {
    title: "This function is in progress...",
    desc: "You could simulate how the distribution of funds should be to get better investment returns under various economic conditions. Stay tuned!",
  },
};
