import { investor } from "./investor";
import { overview } from "./overview";

export const my13F = {
  zh: {
    ranking: "排名",
    symbol: "股票代號",
    company: "公司名稱",
    sector: "股票版塊",
    updatedAt: ({ date }: { date: string }) => `資料更新至 ${date}`,
    overview: overview.zh,
    investor: investor.zh,
  },
  en: {
    ranking: "Ranking",
    symbol: "Symbol",
    company: "Company Name",
    sector: "Sector",
    updatedAt: ({ date }: { date: string }) => `Update to ${date}`,
    overview: overview.en,
    investor: investor.en,
  },
};
