import { about } from "./about";
import { contactUs } from "./contact-us";
import { event } from "./event";
import { help } from "./help";
import { home as homePage } from "./home";
import { roboAdvisor } from "./robo-advisor";
import { stockMining } from "./stock-mining";
import { stockMiningPricing } from "./stock-mining-pricing";
import { technology } from "./technology";

export const home = {
  zh: {
    home: homePage.zh,
    stockMining: stockMining.zh,
    stockMiningPricing: stockMiningPricing.zh,
    roboAdvisor: roboAdvisor.zh,
    technology: technology.zh,
    help: help.zh,
    about: about.zh,
    contactUs: contactUs.zh,
    event: event.zh,
  },
  en: {
    home: homePage.en,
    stockMining: stockMining.en,
    stockMiningPricing: stockMiningPricing.en,
    roboAdvisor: roboAdvisor.en,
    technology: technology.en,
    help: help.en,
    about: about.en,
    contactUs: contactUs.en,
    event: event.en,
  },
};
