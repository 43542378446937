export const guidedCard = {
  zh: {
    addNewRobot: "新增設定",
    customRobot: "自訂",
    valueRobot: "價值機器人",
    swingRobot: "波段機器人",
    trendRobot: "趨勢機器人",
    customRobotDescription:
      "您可以依據市值規模、交易均量、五力分數... 等，自行設定條件，建立專屬於自己的推播策略！",
    valueRobotDescription:
      "此機器人擅長價值選股，挖掘財務穩健、具備成長潛力的股票。若喜歡巴菲特等價值投資大師，不仿試試！可協助您更省時、省力地挖掘便宜價值股！",
    swingRobotDescription:
      "此機器人擅長找尋相對低點進場，同時選的股票兼具好的價值與高度籌碼累積。若喜歡擇時交易，它將讓你在股價波動大的交易環境中如魚得水。",
    trendRobotDescription:
      "此機器人擅長趨勢與動能選股，所選的股票有一定的基本面支撐。若喜愛順勢交易，它會是好幫手！它將為你第一時間找到趨勢正在發生的動能股！",
  },
  en: {
    addNewRobot: "Add A New Robot",
    customRobot: "Customization",
    valueRobot: "Value Investing",
    swingRobot: "Swing Trading",
    trendRobot: "Trend Following",
    customRobotDescription:
      "Customize your own notification strategy based on market cap, average trading volume, 5 dimension scores, etc.",
    valueRobotDescription:
      "This robot excels at identifying stock value and focuses on finding financially stable, consistently profitable, and growth-potential stocks.",
    swingRobotDescription:
      "This robot excels at identifying opportune moments for buying low and selling high, while selecting stocks with both good value and strong accumulation of market interest.",
    trendRobotDescription:
      "This robot excels at identifying stocks with strong momentum and solid fundamentals.",
  },
};
