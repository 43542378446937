import { fearGreed } from "./fear-greed";
import { warningIndicator } from "./warning-indicator";

export const marketAnalysis = {
  zh: {
    fearGreed: fearGreed.zh,
    warningIndicator: warningIndicator.zh,
  },
  en: {
    fearGreed: fearGreed.en,
    warningIndicator: warningIndicator.en,
  },
};
