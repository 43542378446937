import { login } from "./login";
import { subscribe } from "./subscribe";

export const blockDialog = {
  zh: {
    login: login.zh,
    subscribe: subscribe.zh,
  },
  en: {
    login: login.en,
    subscribe: subscribe.en,
  },
};
