export const fearGreed = {
  zh: {
    title: "CNN 恐懼與貪婪指數查詢 | 市場情緒 | 美股市場 - Growin",
    description:
      "掌握市場情緒變化！即時更新的 CNN 恐懼與貪婪指數（Fear & Greed Index），幫助投資人分析美股市場氛圍，判斷風險與機會，Growin 提供最新市場數據，立即查詢！",
  },
  en: {
    title: "CNN Fear and Greed Index | Market Sentiment - Growin",
    description:
      "Stay updated with the CNN Fear and Greed Index! Analyze market sentiment and identify risks and opportunities in the US market with Growin's latest market data.",
  },
};
