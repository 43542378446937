export const stockMiningPricing = {
  zh: {
    title: "個股探勘訂閱方案 - Growin",
    description:
      "Growin 個股探勘訂閱方案，功能包括：個股五力分析、個股篩選器、Growin 獨家量化指標、財經課程。提供基本付費方案，計費方式可採每月/每年，詳細內容供您參考。",
  },
  en: {
    title: "Pricing of Stock Mining - Growin",
    description:
      "Growin's Basic Plan elevates your investment strategy! Our exclusive AI-powered Five-Dimension Analysis, Stock Screener, and 13F Reports quickly consolidate fragmented stock information and indicators.",
  },
};
