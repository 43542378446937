export const technology = {
  zh: {
    bannerSection: [
      "以人工智慧演算法",
      "打造頂尖的理財服務",
      "加快您實現目標的腳步",
    ],
    introductionSection: [
      "結合財經、資訊及工程不同領域的專業知識",
      "我們運用機器學習與計量演算法，提供全自動化的財富管理體驗。",
      "建構於人工智慧與量化模型",
      "Growin 運用多重人工智慧演算法來建構出能有效分散風險於指數型基金 (ETFs) 與股票的投資組合。無論您是投資新手或交易老手，我們都能提供最適合您的自動化投資策略。",
    ],
    globalSection: [
      "多樣化的全球投資標的",
      "Growin 的演算法每日分析上萬檔 ETFs 與股票，並挑選出最適合的資產標的加入投資組合中",
    ],
    methodsSection: [
      "我們有許多方法累積您的財富，追著市場跑絕對不是其中之一",
      "我們的幾項特點將為您的長期投資計畫鋪路，持續地累積您的財富",

      "監控市場脈動",
      "我們時時刻刻保護著您的財富，每日緊盯著國際經濟情勢，並以機器學習演算法分析巨量財務數據進行預測，即時調整投資組合配置",

      "控管投資風險",
      "我們針對風險屬性與財務目標，為您客製化最佳的投資組合。您將不需為了市場波動與風險焦慮。您將可得到在預期風險下應得的報酬",

      "自動調整投資細節",
      "根據市場情況自動為您調整適合的資產比例以及投資標的，您不需要做任何投資決策，卻可以隨時掌握投資績效與狀況",
    ],
    learningSection: [
      "強大的機器學習",
      "我們運用不同的機器學習預測器當作我們的市場研究員，它們日以繼夜地讀取與分析市場數據，預測未來中期以及長期市場走勢，以協助動態配置您的投資部位",

      "您的投資將跨及全世界",
      "我們為您設計的投資組合會動態地將您的資產配置於全球各個市場，致力於抓住各市場機會，創造持續性的獲利以及降低投資風險",
    ],
    strategySection: [
      "我們運用科技提供多樣風格的投資策略",
      "投資市場千變萬化，我們明白只使用單一投資方法或是策略是絕對不夠的！",
      "Growin 量化團隊持續打造多元化的策略，提供您更多的選擇。",

      "資產配置策略",
      "我們的資產配置策略運用機器學習的預測技術，結合諾貝爾等級的投資組合理論，自動化進行資產的挑選及配置調整，提供低波動、穩健成長的投資成效。",

      "價值投資",
      "我們的價值投資策略運用機器學習技術，以各公司的財務數據以及量價資訊供演算法自行學習，每月挑選優質的標的組成投資組合，創造打敗大盤的績效表現。",
    ],
    endingSection: [
      "Growin 的理財機器人時時刻刻為您做最好的投資決策，",
      "就為了給您多一點時間享受生活",
    ],
  },
  en: {
    bannerSection: [
      "We Deliver Supreme Service ",
      "with AI Technology",
      "Accelerate the progress in reaching your goals",
    ],
    introductionSection: [
      "Combine Extensive Knowledge of Finance and Engineering",
      "We provide our clients with full automated wealth management experience harnessed the power of machine learning and quantitative algorithm.",
      "Build on AI and Quantitative Models",
      "Growin utilizes different kinds of AI algorithms to compose diversified and low-risk portfolios across ETFs and stocks. No matter you are a naive investors or a sophisticated trader, we’ve built an automated strategy suits you best.",
    ],
    globalSection: [
      "Invest in Thousands of Global Companies",
      "Our algorithms scans thousands of stocks and ETFs on the daily basis, and find the optimal assets to include in your portfolio.",
    ],
    methodsSection: [
      "Chasing Market is Not One of Ways to Accumulate Wealth!",
      "We manage your investment with long term strategy and consistently growing your wealth.",

      "Monitor the Market",
      "We continuously tracking worldwide economic and leveraging machine learning algorithms to analyze extensive financial data to ensure the safety of your wealth and adjust your portfolio in real-time.",

      "Manage Your Risk",
      "We customize the optimal investment portfolio for your based on your risk profile and financial objectives. You won't have to worry about market fluctuations and risk-related concerns. Instead, you will receive returns in line with the expected level of risk.",

      "Rebalance Automatically",
      "We adjust your portfolio according to the market circumstances automatically. You can have your portfolio under control without making any investment decision.",
    ],
    learningSection: [
      "Power of Machine Learning",
      "Several predictors team up as our market researchers, analyzing the market data at all times. They help us come up with the mid to long-term market prediction and dynamically allocate your investment.",

      "Invest across the World",
      "Your portfolio is dynamically allocating money to all markets around the globe. We strive for helping you generate consistent returns and control risk.",
    ],
    strategySection: [
      "We Offer Multiple Styles of Investing Strategies",
      "Investing on single strategy is not enough to deal with the fickle circumstances.",
      "Growin forges hybrid strategies for you to keep strong in tempestuous market.",

      "Asset Allocation",
      "Our asset allocation strategy uses machine learning, plus nobel prize-winning research to select and allocate assets. The optimal returns at moderate risk is what this portfolio aims to provide.",

      "Value Investing",
      "Our value investing strategy uses machine learning to study and learn the company’s fundamental and technical metrics, buying stocks with great potential to move higher in the near future,  and adjust monthly to create the opportunity to beat the market.",
    ],
    endingSection: ["Growin Makes Every Investment Decision for You!"],
  },
};
