export const stockMining = {
  zh: {
    viewProgram: "查看方案",
    findPotentialStocks: "找出潛力股不再耗時費心",
    stockMenu: "熱門股票 : ",
    growinDescription:
      "Growin 個股健診、個股走勢、個股分析工具，獨家五力分析技術與個股篩選器，將零散的個股資訊及指標迅速彙整，助您高效蒐集市場價值潛在標的，讓 AI 演算法智能輔助投資決策、主動交易！",

    stockAnalysis: "輕鬆了解上市公司的當前表現",
    stockAnalysisDescription:
      "Growin 的 AI 演算依據多項數據資訊，予以標的五個面向評分，讓您在多元複雜的投資市場裡，也能輕鬆看懂上市公司的當前狀態",
    fiveForceDescription: "五力面向說明",
    close: "關閉",
    startForFree: "前往體驗",
    scoreDescription: `AI 演算法依據多項數據資訊進行統整及分析，透過以下五個面向了解上市公司當前的狀態<br><br>
      1. 價值：了解該公司的財務及成長獲利狀況<br>
      2. 趨勢：洞察個股股價的動能累積狀況及走勢行情<br>
      3. 波段：找到低買高賣的時機，抓住大波段行情<br>
      4. 籌碼：收集 13F 持股報告資訊，分析各大投資機構資金進出的流向<br>
      5. 股利：找到殖利率高但波動小的標的，得到穩定的股利獲利
    `,

    overallMarket: "整體市場的",
    averageScore: "平均分數",
    someProblem: "目前出現了一些問題，請稍後再試 🥲",
    easyToUnderstand: "輕鬆了解上市公司的當前表現",
    elevenCategories: "了解 11 個板塊的狀態，以掌握美股產業的趨勢走向",

    investmentStyle: "你是哪種投資型風格",
    understandYourself:
      "了解自己屬於什麼投資風格，可以更快找到適合自己的投資方式。",

    updatedTo: "資料更新至",
    useCases: "五力分析使用範例",

    highlight: "特色介紹",

    investment: "讓投資不再隨波逐流，立即創造超額報酬！",
    experience: "立即加入會員體驗多項功能，打造專屬投資組合！",
    getStarted: "立即體驗",
  },
  en: {
    viewProgram: "Upgrade",
    findPotentialStocks: "Identify High-Potential Stocks with Seamless Ease",
    stockMenu: "Popular stocks: ",
    growinDescription:
      "Maximize Your Potential with Growin's 5-Dimensional AI-Powered Stock Tools: Seize Market Opportunities and Empower Your Investment Decisions with Ease.",

    stockAnalysis: "Analyze any Stock in a Snap",
    stockAnalysisDescription:
      "Growin's AI evaluates stocks across 5 dimensions, enabling you to spot strengths and opportunities for your trading strategy.",
    fiveForceDescription: "Introduce our AI 5-dimension scoring",
    close: "Close",
    startForFree: "Start for Free",
    scoreDescription: `AI analyze multiple data sources to know the current status of listed companies based on the following aspects:<br><br>
      1. Value: Understanding the financial and profit growth situation of the company.<br>
      2. Trend: Insight into the momentum accumulation and trend of the stock price.<br>
      3. Swing Trading: Capturing significant market timing for buy and sell.<br>
      4. Whale Interest: Gathering 13F reports to analyze the funds flow of major institutions.<br>
      5. Dividend: Target high dividend yields and low volatility to achieve stable dividend profits.
    `,

    overallMarket: "Mkt. ",
    averageScore: " Avg",
    someProblem: "There seems to be a problem. Please try again later 🥲",
    easyToUnderstand: "Grasp Sector trends by AI",
    elevenCategories:
      "Spot on the status of 11 sectors and capturing the strong sector and stock trends.",

    investmentStyle: "Cater to Your Investment Style",
    understandYourself:
      "Align your investment style with a 5-dimension AI score combination.",

    updatedTo: "Updated to",
    useCases: "Use Cases",

    highlight: "Highlight Showcase",

    investment: "A New Way to Make Smarter Investment Decisions",
    experience:
      "Embrace free AI technology, empower your investment decisions, and take it to a new level.",
    getStarted: "Get Started",
  },
};
