import { PatternType } from "@/context/redux/reducers/screener/types";
import { Lang } from "@/i18n-config";

export const pattern: Record<Lang, Record<PatternType, string>> = {
  zh: {
    momentumSqueezePattern: "后羿射日",
    cupAndHandle: "杯柄",
    invertedCupAndHandle: "倒杯柄",
    MTop: "Ｍ頭",
    WBottom: "Ｗ底",
    ascendingTriangle: "上升三角型",
    descendingTriangle: "下降三角型",
    headAndShouldersTop: "頭肩頂",
    headAndShouldersBottom: "反向頭肩頂",
    volatilityContraction: "價格波動收縮 (VCP)",
  },
  en: {
    momentumSqueezePattern: "Momentum Squeeze",
    cupAndHandle: "Cup and Handle",
    invertedCupAndHandle: "Inverted Cup and Handle",
    MTop: "Double Top",
    WBottom: "Double Bottom",
    ascendingTriangle: "Ascending Triangle",
    descendingTriangle: "Descending Triangle",
    headAndShouldersTop: "Head and Shoulders Top",
    headAndShouldersBottom: "Head and Shoulders Bottom",
    volatilityContraction: "Volatility Contraction (VCP)",
  },
};
