import { backtest } from "./backtest";
import { chip } from "./chip";
import { dividend } from "./dividend";
import { historicalRating } from "./historical-rating";
import { navigation } from "./navigation";
import { overview } from "./overview";
import { relatedSymbol } from "./related-symbol";
import { scoreDescription } from "./score-description";
import { search } from "./search";
import { shared } from "./shared";
import { swing } from "./swing";
import { trend } from "./trend";
import { value } from "./value";

export const analysis = {
  zh: {
    noSymbol: ({ symbol }: { symbol: string }) => `${symbol} 此檔標的不存在`,
    checkOtherSymbol: "前往查看其他標的",
    noScore: "無法評分",
    noSymbolData: ({ symbol }: { symbol: string }) =>
      `${symbol} 目前資料不足，AI 尚且無法給予評分`,
    analysis: "分析",

    shared: shared.zh,
    search: search.zh,
    overview: overview.zh,
    chip: chip.zh,
    dividend: dividend.zh,
    trend: trend.zh,
    value: value.zh,
    swing: swing.zh,
    historicalRating: historicalRating.zh,
    backtest: backtest.zh,
    relatedSymbol: relatedSymbol.zh,
    navigation: navigation.zh,
    scoreDescription: scoreDescription.zh,
    viewChart: "查看圖表",
  },
  en: {
    noSymbol: ({ symbol }: { symbol: string }) => `${symbol} does not exist.`,
    checkOtherSymbol: "Go check out other options.",
    noScore: "No score",
    noSymbolData: ({ symbol }: { symbol: string }) =>
      `Due to insufficient data, AI is currently unable to assign a rating to ${symbol}. `,
    analysis: " Analysis",

    shared: shared.en,
    search: search.en,
    overview: overview.en,
    chip: chip.en,
    dividend: dividend.en,
    trend: trend.en,
    value: value.en,
    swing: swing.en,
    historicalRating: historicalRating.en,
    backtest: backtest.en,
    relatedSymbol: relatedSymbol.en,
    navigation: navigation.en,
    scoreDescription: scoreDescription.en,
    viewChart: "View Chart",
  },
};
