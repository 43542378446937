export const investmentFirms13F = {
  zh: {
    ownerName: "投資機構名稱",
    shares: "持股數量",
    change: "持股變化率",
    changeWeight: "持股變化率",
    strategy: "投資風格",
    positionValue: "部位價值",
    portfolioAllocation: "投組比例",
    famousInstitutesStatus: ({ symbol }: { symbol: string }) =>
      `知名機構近 4 季持有 ${symbol} 狀況`,
    famousDescription: ({ symbol }: { symbol?: string }) =>
      `精選知名大型機構近 4 季持有 ${symbol} 股票的數量及變化`,
    famousTableTitle: "知名投資機構近 4 季持股狀態",
    rankingTable: "機構排名表",
    rankingDescription:
      "以下為最新一季所有繳交 13F 持倉報告的大型機構中，有購買這檔股票的統計表格。",
  },
  en: {
    ownerName: "Institution",
    shares: "Shares",
    change: "Chg. (%)",
    changeWeight: "Chg. Weight",
    strategy: "Investment Style",
    positionValue: "Total Amount",
    portfolioAllocation: "Weight",
    famousInstitutesStatus: ({ symbol }: { symbol: string }) =>
      `Prominent Inst. Holdings of ${symbol}`,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    famousDescription: ({ symbol }: { symbol?: string }) =>
      "The buying and selling records of 10 well-known and widely followed institutions for this stock over the past year are listed in the table below.",
    famousTableTitle: "The Records of 10 Institutions",
    rankingTable: "Institutional Ranking Table",
    rankingDescription:
      "The following is a statistical table of large institutions who have purchased this stock in the latest quarter of all 13F holdings reports.",
  },
};
