export const dateTime = {
  zh: {
    daily: "每日更新",
    weekly: "每週更新",
    monthly: "每月更新",
    quarterly: "每季更新",
    annual: "每年更新",
    latest: "資料更新至",
  },
  en: {
    daily: "Daily Update",
    weekly: "Weekly Update",
    monthly: "Monthly Update",
    quarterly: "Quarterly Update",
    annual: "Annual Update",
    latest: "Update to",
  },
};
