export const plan = {
  zh: {
    guest: "訪客",
    free: "免費方案",
    beginningMonthly: "入門方案（月繳）",
    beginningYearly: "入門方案（年繳）",
    basicMonthly: "基本方案（月繳）",
    basicYearly: "基本方案（年繳）",
    premiumMonthly: "進階方案（月繳）",
    premiumYearly: "進階方案（年繳）",
    enterprise: "企業方案",
  },
  en: {
    guest: "Guest",
    free: "Free",
    beginningMonthly: "Beginning (Monthly)",
    beginningYearly: "Beginning (Yearly)",
    basicMonthly: "Basic (Monthly)",
    basicYearly: "Basic (Yearly)",
    premiumMonthly: "Premium (Monthly)",
    premiumYearly: "Premium (Yearly)",
    enterprise: "Enterprise",
  },
};
