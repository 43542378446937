export const home = {
  zh: {
    getStarted: "立即體驗",
    readMore: "瞭解更多",
    learnMore: "閱讀更多",
    bannerSection: {
      title: `透過大數據分析\n開啟智慧投資，讓理財生活並行！`,
      content:
        "Growin 致力於將 AI 人工智慧與交易投資策略進行結合，透過大數據的收集及分析，為投資人打造被動穩健的智能理財服務以及主動積極的投資分析工具。提供多元的 AI 投資方式，不只期望為您達到優異報酬，也希望協助降低投資的複雜度及焦慮感，讓您能安心投資！",
    },
    roboSection: {
      title: "Growin - 智能理財",
      subtitle: "Grow Your Wealth, Win A Better Life",
      AIInvestment: {
        title: "AI 理財智能投資",
        content:
          "為了解決大多投資人無法長期盯盤或花費時間研究的問題，Growin 透過 AI 演算調配出由 ETF 及美股所組成的資產配置投組，可以 24/7 全天候掌握市場控管風險，每月定期自動為您調整投資組合，使其處在最適化狀態！",
      },
      quickAndConvenient: {
        title: "開戶簡單，操作方便",
        content:
          "為了確保投資者投資上的安全，Growin 服務整合了美國前五大券商 Interactive Brokers 的開戶系統，讓您無需跨平台操作即可直接完成證券開戶，未來的投資也都受到美國 SEC 的保護。",
      },
      customizedPortfolio: {
        title: "投資計畫客製多元",
        content:
          "投資理財是為了人生不同的目標而規劃，Growin 提供多元投資組合，讓投資人可以依據不一樣的理財需求及可承受風險程度，提供合適的投資組合及建議。",
      },
      lowMaintenanceFeeAndTransparency: {
        title: "費用合理資訊透明",
        content:
          "Growin 智能理財服務以大數據演算做投資決策，系統自動化交易，因此費用低廉，年管理費最低僅要 0.25%，為投資者清楚紀錄每筆交易內容、股利、管理費等資訊。",
      },
    },
    stockMiningSection: {
      title: "Growin - 個股探勘",
      subtitle: "找出潛力股不再耗時費心",
      content:
        "喜歡做主動交易的投資人有機會藉 AI 演算法的協助做投資決策囉！透過 Growin 獨家的五力分析技術與個股篩選器，將零散的個股資訊及指標迅速彙整，幫助您高效率地蒐集市場上有價值的潛在標的。",
    },
    technologySection: {
      title: "為每個人打開參與投資的機會",
      content:
        "借助科技的力量，讓投資獲益不再是一件難事。就長期投資而言，不論是自主或被動操作，科技可以協助您得到優異的投資表現。",
      AIMarketPrediction: {
        title: "AI 市場預測",
        content:
          "AI 人工智慧收集及分析大量數據（包含經濟面、財務面、量價面...等），來預測全球市場的未來走勢。在資產配置中，正確的市場分析是最基礎且最關鍵的步驟，能有效提高資產配置所帶來的效益。",
      },
      hybridInvestmentStrategies: {
        title: "多元策略靈活混搭",
        content:
          "考量每個人的投資風格、投資目標與市場現況，應有相對應的投資建議，Growin 開發多套投資策略演算法，如資產配置策略、價值投資策略等，將能提供更客製化的理財服務。",
      },
    },
    newsSection: [
      {
        title:
          "機器人也能幫你理財！體現普惠金融價值，Growin 用機器學習打造出準確率逾 8 成的投資模型",
        outline:
          "隨著科技的發展，TradingValley 開始思考如何把最新的科技與金融做結合，來改變這個龐大卻傳統的領域。TradingValley 是由量化交易員、資料科學家、財務分析師、工程師、數位行銷、設計師所組成，並將數十年的量化投資經驗結合先進的 AI 技術，打造了一款名叫 Growin APP 的智慧理財軟體。透過人工智慧等數位工具的輔助，讓使用者可以在減少人為因素的情況下進行投資，仰賴 Growin 背後打造的各種演算模型，當目標明確後、交由投資機器人處理，打敗沒有紀律與浮動的人心就有機會創造獲利。",
      },
      {
        title:
          "AI 理財新創 TradingValley 完成 3,000 萬元 Pre-A 輪募資，將提供最多年化 18% 獲利方案投資組合！",
        outline:
          "AI 智能理財新創 TradingValley（立鼎資訊）宣布完成新台幣 3,000 萬元的 Pre-A 輪募資，此輪募資由多位清華天使投資人領投，搭配天使投資人集英資訊、矽谷 Google 資料科學家與工程師等支持挹注，金融科技公司 StockFeel 股感也參與本輪策略投資。此輪募資主要為招募更多優秀的人才、研發 AI 新技術與投資策略，同時將現於美國推出的 AI 理財服務「Growin」落地台灣，拓展國內智能理財市場，未來將透過股感知識圖譜技術提升智能理財的服務品質。",
      },
      {
        title: "未來新趨勢：機器人投資在資產管理面的應用",
        outline:
          "資產和財富管理機構一直在填補全球金融危機造成的缺口，他們在貿易融資、點對點貸款和基礎設施等領域的參與將大幅增加。TradingValley 根據市場變化和投資趨勢，搭配智能演算法生成由 ETF & 美股所組成的投資組合，他們所建造的服務也會引導用戶依據自身的需求選擇合適的投資方式。",
      },
      {
        title:
          "Trading Valley 運用 AI 即時分析投資趨勢，大眾理財投資不再遙不可及",
        outline:
          "伴隨金融科技（FinTech）熱潮在市場上興起，隨著大數據及演算法日益成熟，AI 智能理財機器人（Robo-Advisor）如雨後春筍般出現，並廣泛應用於日常生活中。TradingValley 運用演算網路技術，進行全球市場預測與更精確的投資標的篩選，並結合財務工程模型、軟體服務思維，打造新型態的智能投資理財服務。Trading Valley 執行長彭瀚生強調：「我們不用人為的觀點，全都以量化數據為主，不設定 rule，讓 AI 自己去找出 rule。簡單來說，我們收集的大量資訊提供 AI 自動化找出市場需求的資訊和趨勢。」",
      },
    ],
    CTATitle: "讓智能理財工具陪您達到財富生活平衡！",
    stockMining: "個股探勘",
    roboAdvisor: "智能理財",
  },
  en: {
    getStarted: "Get Started",
    readMore: "Read More",
    learnMore: "Learn More",
    bannerSection: {
      title: "Analyze Stocks and Invest Wisely with AI!",
      content:
        "Growin is dedicated to combining AI artificial intelligence with trading and investment strategies. Through the collection and analysis of big data, we create passive and robust intelligent financial services and active investment analysis tools for investors. By providing a variety of AI investment methods, we not only hope to achieve excellent returns for you, but also to help reduce the complexity and anxiety of investment, so that you can invest with peace of mind!",
    },
    roboSection: {
      title: "Growin - Robo Advisor",
      subtitle: "Grow Your Wealth, Win A Better Life",
      AIInvestment: {
        title: "AI Investment",
        content:
          "To solve the problem that investors is unavailable spending time on their investment, Growin provide a service which diversify your portfolio including both ETFs and US stocks from our AI algorithm. Also it monitor the market 24/7 and rebalance your portfolio automatically.",
      },
      quickAndConvenient: {
        title: "Quick & Convenient",
        content:
          "For the investors' account safety, Growin integrates the system of Interactive Brokers, the top five US brokerages, allowing you to complete account opening and risk measurement without cross-platform operations.",
      },
      customizedPortfolio: {
        title: "Customized Portfolio",
        content:
          "You can create multiple investment plans that suit your financial goals witch match your risk tolerance and maximize your profit for each plan.",
      },
      lowMaintenanceFeeAndTransparency: {
        title: "Low Maintenance Fee & Transparency",
        content:
          "It is required a minimal amount of $2,000 to start a plan and  charged annual maintenance fee 0.25%+. Every trading details are recorded in Growin App transparently.",
      },
    },
    stockMiningSection: {
      title: "Stock Mining",
      subtitle: "Efficiently pick out the most valuable underlyings",
      content:
        'With Growin\'s exclusive "5-Dimension Analysis" and "Stock Screener", the scattered stock information and indicators can be quickly aggregated to help you efficiently collect valuable potential targets.',
    },
    technologySection: {
      title: "More Investment Opportunities for Everyone",
      content:
        "With technology and big data, it is no longer difficult to make investment profitable. For long-term investment, whether active or passive investment, technology can help you achieve excellent performance.",
      AIMarketPrediction: {
        title: "AI Market Prediction",
        content:
          "Growin AI engine processes a huge amount of data to generate the market view, which is the most critical step when allocating your assets.",
      },
      hybridInvestmentStrategies: {
        title: "Hybrid Investment Strategies",
        content:
          "Growin’s multiple strategies, such as asset allocation and value investing, make investment plans more customizable to achieve your financial goals.",
      },
    },
    newsSection: [
      {
        title:
          "Wealth Management with Tech! Growin Uses Machine Learning to Create An Investment Model with An Accuracy Rate of Over 80%.",
        outline:
          "TradingValley dedicates to combine the latest technology with finance to change the traditional field. They are composed of quantitative traders, data scientists, financial analysts, engineers, digital marketing, and designers. It combines decades of quantitative investment experience with advanced AI technology to create a smart financial management called Growin APP. With the assistance of digital tools such as artificial intelligence, users can make investments while reducing human factors. Relying on various calculation models created by Growin, when the goal is clear, it is handed over to the investment robot to defeat the undisciplined and floating hearts. There is an opportunity to generate profits.",
      },
      {
        title:
          "TradingValley, A Robo-advisor Startup, got a NT$30 Million Pre-A round Fundraising",
        outline:
          "AI intelligent financial management startup TradingValley announced the completion of the NT$30 million Pre-A round of fundraising. This round of fundraising was led by a number of Tsinghua angel investors, also with angel investors Jiying Information, Silicon Valley Google Data scientists and engineers supported the investment, and the financial technology company StockFeel also participated in this round of strategic investment. This round of fundraising is mainly to recruit more outstanding talents, to develop new AI technologies and investment strategies. At the same time, the AI ​​wealth management service 'Growin', which is now launched in the United States, will be launched in Taiwan to expand the domestic intelligent wealth management market. Technology improves the service quality of intelligent financial management.",
      },
      {
        title:
          "Trend Alert: The Utilisation of Robo-Investors in Asset Management",
        outline:
          "Asset and wealth managers have been filling the financing gaps resulting from the Global Financial Crisis. Their involvement in niches such as trade finance, peer-to-peer lending and infrastructure will dramatically increase. TradingValley uses robo advisors to generate a number of financial portfolios based upon certain market and investment trends. The consumer is then led towards what portfolio would best suit their lifestyle based on their needs and wants.",
      },
      {
        title:
          "With TradingValley's AI robo-advisor, stable investment returns can be within reach for everyone",
        outline:
          "In the Asia Pacific, 90% of the asset in the bank is unmanaged, with a total value at $32 trillion. Jerry Peng, CEO of Trading Valley started his presentation to Business Next about his Fintech startup with an appalling figure. This is the market he chooses to explore and it's enormous not only in size but in potential.",
      },
    ],
    CTATitle: "Lift your financial future with the power of AI !",
    stockMining: "Stock Mining",
    roboAdvisor: "Robo-Advisor",
  },
};
