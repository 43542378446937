import { Category } from "@/features/media-center/services/get-category-data";
import { Lang } from "@/i18n-config";

type Props = {
  [K in Lang]: {
    [K in "title" | "description"]: {
      [K in "category" | "media"]: {
        [key in Category]: string;
      };
    };
  };
};

export const mediaCenter: Props = {
  zh: {
    title: {
      category: {
        "live-trading-sessions": "美股分析直播｜列表 - Growin",
        "trading-courses": "獨家交易課程｜列表 - Growin",
        "website-tutorials": "個股探勘教學影片｜使用攻略 - Growin",
        "weekly-market-analysis": "每週市場分析影片｜列表 - Growin",
      },
      media: {
        "live-trading-sessions": "美股分析直播｜列表 - Growin",
        "trading-courses": "獨家交易課程｜列表 - Growin",
        "website-tutorials": "個股探勘教學影片｜使用攻略 - Growin",
        "weekly-market-analysis": "每週市場分析影片｜列表 - Growin",
      },
    },
    description: {
      category: {
        "live-trading-sessions":
          "由 Growin 團隊帶你深入了解美股市場趨勢，透過專家直播分析，掌握投資機會。加入我們，洞悉市場動態，做出明智投資決策。",
        "trading-courses":
          "由 Growin 獨家推出的交易課程，適用於台、美股交易，探索專業的交易策略和技巧，提升您的投資技能。立即加入我們的課程，開啟財富增長之旅。",
        "website-tutorials":
          "透過 Growin 團隊錄製的教學影片，教您如何使用個股探勘功能快速找出潛力個股。",
        "weekly-market-analysis":
          "由 Growin 團隊為您掌握最新市場狀況，透過每週市場分析影片，獲取深入投資洞察。",
      },
      media: {
        "live-trading-sessions":
          "由 Growin 團隊帶你深入了解美股市場趨勢，透過專家直播分析，掌握投資機會。加入我們，洞悉市場動態，做出明智投資決策。",
        "trading-courses":
          "由 Growin 獨家推出的交易課程，適用於台、美股交易，探索專業的交易策略和技巧，提升您的投資技能。立即加入我們的課程，開啟財富增長之旅。",
        "website-tutorials":
          "透過 Growin 團隊錄製的教學影片，教您如何使用個股探勘功能快速找出潛力個股。",
        "weekly-market-analysis":
          "由 Growin 團隊為您掌握最新市場狀況，透過每週市場分析影片，獲取深入投資洞察。",
      },
    },
  },
  en: {
    title: {
      category: {
        "live-trading-sessions": "US Stock Analysis Live Stream｜List - Growin",
        "trading-courses": "Exclusive Trading Course｜List - Growin",
        "website-tutorials": "Stockmining Tutorial Videos｜List - Growin",
        "weekly-market-analysis":
          "Weekly Market Analysis Videos｜List - Growin",
      },
      media: {
        "live-trading-sessions": "US Stock Analysis Live Stream｜List - Growin",
        "trading-courses": "Exclusive Trading Course｜List - Growin",
        "website-tutorials": "Stockmining Tutorial Videos｜List - Growin",
        "weekly-market-analysis":
          "Weekly Market Analysis Videos｜List - Growin",
      },
    },
    description: {
      category: {
        "live-trading-sessions":
          "Join the Growin membership to gain a deep understanding of US stock market trends. Through expert live analysis, seize investment opportunities. ",
        "trading-courses":
          "Exclusive trading courses offered by Growin, suitable for Taiwan and US stock trading. Explore professional trading strategies and techniques to enhance your investment skills.",
        "website-tutorials":
          "Learn how to quickly identify potential stocks using the individual stock exploration feature through tutorial videos recorded by the Growin team.",
        "weekly-market-analysis":
          "Let the Growin team keep you informed of the latest market conditions. Gain in-depth investment insights through our weekly market analysis videos.",
      },
      media: {
        "live-trading-sessions":
          "Join the Growin membership to gain a deep understanding of US stock market trends. Through expert live analysis, seize investment opportunities. ",
        "trading-courses":
          "Exclusive trading courses offered by Growin, suitable for Taiwan and US stock trading. Explore professional trading strategies and techniques to enhance your investment skills.",
        "website-tutorials":
          "Learn how to quickly identify potential stocks using the individual stock exploration feature through tutorial videos recorded by the Growin team.",
        "weekly-market-analysis":
          "Let the Growin team keep you informed of the latest market conditions. Gain in-depth investment insights through our weekly market analysis videos.",
      },
    },
  },
};
