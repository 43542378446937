export const chart = {
  zh: {
    timeFrame: {
      year: "年",
      month: "月",
      quarter: "季度",
      fourQuarters: "4季度",
    },
  },
  en: {
    timeFrame: {
      year: "Year",
      month: "Month",
      quarter: "Quarter",
      fourQuarters: "4 Quarters",
    },
  },
};
