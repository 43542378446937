import { Country } from "@/data/constants/country";

export const overview = {
  zh: {
    analysis: (symbol: string) => `${symbol} 投資分析`,
    instruction: (symbol: string) => `${symbol} 整體評價`,
    performance: (symbol: string) => `${symbol} 近期報酬表現`,
    highRelevance: (symbol: string) => `與 ${symbol} 同產業的標的表現`,
    profile: (symbol: string) => `${symbol} 公司資訊`,
    sameIndustry: "同產業平均",
    price: (symbol: string) => `${symbol} 股價`,
    stock: (country: Uppercase<Country>): string =>
      country === "TW" ? "台股" : "美股",
    highRelevanceTable: {
      symbol: "股票代碼",
      company: "公司名稱",
      value: "價值",
      trend: "趨勢",
      swing: "波段",
      chip: "籌碼",
      dividend: "股利",
      watchlist: "加入追蹤",
      score: (score: number) => `${score} 分`,
      viewAll: "查看全部",
      viewLess: "顯示較少",
    },
    seeMore: "查看更多",
    longTermValue: "長線價值",
    trendMomentum: "趨勢動能",
    swingSignal: "波段訊號",
    incomeInvesting: "存股收息",
    keysForBull: "看多重點：",
    conclusion: "分析結論",
    score: ({ orientation, score }: { orientation: string; score: number }) =>
      `${orientation} ${score} 分`,
  },
  en: {
    analysis: (symbol: string) => `${symbol} AI Analysis Overview`,
    instruction: (symbol: string) => `Analysis Instruction of ${symbol}`,
    performance: (symbol: string) => `${symbol} Current Performance`,
    highRelevance: (symbol: string) => `Top 10 High Relevance to ${symbol}`,
    profile: (symbol: string) => `${symbol} Profile`,
    sameIndustry: "Avg of Sector",
    price: (symbol: string) => `Price of ${symbol}`,
    stock: (country: Uppercase<Country>): string =>
      country === "TW" ? "TW Stock" : "US Stock",
    highRelevanceTable: {
      symbol: "Symbol",
      company: "Company Name",
      value: "Value",
      trend: "Trend",
      swing: "Swing Trading",
      chip: "Whale Interest",
      dividend: "Dividend",
      watchlist: "Add to Watchlist",
      score: (score: number) => `${score}`,
      viewAll: "View All",
      viewLess: "View Less",
    },
    seeMore: "See more",
    longTermValue: "Long-term Value",
    trendMomentum: "Trend Momentum",
    swingSignal: "Swing Signal",
    incomeInvesting: "Income Investing",
    keysForBull: "Keys for Bullish View",
    conclusion: "Analysis Conclusion",
    score: ({ orientation, score }: { orientation: string; score: number }) =>
      `${orientation}: ${score}`,
  },
};
