export const overview = {
  zh: {
    reportingInquires: "13F 報告機構查詢",
    search13F: "搜尋 13F 機構",
    popularSearches: "熱門搜尋機構",
    trialBall13F: "13F 機構風向球",
    mostBuy: "最多機構買入",
    mostSell: "最多機構賣出",
    contrarianBuy: "逆勢買入",
    momentumBuy: "順勢追高",
    flatBuy: "股價平穩買入",

    numberOfInvestors: ({ action }: { action: "add" | "reduce" }) =>
      `${action === "add" ? "買入" : "賣出"}機構數`,
    return: "報酬",
    netChange: "單位淨變化",
  },
  en: {
    reportingInquires: "13F Reports",
    search13F: "13F Institutions",
    popularSearches: "Hot Hit of Institutions",
    trialBall13F: "Overall of 13F",
    mostBuy: "Popular Buys",
    mostSell: "Popular Sells",
    contrarianBuy: "Buy the Dips",
    momentumBuy: "Buy at Rally",
    flatBuy: "Buy on Consolidation",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    numberOfInvestors: ({ action }: { action: "add" | "reduce" }) =>
      "Institution Chg.",
    return: "Return",
    netChange: "Share Net Chg.",
  },
};
