import { Category } from "@/features/ranking/data/category-data";
import { Lang } from "@/i18n-config";

export const category: Record<Lang, Record<Category, string>> = {
  zh: {
    "hot-search": "近一週熱搜",
    "top-gainers": "當日大漲",
    "top-losers": "當日大跌",
    eps: "每股盈餘",
    "net-flow": "資產淨流入",
    yield: "殖利率",
    "monthly-revenue-yoy": "月營收年增率",
    "pe-ratio": "本益比",
  },
  en: {
    "hot-search": "Weekly Hot Search",
    "top-gainers": "Top Gainers",
    "top-losers": "Biggest Losers",
    eps: "EPS",
    "net-flow": "Capital Net Inflow",
    yield: "Dividend Yield",
    "monthly-revenue-yoy": "Monthly Revenue YoY",
    "pe-ratio": "PE Ratio",
  },
};
