import { category } from "./category";
import { columnKey } from "./column-key";

export const ranking = {
  zh: {
    ranking: (country: string) => `${country}股排行榜`,
    openGraph: (country: string) => `${country}股排行`,
    subscribe: "訂閱基本方案",
    viewMore: "訂閱查看更多排行榜內容",
    goSubscribe: "前往訂閱",

    category: category.zh,
    columnKey: columnKey.zh,
  },
  en: {
    ranking: (country: string) => `${country} Stock Ranking`,
    openGraph: (country: string) => `${country} Stock Ranking`,
    subscribe: "Subscribe to Basic Plan",
    viewMore: "Subscribe to view more ranking",
    goSubscribe: "Go to subscribe",

    category: category.en,
    columnKey: columnKey.en,
  },
};
