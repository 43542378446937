import { factor } from "./factor";
import { history } from "./history";

export const dividend = {
  zh: {
    forceInfo: [
      "喜愛存股票的投資人特別會關注公司發放股利的情形，大部分的存股投資人買進後會持有很長的一段時間不賣出股票，因此當股票穩定發送高額股利，對投資人來說就類似買房當包租公收租金般，每年均可有穩定的現金流入。",

      "Growin 股利力道分數可以快速協助投資人，此力道分數參考企業過去的股利政策、每年殖利率與填息狀況等統整編制而成，分數越高代表個股的配息率較高、股利政策穩定，且股價波動相對較小。然而，存股投資人最害怕的就是“賺了股利，賠了價差”，因此除了股利力道分數外，建議投資人搭配其他面向的評分（ex.價值力道評分）一起綜合參考。",

      "請注意：此股利力道的評分僅為輔助使用，投資人應建立適合自己的進場、出場、停損的策略來進行投資。",
    ],
    factor: factor.zh,
    history: history.zh,
  },
  en: {
    forceInfo: [
      "Investors who favor holding stocks often pay special attention to a company's dividend distribution. Most buy-and-hold investors keep their stocks for extended periods without selling. Therefore, when a stock consistently provides high dividends, it's akin to being a landlord collecting rent from a property, yielding a stable annual cash flow.",

      "Growin's Dividend Score swiftly assists investors. This score considers factors such as a company's past dividend policy, annual dividend yield, and dividend payout history. A higher score indicates a stock with a higher dividend yield, stable dividend policy, and relatively lower price volatility. However, buy-and-hold investors are most concerned about 'earning dividends but losing on the price differential.' Therefore, in addition to the Dividend Score, it's advisable for investors to consider other indicators as well  (e.g., Value Score).",

      "Note: This Dividend Score is intended for supplementary use only. Investors should establish their own strategies for entry, exit, and stop-loss levels based on their individual risk tolerance when making investment decisions.",
    ],
    factor: factor.en,
    history: history.en,
  },
};
