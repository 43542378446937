export const signup = {
  zh: {
    login: "登入",
    title: "註冊新帳號",
    register: "註冊",
    haveAccount: "已經是會員了嗎？",
    haveBusinessAccount: "已經是企業會員了嗎？",
    emailAlreadyRegistered: "此電子郵箱已被註冊",
    invalidReferralCode: "此推薦碼不存在",
    agreeWith: "註冊即表示你同意",
    termsOfUse: "服務條款",
    and: "和",
    privacyPolicy: "隱私條款",
    email: "輸入電子信箱",
    password: "輸入密碼",
    passwordConfirm: "確認密碼",
    enterReferralCode: "輸入推薦碼（選填）",
    validation: [
      "密碼長度需介於 8 到 16 個字元之間",
      "至少需包含 1 個大寫字母及 1 個小寫字母",
      "至少需包含 1 個數字",
    ],
    passwordInsecure: "密碼安全性不足",
    blankEmail: "請輸入電子郵件地址",
    invalidEmail: "請輸入有效電子郵件",
    confirmPasswordFailed: "兩次輸入密碼需相同",
    welcome: "歡迎使用 Growin 服務",
    orUse: "或使用",
    freeRegister: "免費註冊 Growin 可享有以上服務",
    serviceDescription:
      "透過大量數據解析檢視投資市場及標的表現，協助您更能掌握市場狀況，提升投資勝率！",
  },
  en: {
    login: "Log In",
    title: "Sign Up",
    register: "Sign Up",
    haveAccount: "Have an account?",
    haveBusinessAccount: "Have a business account?",
    emailAlreadyRegistered: "This Email has already been registered",
    invalidReferralCode: "This referral code is not exist",
    agreeWith: "By clicking this button, you agree to the ",
    termsOfUse: "Terms of Use ",
    and: "and ",
    privacyPolicy: "Privacy Policy",
    email: "Email address",
    password: "Password",
    passwordConfirm: "Confirm Password",
    enterReferralCode: "Referral Code (Optional)",
    validation: [
      "It must contain 8 to 16 characters",
      "It must contain at least 1 lowercase letter and 1 uppercase letter",
      "It must at least contain 1 numeric digit",
    ],
    passwordInsecure: "Password is insecure",
    blankEmail: "Email Address can not be blank",
    invalidEmail: "Should be a valid email",
    confirmPasswordFailed: "Password does not match",
    welcome: "Welcome to Growin",
    orUse: "or use",
    freeRegister: "Sign up to Growin for free to enjoy the services",
    serviceDescription:
      "Through the analysis of a large amount of data to view the performance of the investment market and targets, help you better grasp the market conditions and improve the investment winning rate!",
  },
};
