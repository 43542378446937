export const oriented = {
  zh: {
    overview: "總覽",
    value: "價值",
    trend: "趨勢",
    swing: "波段",
    chip: "籌碼",
    dividend: "股利",
  },
  en: {
    overview: "Overview",
    value: "Value",
    trend: "Trend",
    swing: "Swing Trading",
    chip: "Whale Interest",
    dividend: "Dividend",
  },
};
