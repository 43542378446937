import { auth } from "./auth";
import { global } from "./global";
import { home } from "./home";
import { my } from "./my";
import { stripe } from "./stripe";

export const metadata = {
  zh: {
    global: global.zh,
    auth: auth.zh,
    my: my.zh,
    home: home.zh,
    stripe: stripe.zh,
  },
  en: {
    global: global.en,
    auth: auth.en,
    my: my.en,
    home: home.en,
    stripe: stripe.en,
  },
};
