export const factor = {
  zh: {
    title: (symbol: string) => `${symbol} 股利參考因子（近 10 年統計）`,
    description: "股利力道分數主要依據以下的統計數據及殖利率等因子進行評價。",
    historicalDividend: "歷史股利政策",
    relatedStatistics: "股利相關數據統計",
    averageYield: "平均殖利率",
    averageVolatility: "平均波動率",
    averageFillingRate: "填權息率",
    averageFillingDays: "平均填權息花費日數",
    consequentYears: "股利連續分派數",
    yield: "殖利率",
    volatility: "波動率",
    industry: "同產業平均",
    industryAverage: "同產業平均",
    market: "整體市場平均",
    marketAverage: "整體市場平均",
    comparison: "殖利率與波動率比較",
    cash: "現金股利",
    stock: "股票股利",
    year: "股利發放年度",
    total: "股利合計",
    daysFilling: "填權息花費日數",
    distributionRate: "盈餘分配率",
    historicalDescription:
      "以下將條列個股過去到現在的股利政策，並計算殖利率、填息日數等資訊。",
    days: (days: number) => `${days} 天`,
    years: (years: number) => `${years} 年`,
  },
  en: {
    title: (symbol: string) => `${symbol} Dividend Reference Factor`,
    description:
      "The dividend force score is mainly based on the following statistical data and factors such as dividend yield.",
    historicalDividend: "Historical Dividend Policy",
    relatedStatistics: "Related Statistics",
    averageYield: "Avg. Yield",
    averageVolatility: "Avg. Volatility",
    averageFillingRate: "Avg. Filling Rate",
    averageFillingDays: "Avg. Filling Days",
    consequentYears: "Consequent Years",
    yield: "Dividend Yield",
    volatility: "Volatility",
    industry: "Industry",
    industryAverage: "Sector",
    market: "Market Average",
    marketAverage: "Market",
    comparison: "Dividend Yield & Volatility",
    cash: "Cash Dividend",
    stock: "Stock Dividend",
    year: "Year",
    total: "Total",
    daysFilling: "Filling Days",
    distributionRate: "Payout Ratio",
    historicalDescription:
      "The following lists the company's past and present dividend policies, and calculates information such as dividend yield and ex-dividend days.",
    days: (days: number) => `${days}`,
    years: (years: number) => `${years}`,
  },
};
