import { footer } from "./footer";
import { myFooter } from "./my-footer";
import { navbar } from "./navbar";
import { watchlist } from "./watchlist";

export const layout = {
  zh: {
    newbie: "新手專區",
    navbar: navbar.zh,
    myFooter: myFooter.zh,
    watchlist: watchlist.zh,
    footer: footer.zh,
  },
  en: {
    newbie: "101s",
    navbar: navbar.en,
    myFooter: myFooter.en,
    watchlist: watchlist.en,
    footer: footer.en,
  },
};
