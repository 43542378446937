import { layout } from "./layout";

export const fearGreed = {
  zh: {
    layout: layout.zh,
  },
  en: {
    layout: layout.en,
  },
};
