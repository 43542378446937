export const warningIndicator = {
  zh: {
    title: {
      historicalSignal: "市場預警燈號查詢 | 市場警訊 | 美股市場 - Growin",
      fundingLevelBacktest: "資金水位計算 | 市場警訊 | 美股市場 - Growin",
      pmi: "PMI 製造業採購經理人指數 | 市場情緒 | 美股市場 - Growin",
      cpi: "美國 CPI 通膨率 | 市場情緒 | 美股市場 - Growin",
      treasurySpread: "10 年期減 1 年公債利差 | 市場情緒 | 美股市場 - Growin",
      skewIndex: "黑天鵝指數 SKEW | 市場情緒 | 美股市場 - Growin",
      roroIndex: "RORO 指標 | 市場情緒 | 美股市場 - Growin",
      putCallRatio: "P/C Ratio 指標查詢 | 市場情緒 | 美股市場 - Growin",
      vix: "VIX 恐慌指數 | 市場情緒 | 美股市場 - Growin",
      moveIndex: "美林公債波動率指數 | 市場情緒 | 美股市場 - Growin",
    },
    description: {
      historicalSignal:
        "Growin 市場預警燈號，迅速釐清當週市場情緒警訊，讓你避開美股崩盤時的下檔風險，做好美股大跌前的風險評估。",
      fundingLevelBacktest:
        "資金水位意旨股票佔總投資金額的比例，在長期投資中因為投資市場狀況，資金水位控管是相當重要的環節。Growin 提供提供市場預警燈號，再搭配資金水位的回測計算，幫助投資人更清楚了解自己的資金水位策略是否可迎來更好的投資報酬。",
      pmi: "Growin 所統整的 PMI 每月製造業採購經理人指數涵蓋新訂單、生產、僱傭等等關鍵經濟指標，廣泛用於評估經濟活動和預測經濟趨勢。",
      cpi: "Growin 提供最新美國CPI通膨率數據，給予市場評估經濟是否過熱、聯邦基準利率是否合理的依據。",
      treasurySpread:
        "長天期的公債殖利率容易受到市場景氣的影響；而短天期則較容易受到利率政策的影響。因此我們常拿長短天期公債殖利率差額代表景氣相對於利率政策之間的差異，是用來觀察經濟循環的常見指標。",
      skewIndex:
        "黑天鵝指數（SKEW Index）用來衡量標普500指數選擇權價格的尾部風險。該指數反映了市場對於極端下跌事件的擔憂程度，指數值越高，表示市場預期出現重大下跌的可能性越大。",
      roroIndex:
        "Growin 利用 S&P 500 指數、長短天期債券、商品及黃金等資產作為判讀市場的成份，再透過主成份分析計算出 Growin RORO index 值。",
      putCallRatio:
        "Put Call Ratio 指標可透過將市場上的 Put 成交量除以 Call 成交量來獲取，以便觀察市場情緒。當成交量中的 Put/Call Ratio 越大時，表示市場上 Put 的交易活躍度高於 Call，因而顯示出偏空的市場情緒。",
      vix: "此指標反映標普 500 指數期貨的波動程度，測量未來 30 天市場預期的波動程度。通常VIX 指數超過 40 時，表示市場對未來出現非理性恐慌；低於15 時，則出現非理性過熱的跡象。",
      moveIndex:
        "美林公債波動率指數是衡量美國公債市場波動性的指數，它通過追踪美國公債市場中期限1個月至30年的公債期權價格來計算波動率。指數的高值通常表示市場預期未來的利率波動較大，而低值則表明市場預期較為穩定。",
    },
  },
  en: {
    title: {
      historicalSignal: "Market Warning Indicator | Market Sentiment - Growin",
      fundingLevelBacktest:
        "Funding Level Backtest | Market Sentiment - Growin",
      pmi: "PMI Manufacturing Purchasing Managers Index | Market Sentiment",
      cpi: "U.S. CPI Inflation Rate | Market Sentiment",
      treasurySpread: "10-Year minus 1-Year Treasury Spread | Market Sentiment",
      skewIndex: "Black Swan Index SKEW | Market Sentiment",
      roroIndex: "RORO Index | Market Sentiment",
      putCallRatio: "Put Call Ratio | Market Sentiment",
      vix: "VIX Fear Index | Market Sentiment",
      moveIndex: "Merrill Lynch Move Index | Market Sentiment",
    },
    description: {
      historicalSignal:
        "Growin Market Warning Indicator quickly clarifies the market sentiment for the week, allowing you to avoid the plummeting risk during a U.S. stock market crash and to conduct a risk assessment before a bearish reversal in U.S. stocks.",
      fundingLevelBacktest:
        "Growin provides Market warning signals, paired with backtesting calculations of Funding levels, helping investors understand more clearly whether their Funding level backtest strategy can yield better investment returns.",
      pmi: "Growin's PMI monthly Manufacturing Purchasing Managers Index covers key economic indicators such as new orders, production, and employment, widely used to assess economic activity and predict economic trends.",
      cpi: "Growin provides the latest U.S. CPI inflation rate data, giving the market a basis for evaluating whether the economy is overheated and whether the federal benchmark interest rate is reasonable.",
      treasurySpread:
        "Long-term bond yields are easily affected by market conditions, while short-term bond yields are more easily influenced by interest rate policies. Therefore, we often use the difference between long-term and short-term bond yields to represent the difference between the economy and interest rate policies, a common indicator for observing economic cycles.",
      skewIndex:
        "The Black Swan Index (SKEW Index) is used to measure the tail risk of S&P 500 index option prices. The index reflects the market's concern about extreme downward events, with higher index values indicating a greater likelihood of significant declines.",
      roroIndex:
        "Growin uses the S&P 500 index, long-term and short-term bonds, commodities, and gold as components to interpret the market, and then calculates the Growin RORO index value through principal component analysis.",
      putCallRatio:
        "The Put Call Ratio indicator divides the market's Put trading volume by the Call trading volume to observe market sentiment. When the Put/Call Ratio in the trading volume is higher, it indicates that Put trading is more active than Call trading, suggesting a stronger bearish sentiment in the market.",
      vix: "This indicator reflects the volatility of S&P 500 index futures, measuring the expected volatility of the market for the next 30 days. Usually, when the VIX index exceeds 40, it indicates irrational panic in the future; when it is below 15, there are signs of irrational overheating.",
      moveIndex:
        "The Merrill Lynch Move Index is an index that measures the volatility of the U.S. bond market. It calculates volatility by tracking the prices of bond options with maturities ranging from 1 month to 30 years. A high index value usually indicates that the market expects greater future interest rate volatility, while a low value indicates that the market expects greater stability.",
    },
  },
};
