enum PowerSqueezeEnumZH {
  "無累積",
  "弱",
  "中",
  "強",
}

enum PowerSqueezeEnumEN {
  "None",
  "Minor",
  "Moderate",
  "Strong",
}

const SurfingTrendTextMap = {
  "1": "處於上漲",
  "-1": "處於下跌",
};

const MeanRevertingRadarTextMap = {
  "1": "跌深反彈",
  "-1": "漲多拉回",
};

const SurfingTrendTextMapEN = {
  "1": "Up Trend",
  "-1": "Down Trend",
};

const MeanRevertingRadarTextMapEN = {
  "1": "Revert Up",
  "-1": "Revert Down",
};

export const strategy = {
  zh: {
    twStock: "台股",
    usStock: "美股",
    kindlyReminder: "貼心小提醒",
    store: "儲存",
    strategyInfo: "策略資訊",
    saveStrategy: "儲存策略",
    saveStrategyTo: (name?: string) => `儲存策略至 ${name}`,
    saveNewStrategy: "另存新策略",
    subscribeDescription:
      "「儲存策略」功能需為基本 / 進階付費會員才可以使用喔！可點擊下方按鈕前往付費解鎖限制。",
    disableStrategyDescription:
      "「策略篩選」功能需為基本 / 進階付費會員才可以使用喔！可點擊下方按鈕前往付費解鎖限制。",
    subscribe: "前往訂閱或升級",
    nameLabel: "策略名稱",
    namePlaceholder: "策略名稱（至多 8 字）",
    descriptionLabel: "策略描述",
    descriptionPlaceholder: "策略描述（至多 20 字）",
    reachLimitDescription:
      "基本方案可儲存至多 3 個策略，可點擊下方按鈕前往升級解鎖限制。",
    noStrategies: "尚未建立任何策略",
    editStrategy: "編輯策略資訊",
    recommenderStrategy: ({ market }: { market: string }): string =>
      market === "TW" ? "台股策略" : "美股策略",
    allScreeners: "所有策略",
    powerSqueeze: "Power Squeeze",
    surfingTrend: "Surfing Trend",
    meanRevertingRadar: "Mean Reverting Radar",
    createStrategySuccess: "策略已建立成功",
    updateStrategySuccess: "策略已更新成功",
    deleteStrategySuccess: "策略已刪除成功",
    updateStrategyFailed: "更新策略失敗",
    deleteStrategyFailed: "刪除策略失敗",
    createStrategyFailed: "建立策略失敗",
    userStrategy: ({ market }: { market: string }) =>
      `我的${
        market === "TW" ? strategy.zh.twStock : strategy.zh.usStock
      }篩選策略`,
    period: {
      day: ({ indicator }: { indicator: string }) => `日線 ${indicator}`,
      week: ({ indicator }: { indicator: string }) => `週線 ${indicator}`,
      month: ({ indicator }: { indicator: string }) => `月線 ${indicator}`,
    },
    fiveScore: ({ score }: { score: number[] }) => `${score.join(", ")} 分`,
    powerSqueezeStrength: ({ strength }: { strength: number[] }) =>
      strength.map((item) => PowerSqueezeEnumZH[item]).join(", "),
    surfingTrendDescription: ({ trend }: { trend: string }) =>
      SurfingTrendTextMap[trend as keyof typeof SurfingTrendTextMap],
    meanRevertingRadarDescription: ({ trend }: { trend: string }) =>
      MeanRevertingRadarTextMap[
        trend as keyof typeof MeanRevertingRadarTextMap
      ],
  },
  en: {
    twStock: "TW Stock",
    usStock: "US Stock",
    kindlyReminder: "Kindly Reminder",
    store: "Save",
    strategyInfo: "Strategy Info",
    saveStrategy: "Save Strategy",
    saveStrategyTo: (name?: string) => `Save Strategy To ${name}`,
    saveNewStrategy: "Save New Strategy",
    subscribeDescription: `The "Screens Saving" feature is only available to Basic or Advanced paid members! You can click the button below to upgrade and unlock the restriction.`,
    disableStrategyDescription:
      'The "Strategy Screens" feature is only available to Basic or Advanced paid members! You can click the button below to upgrade and unlock the restriction.',
    subscribe: "Buy Or Upgrade",
    nameLabel: "Name",
    namePlaceholder: "Name（8 Words）",
    descriptionLabel: "Description",
    descriptionPlaceholder: "Description（20 Words）",
    reachLimitDescription:
      "The Basic plan allows you to store up to 3 strategies. You can click the button below to upgrade and unlock the restriction.",
    noStrategies: "No strategies found",
    editStrategy: "Edit Strategy Info",
    recommenderStrategy: ({ market }: { market: string }): string =>
      market === "TW" ? "TW Stock Screeners" : "US Stock Screeners",
    allScreeners: "All Screeners",
    createStrategySuccess: "Strategy created successfully",
    updateStrategySuccess: "Strategy updated successfully",
    deleteStrategySuccess: "Strategy deleted successfully",
    updateStrategyFailed: "Failed to update strategy",
    deleteStrategyFailed: "Failed to delete strategy",
    createStrategyFailed: "Failed to create strategy",
    userStrategy: ({ market }: { market: string }) =>
      `My ${
        market === "TW" ? strategy.en.twStock : strategy.en.usStock
      } Screeners`,
    period: {
      day: ({ indicator }: { indicator: string }) => `Daily ${indicator}`,
      week: ({ indicator }: { indicator: string }) => `Weekly ${indicator}`,
      month: ({ indicator }: { indicator: string }) => `Monthly ${indicator}`,
    },
    powerSqueeze: "Power Squeeze",
    surfingTrend: "Surfing Trend",
    meanRevertingRadar: "Mean Reverting Radar",
    fiveScore: ({ score }: { score: number[] }) => `${score.join(", ")}`,
    powerSqueezeStrength: ({ strength }: { strength: number[] }) =>
      strength.map((item) => PowerSqueezeEnumEN[item]).join(", "),
    surfingTrendDescription: ({ trend }: { trend: string }) =>
      SurfingTrendTextMapEN[trend as keyof typeof SurfingTrendTextMapEN],
    meanRevertingRadarDescription: ({ trend }: { trend: string }) =>
      MeanRevertingRadarTextMapEN[
        trend as keyof typeof MeanRevertingRadarTextMapEN
      ],
  },
};
