export const business = {
  zh: {
    title: "Growin 企業單位服務",
    description: "歡迎企業和教育機構與我們合作，有任何問題也歡迎隨時詢問",
    name: "姓名",
    nameRequired: "請輸入您的姓名",
    email: "電子郵件",
    emailRequired: "請輸入您的電子郵件",
    emailInvalid: "請輸入正確的電子郵件",
    message: "撰寫訊息給我們",
    messageRequired: "請輸入您的訊息",
    submit: "提交",
  },
  en: {
    title: "Sign up for Growin Business Service",
    description:
      "Welcome to cooperate with us, if you have any questions, please feel free to contact us",
    name: "Name",
    nameRequired: "Please fill your name",
    email: "Email",
    emailRequired: "Please fill your email",
    emailInvalid: "Please fill correct email",
    message: "Leave a message to us",
    messageRequired: "Please fill your message",
    submit: "Submit",
  },
};
