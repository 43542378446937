import { dialog } from "./dialog";
import { guidedCard } from "./guided-card";
import { lineBinding } from "./line-binding";
import { logs } from "./logs";
import { roboSection } from "./robo-section";
import { settings } from "./settings";

export const notification = {
  zh: {
    title: "推播通知設定",
    robots: "推播策略",
    robotLogs: "策略觸發日誌",
    buyInStrategy: "進場策略",
    sellOutStrategy: "出場策略",

    lineBinding: lineBinding.zh,
    logs: logs.zh,
    guidedCard: guidedCard.zh,
    roboSection: roboSection.zh,
    settings: settings.zh,
    dialog: dialog.zh,
  },
  en: {
    title: "Notification",
    robots: "Notification Robots",
    robotLogs: "Notification Logs",
    buyInStrategy: "Buy In",
    sellOutStrategy: "Sell Out",

    lineBinding: lineBinding.en,
    logs: logs.en,
    guidedCard: guidedCard.en,
    roboSection: roboSection.en,
    settings: settings.en,
    dialog: dialog.en,
  },
};
