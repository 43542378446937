export const marginTransactions = {
  zh: {
    title: "融資融券",
    date: "日期",
    closePrice: "股價",
    marginDiff: "融資變化",
    shortDiff: "融券變化",
    marginShortRatio: "券資比",
    marginUtilization: "融資使用率",
    margin: "融資",
    short: "融券",
  },
  en: {
    title: "Retail Long/Short Margin",
    date: "Date",
    closePrice: "Price",
    marginDiff: "Chg. of Retail Long Margin",
    shortDiff: "Chg. of Retail Short Margin",
    marginShortRatio: "Retail Margin Ratio",
    marginUtilization: "Retail Long Margin Usage",
    margin: "Retail Long Margin",
    short: "Retail Short Margin",
  },
};
