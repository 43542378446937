export const roboAdvisor = {
  zh: {
    title: "產品 | 機器人理財，智能投資 APP 推薦 - Growin",
    description:
      "Growin 擁有獨家機器人理財技術，透過智能投資 APP，運用 Growin 多重投資策略，由人工智能引擎挑選指數型基金及最具潛力的股票，為您打造最適合您的投資組合。",
  },
  en: {
    title: "Robo Advisor - Growin",
    description:
      "Growin has exclusive robo advisor technology. Through the intelligent investment APP, Growin uses multiple investment strategies to select index funds and the most promising stocks by artificial intelligence engine to create the most suitable investment portfolio for you.",
  },
};
