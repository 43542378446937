export const stripe = {
  zh: {
    title: "Growin | 訂閱成功",
    description:
      "感謝您的訂閱！您現在可以享受我們提供的所有服務。如果您有任何問題或疑慮，請隨時聯繫我們的客戶服務團隊。",
  },
  en: {
    title: "Growin | Subscription Success",
    description:
      "Thank you for your subscription! You can now enjoy all the services we provide. If you have any questions or concerns, please feel free to contact our customer service team.",
  },
};
