import { fundamental } from "./fundamental";
import { macro } from "./macro";
import { shared } from "./shared";
import { pattern } from "./pattern";
import { technical } from "./technical";

export const screenerOptions = {
  zh: {
    shared: shared.zh,
    macro: macro.zh,
    fundamental: fundamental.zh,
    technical: technical.zh,
    pattern: pattern.zh,
  },
  en: {
    shared: shared.en,
    macro: macro.en,
    fundamental: fundamental.en,
    technical: technical.en,
    pattern: pattern.en,
  },
};
