export const factor = {
  zh: {
    institutionUnit: "間",
    capitalInflow: "資金流入",
    accumulator: "加碼機構",
    initiator: "建倉機構",
    capitalOutflow: "資金流出",
    reducer: "減碼機構",
    liquidator: "清倉機構",
    netAssetGrowth: "資產凈增",
    inflowOutflow: "流入 - 流出",
    investmentFirms13F: "13F 投資機構",
    insiderTrading: "內部人員交易",
    holdingRate: "機構持有率",
    institutionNumber: "機構持有數",
    description:
      "根據最新一季的 13F 機構持倉報告，我們將購買這檔股票的基本資訊整理如下，其中包含了這檔股票的機構持有率、持有的機構數與相關的金流，讓您對於最新一季大型機構對於這檔股票的操作有個整體的概念。",
    symbolInstitutionNumber: ({ symbol }: { symbol: string }) =>
      `持有 ${symbol} 的機構數量`,
    whaleInterestRateDialog:
      "機構持有率看的是在外流通股數被大型機構所持有的比率，一般來說，一檔股票擁有越高的機構持有率會有助於價格的穩定。",
  },
  en: {
    institutionUnit: "",
    capitalInflow: "Capital Inflow",
    accumulator: "Accumulator",
    initiator: "Initiator",
    capitalOutflow: "Capital Outflow",
    reducer: "Reducer",
    liquidator: "Liquidator",
    netAssetGrowth: "Net Flow",
    inflowOutflow: "Inflow - Outflow",
    investmentFirms13F: "13F Investment Firm",
    insiderTrading: "Insider Trading",
    holdingRate: "Percentage of Institutional Ownerships",
    institutionNumber: "Number of Institutional Owners ",
    description:
      "According to the latest 13F reports, we summarizes key information, including whale invest rate, number of institutions, and related fund flows, providing an overview of major institutional activity.",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    symbolInstitutionNumber: (_props: { symbol: string }) =>
      "Number of Institutional Owners",
    whaleInterestRateDialog:
      "Percentage of Institutional Ownerships reflects the proportion of outstanding shares held by large institutional investors. Generally, a higher percentage of institutional ownerships for a stock contributes to price stability.",
  },
};
