export const stockMining = {
  zh: {
    title: "Growin 個股健診｜讓 AI 幫你找出強勢潛力股",
    description:
      "Growin 提供的個股健診及股市健診，使用最先進的 AI 技術幫助您進行個股走勢分析，找出市場上的強勢潛力股。",
  },
  en: {
    title: "Growin | Uncover Top Stock with AI Stock Picker",
    description:
      "Growin revolutionize your portfolio with AI Stock Picker. Dive into Growin Stock Mining instantly and master AI Stock Analysis for optimum results!",
  },
};
