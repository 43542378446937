export const winwinGpt = {
  zh: {
    title: "Growin WinwinGPT｜Waitlist",
    description:
      "透過 Growin 的 AI 投資顧問 WinwinGPT，您可以獲取最新美國上市公司的財報分析資訊及股價走勢。WinwinGPT 搭載了 ChatGPT，可為您提供精準財報分析、實時股價走勢、人性化的互動體驗。",
  },
  en: {
    title: "Growin WinwinGPT｜Waitlist",
    description:
      "With Growin's AI investment advisor WinwinGPT, you can access the latest financial analysis and stock price trends for US-listed companies. WinwinGPT is powered by ChatGPT, providing you with accurate financial analysis, stock price trends analysis and Humanized interactive experience. ",
  },
};
