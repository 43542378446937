export const subscribe = {
  zh: {
    analysis: {
      title: (count: number) => `每週標的查看檔數已達 ${count} 檔`,
      description: (symbols: string[]) =>
        `本週已查看標的有 ${symbols.join("、")}，訂閱查看更多內容！`,
      button: "立即前往訂閱",
    },
    screener: {
      title: (count: number) => `每週搜尋次數已達 ${count} 次`,
      description: "訂閱查看更多內容！",
      button: "立即前往訂閱",
    },
    form13F: {
      title: (count: number) => `每週機構查看家數已達 ${count} 家`,
      description: (institutions: string[]) =>
        `本週已查看的機構有 ${institutions.join("、")}，訂閱查看更多內容！`,
      button: "立即前往訂閱",
    },
    blog: {
      title: "訂閱方案看文章",
      description: "訂閱任一方案，就可以查看更多內容！",
      button: "前往訂閱",
    },
  },
  en: {
    analysis: {
      title: (count: number) => `The weekly viewing target: ${count} symbols`,
      description: (symbols: string[]) =>
        `You have viewed the following targets this week: ${symbols.join(
          ", "
        )}. Upgrade your plan for more.`,
      button: "Upgrade Now",
    },
    screener: {
      title: (count: number) => `The weekly searching target: ${count} times`,
      description: "Upgrade your plan for more.",
      button: "Upgrade Now",
    },
    form13F: {
      title: (count: number) =>
        `The weekly viewing target: ${count} institutions`,
      description: (institutions: string[]) =>
        `You have viewed the following targets this week: ${institutions.join(
          ", "
        )}. Upgrade your plan for more.`,
      button: "Upgrade Now",
    },
    blog: {
      title: "Subscribe for more article!",
      description: "Subscribe any plan any pricing for more articles!",
      button: "Subscribe Now",
    },
  },
};
