export const patternInfo = {
  zh: {
    momentumSqueezePattern: [
      "后羿射日型態是一個多頭形態，通常出現於因題材熱度或資金湧入引發快速上漲的情況中。這類上漲行情通常伴隨著投資者的強烈「錯失恐懼症（FOMO）」，導致市場追捧情緒高漲。然而，這種追高的情緒往往不會持續太久，會隨著短期利多的衰退而迅速下殺。",
      "在后羿射日型態中，股票的價格在第一波快速上漲後會發生急速回落（第二段下殺），跌幅通常落在前一波上漲幅度的 0.382~0.618 的位置，這些比例為關鍵的 Fibonacci 回檔水平。此區間通常會形成一個橫盤整理平台，該平台能夠有效消除前期 FOMO 的浮動籌碼，將股票從弱手換到強手。",
      "當洗盤結束後，該股在新的支撐平台上逐步穩定，增強了支撐力量。此時，該股票容易再次向上突破，並可能挑戰第一波上漲的高點，進而推動股價進一步上漲。",
    ],
    cupAndHandlePattern: [
      "杯柄形態（Cup-and-Handle）出現在股票價格先下跌，隨後回升形成一個圓形的「杯」字形。當價格達到杯的右側邊緣後，通常會出現一個短暫的回調，形成「柄」的部分，這是由於賣方在此高點施加的壓力所致。然而，一旦賣壓減弱，股票可能向上突破。",
      "對於波段交易者來說，向上突破信號表示買入機會。要計算目標價，可將杯的高度（即杯底至右側邊緣的距離）加到突破價上。此外，為了限制潛在損失，可考慮在突破點附近或稍低的位置設置止損單，以應對價格意外下行的風險。",
    ],
    invertedCupAndHandlePattern: [
      "倒杯柄型態（Inverted Cup-and-Handle）形成於價格先上升後回落，創造出一個倒置的「U」形「杯」字。當價格達到杯的右側邊緣後，隨之出現短暫的上漲，形成「柄」的部分，這通常是由於買方在此低點處施加的壓力。然而，當買方放棄時，賣方接管，股票可能迅速下跌。",
      "在價格向下突破後，波段交易者應順勢做空。考慮在突破點做空股票或購買看跌期權。計算目標價的方法是從右側杯緣的價格減去杯的高度（即最高價與支撐位的差距）。若價格跌破右側杯緣即確認型態成立。為了控制風險，可考慮在突破價位設置止損單，買回空頭頭寸或賣出看跌期權，以應對價格反向波動的情況。",
    ],
    wBottomPattern: [
      "W底型態形成於價格先下跌後反彈，再次下跌至相似水準，隨後上升，形成「W」形狀。此型態通常預示著從下跌趨勢到上升趨勢的潛在反轉。兩個低點代表支撐位，表明買方在此處介入以防止進一步下跌。",
      "當價格突破「W」的中間高點後，可能出現向上突破信號。交易者可以考慮在此突破點買入或購買看漲期權。目標價可透過將「W」的高度（中間高點與低點的差距）加到突破價上來估算。為了控制風險，可以在支撐位附近設置止損單，以在型態失敗時限制潛在損失。",
    ],
    mTopPattern: [
      "M頭型態（M Top）形成於價格先上升後回落，然後再次反彈至相似高點，最終再度下跌，形成「M」形狀。這一型態通常預示著從上升趨勢轉為下跌趨勢的潛在反轉。兩個高點代表阻力位，表明賣方在此水準阻止進一步上漲。",
      "當價格跌破「M」的中間低點後，即出現向下突破信號。此時，交易者可以考慮做空或購買看跌期權。目標價可透過將「M」的高度（兩個高點與中間低點的差距）減去突破價來估算。為了控制風險，可以在阻力位附近設置止損單，以在型態失敗時限制潛在損失。",
    ],
    volatilityContraction: [
      "價格波動收縮型態（Volatility Contraction Pattern, VCP）由美國投資冠軍馬克．米奈爾維尼 (Mark Minervini) 提出，是一種股價波動收縮的技術型態，特徵包括波動幅度逐漸減小、出現多次收縮（通常2至4次）、每次回檔幅度遞減，且時間間隔逐漸縮短。這反映了股價上漲過程中，賣壓逐步減弱與籌碼向耐心投資者的轉移。隨著波動收縮至一定程度且成交量萎縮，若股價放量突破前高，往往預示新的上漲趨勢，成為潛在的買入信號。",
    ],
    ascendingTrianglePattern: [
      "上升三角形（Ascending Triangle） 是一種持續型態，通常出現在上升趨勢中，表示股價可能進一步上漲。此形態由水平阻力線（上邊界） 和 上升趨勢線（下邊界） 組成，股價在此範圍內震盪，低點逐步抬高，顯示買盤增強、賣方減弱。當股價多次測試上邊界未突破，最終伴隨放量突破時，形態確認，市場進入新一輪漲勢。目標價可用「突破價 +（上邊界 - 下邊界最大距離）」計算。突破後可能回踩測試支撐，確認有效後繼續上漲。此形態常見於強勢市場，投資者可等待突破點進場以跟隨趨勢。",
    ],
    descendingTrianglePattern: [
      "下降三角形（Descending Triangle） 是一種持續型態，通常出現在下降趨勢中，表示股價可能進一步下跌。此形態由水平支撐線（下邊界） 和 下降趨勢線（上邊界） 組成，股價在此區間內震盪，高點逐步降低，顯示賣方力量增強、買方減弱。當股價多次測試下邊界支撐但未跌破，最終伴隨放量跌破時，形態確認，市場進入新一輪跌勢。目標價可用「突破價 -（上邊界 - 下邊界最大距離）」計算。跌破後可能回測支撐轉壓力，確認後繼續下跌。此形態常見於空頭市場，投資者可等待跌破支撐點後進場做空以順應趨勢。",
    ],
    headAndShouldersBottomPattern: [
      "反向頭肩頂（Inverse Head and Shoulders） 是底部反轉形態，通常出現在股價長期下跌後，預示趨勢由空轉多。形態包括左肩、頭部、右肩與頸線。當股價下跌形成左肩後短暫反彈，隨後創新低形成頭部，再次反彈但未創新低形成右肩，顯示拋壓減弱。當股價突破頸線並伴隨成交量放大，代表形態確認，買盤力量增強，預期股價將進一步上漲。漲幅目標可用「頸線突破價 +（頸線 - 頭部）」計算。此外，突破後可能會回踩頸線確認支撐，再啟動上漲趨勢，是關鍵進場機會。",
    ],
    headAndShouldersTopPattern: [
      "頭肩頂（Head and Shoulders） 是典型的頂部反轉形態，通常出現在股價長期上漲後，預示市場可能由多轉空。形態包括左肩、頭部、右肩與頸線。當股價上漲後形成左肩並短暫回落，隨後創出更高的頭部，但買盤動能減弱。當股價再度回落後反彈，卻未能突破頭部高點，形成右肩，顯示買方力量轉弱。當股價跌破頸線並伴隨放量，形態確認，空頭趨勢確立。跌破後，股價可能回測頸線確認壓力，隨後進一步下跌。目標跌幅可用「頭部高點 - 頸線價格」計算，向下延伸。投資者可在跌破頸線時減倉或做空，以避開潛在下跌風險。",
    ],

    selectedPatterns: ({
      dayPattern,
      weekPattern,
      monthPattern,
    }: {
      dayPattern?: string;
      weekPattern?: string;
      monthPattern?: string;
    }) => {
      const prefix = "以下標的的股價型態皆符合 ";
      const dayPatternText = dayPattern ? `（日）${dayPattern}` : "";
      const weekPatternText = weekPattern ? `（週）${weekPattern}` : "";
      const monthPatternText = monthPattern ? `（月）${monthPattern}` : "";
      const patternText = [dayPatternText, weekPatternText, monthPatternText]
        .filter(Boolean)
        .join("、");
      return prefix.concat(patternText);
    },
    patternInfoTitle: ({ orientation }: { orientation: string }) => {
      return `${orientation}型態說明`;
    },
  },
  en: {
    momentumSqueezePattern: [
      "The Momentum Squeeze Pattern is a bullish formation that appears when a stock rapidly surges due to thematic hype or capital inflows, triggering “Fear of Missing Out (FOMO)” among investors and leading to a buying frenzy. However, this rally is typically short-lived and followed by a swift pullback as initial enthusiasm fades.",
      "In this pattern, after the first sharp upward move, the stock undergoes a second, rapid decline, typically retracing to a level between 0.382 and 0.618 of the initial rise—key Fibonacci retracement ratios. This retracement forms a consolidation platform, effectively shaking out weaker FOMO-driven holders and transferring the stock to stronger hands.",
      "Once this consolidation phase completes and the stock stabilizes on solid support, it builds a stronger base. At this point, the stock is likely to initiate a new upward movement, potentially challenging the high of the initial rally for further gains.",
    ],
    cupAndHandlePattern: [
      `The Cup-and-Handle pattern forms when a security’s price first declines, then rises in a rounded “U” shape, creating the "cup." After reaching the right lip of the cup, a smaller, short-lived decline forms the "handle" as selling pressure mounts. This handle often results from sellers testing the previous high at the right lip, but once selling pressure eases, the security may break out upward.`,
      "For trading, an upward breakout signals a buying opportunity for swing traders. To calculate a target price, add the height of the cup (distance from the bottom of the cup to the right lip) to the breakout price. Placing a stop order near or slightly below the breakout point can help manage risk if the price moves unexpectedly.",
    ],
    invertedCupAndHandlePattern: [
      "The Inverted Cup-and-Handle pattern forms when prices rise and then fall, creating an upside-down “U”-shaped cup. After reaching the right edge, a brief upward move forms the handle due to buying pressure. Once buyers give up, sellers take control, potentially leading to a sharp decline.",
      "After a downward breakout, swing traders can short-sell or buy put options at the breakout point. The target price is calculated by subtracting the cup’s height (difference between the highest price and support level) from the right edge price. A breakout below this level confirms the pattern. To manage risk, consider a stop order at the breakout price to cover a short position or exit a put option if prices reverse.",
    ],
    wBottomPattern: [
      "The W Bottom pattern forms when a security’s price declines, rebounds, declines again to a similar level, and then rises, creating a “W” shape. This pattern indicates a potential reversal from a downtrend to an uptrend. The two low points represent support levels, showing that buyers are stepping in to prevent further decline.",
      "Once the price breaks above the middle peak of the “W,” it signals a possible upward breakout. Traders can consider buying at this breakout point or entering a call option. The target price can be estimated by adding the height of the “W” (difference between the middle peak and the low points) to the breakout price. To manage risk, a stop order can be placed near the support level to limit potential loss if the pattern fails.",
    ],
    mTopPattern: [
      "The M Top pattern forms when prices rise, pull back, rebound to a similar high, and then decline again, creating an “M” shape. This pattern typically signals a potential reversal from an uptrend to a downtrend. The two high points represent resistance levels, indicating that sellers are preventing further price increases.",
      "When the price breaks below the middle low of the “M,” it generates a downward breakout signal. Traders might consider short-selling or buying put options at this breakout point. The target price can be estimated by subtracting the height of the “M” (the difference between the high points and the middle low) from the breakout price. To manage risk, a stop order can be placed near the resistance level to limit potential losses if the pattern fails.",
    ],
    volatilityContraction: [
      "The Volatility Contraction Pattern (VCP), introduced by U.S. investing champion Mark Minervini, is a technical pattern characterized by contracting price volatility. Its features include gradually decreasing price fluctuations, multiple contractions (usually 2 to 4 times), diminishing retracement depth with each pullback, and increasingly shorter intervals between contractions. This pattern reflects the process where selling pressure weakens, and shares shift to more patient investors during an upward price movement. Once the volatility contracts to a certain level and trading volume declines, a breakout above the previous high with increased volume often signals the start of a new upward trend, presenting a potential buying opportunity.",
    ],
    ascendingTrianglePattern: [
      `The Ascending Triangle is a continuation pattern in an uptrend, signaling a potential price increase. It consists of a horizontal resistance line and a rising trendline. The price forms higher lows, showing stronger buying pressure. A breakout with volume confirms the pattern, leading to an upward move. The target price is "breakout price + (upper boundary - lower boundary)". After breaking out, the price may pull back to test support before continuing higher. Common in strong markets, investors can enter after the breakout to follow the trend.`,
    ],
    descendingTrianglePattern: [
      `The Descending Triangle is a continuation pattern in a downtrend, signaling further decline. It consists of a horizontal support line and a descending trendline. The price forms lower highs, showing strong selling pressure. A breakdown with volume confirms the pattern, leading to a downward move. The target price is "breakout price - (upper boundary - lower boundary)". After breaking down, the price may retest support as resistance before falling further. Common in bearish markets, investors can short after the breakdown to follow the trend.`,
    ],
    headAndShouldersBottomPattern: [
      `The Inverse Head and Shoulders is a bottom reversal pattern that typically appears after a prolonged downtrend, signaling a shift from bearish to bullish momentum. The pattern consists of a left shoulder, head, right shoulder, and neckline. When the stock declines to form the left shoulder and briefly rebounds, it then drops to a new low, creating the head. A subsequent rebound occurs, but the price does not fall to a new low, forming the right shoulder, indicating weakening selling pressure. When the stock breaks above the neckline with increased volume, the pattern is confirmed, suggesting stronger buying momentum and a potential uptrend. The target price can be estimated using "neckline breakout price + (neckline - head)". Additionally, a pullback to retest the neckline may occur before the uptrend resumes, presenting a key entry opportunity.`,
    ],
    headAndShouldersTopPattern: [
      `The Head and Shoulders pattern is a top reversal formation that appears after a prolonged uptrend, signaling a potential bearish shift. It consists of a left shoulder, head, right shoulder, and neckline. After an uptrend, the stock forms the left shoulder, followed by a higher head, but buying momentum weakens. The price declines, rebounds, but fails to surpass the head’s high, forming the right shoulder, showing reduced buying strength. When the stock breaks below the neckline with volume, the pattern confirms a bearish trend. The price may retest the neckline as resistance before falling further. The target drop is "head peak - neckline price" downward. Investors may reduce positions or short upon breakdown to avoid downside risks.`,
    ],
    selectedPatterns: ({
      dayPattern,
      weekPattern,
      monthPattern,
    }: {
      dayPattern?: string;
      weekPattern?: string;
      monthPattern?: string;
    }) => {
      const prefix =
        "The following securities exhibit price patterns that match ";
      const dayPatternText = dayPattern ? ` (Daily) ${dayPattern}` : "";
      const weekPatternText = weekPattern ? ` (Weekly) ${weekPattern}` : "";
      const monthPatternText = monthPattern ? ` (Monthly) ${monthPattern}` : "";
      const patternText = [dayPatternText, weekPatternText, monthPatternText]
        .filter(Boolean)
        .join("、");
      return prefix.concat(patternText);
    },
    patternInfoTitle: ({ orientation }: { orientation: string }) => {
      return `The Descriptions of ${orientation} Patterns`;
    },
  },
};
