export const blog = {
  zh: {
    title: "Growin Blog｜財報分析 / 產業研究 / 量化分析",
    description:
      "探索 Growin Blog 的台、美股投資專業內容。獲取市場分析、財報分析及投資策略，助您在股市中做出明智決策，實現財務目標。",
  },
  en: {
    title: "Growin Blog | Industry Research / Quantitative Analysis",
    description:
      "Explore Growin Blog's professional content on Taiwan and US stock investments. Gain market insights, financial statement analysis, and investment strategies to make informed decisions in the stock market and achieve your financial goals",
  },
};
