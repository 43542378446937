export const navigation = {
  zh: {
    AIAnalysis: "投資分析",
    performance: "近期報酬表現",
    top10Relevance: "同產業的標的表現",
    profile: "公司資料",
    price: "股價",
    analysisInstructions: "整體分析",
    referenceFactors: "參考因子",
    historicalScore: "歷史分數",
    backtest: "回測模擬",
    institutionHolding: "知名機構近 4 季持有狀況",
    insiderTransaction: "內部交易人員概況",
    dividendReferenceFactor: "股利參考因子（近 10 年統計）",
    historicalDividend: "歷史股利政策",
    listOfPages: "頁面大綱",
    forecast: "財務預測",
    revenue: "收益表",
    institutionalInvestors: "三大法人",
    marginTradingShortSelling: "融資融券",
    shareholderDiversification: "股權分散",
    insiderHolding: "董監事持股",
  },
  en: {
    AIAnalysis: "AI Analysis Overview",
    performance: "Current Performance",
    top10Relevance: "Top 10 High Relevance",
    profile: "Profile",
    price: "Price",
    analysisInstructions: "Analysis Instructions",
    referenceFactors: "Reference Factors",
    historicalScore: "Historical Score",
    backtest: "Strategy Backtest",
    institutionHolding: "Institution Holding",
    insiderTransaction: "Insider Transaction",
    dividendReferenceFactor: "Dividend Reference Factor",
    historicalDividend: "Historical Dividend Policy",
    listOfPages: "List of Pages",
    forecast: "Financial Forecast",
    revenue: "Earnings Table",
    institutionalInvestors: "Institutional Investors",
    marginTradingShortSelling: "Retail Long/Short Margin",
    shareholderDiversification: "Holdings of Investors",
    insiderHolding: "Holdings of Directors and Supervisors",
  },
};
