export const pricing = {
  zh: {
    title: "個股探勘訂閱方案",
    text: "依據您的投資需求提供多項訂閱方案，隨選即用開始你的主動投資",
    monthly: "每月",
    yearly: "每年",
    buttonText: {
      getStarted: "開始使用",
      chooseThisPlan: "選擇此方案",
      buyNow: "購買此方案",
      enterprise: "已為企業會員",
      disabled: "即將上線！",
    },
    comingSoon: "Growin 即將重磅推出！",
    moreContent: [
      "4 堂美股交易課程無限回放",
      "每月 2 次美股分析直播",
      "WinwinGPT 投資分析",
    ],
    anyQuestions: "對於個股探勘訂閱服務有什麼疑問呢？",
    currentPlan: "目前使用方案",
    reachBrowsingLimit: (times: number) => `每日瀏覽已達上限 ${times} 次`,
    unlockBrowsingLimit: "可付費解鎖瀏覽限制",
    reachScreenerLimit: (times: number) => `每週篩選次數已達上限 ${times} 次`,
    unlockScreenerLimit: "可付費解鎖篩選限制",
    vipTitle: "給 VIP 尊榮會員的小說明： ",
    vipContent: ({
      basicPlanName,
      basicPrice,
      premiumPlanName,
      premiumPrice,
    }: {
      basicPlanName: string;
      basicPrice: number;
      premiumPlanName: string;
      premiumPrice: number;
    }) =>
      `由於 Growin 合作的第三方支付系統限制，您可能會在即將前往的後台系統中看到您是屬於「$${basicPrice} 的${basicPlanName}」。但請不用擔心！您已是我們的 VIP 會員，在沒有取消訂閱目前方案的狀況下，您將永久得到 **「以 $${premiumPrice} 的價格升級使用${premiumPlanName}」** 的優惠！所以您在 Growin 個股探勘服務中的權限使用，依然是正常的喔！`,
    ok: "了解",
    goPayment: "不用，直接前往付款",
    findOutNow: "立即前往了解",
    reminder: "訂閱小提醒：",
    reminderContent:
      "「進階方案年訂閱」還有提供交易課程相關的服務及優惠，是否要參考看看呢？",
    continueWatch: "是否繼續觀看？",
    continue: "繼續",
    cancel: "取消",
  },
  en: {
    title: "Plans & Pricing",
    text: "Start a best plan for your investment journey",
    monthly: "Monthly",
    yearly: "Annually",
    buttonText: {
      getStarted: "Get Started",
      chooseThisPlan: "Choose This Plan",
      buyNow: "Buy Now",
      enterprise: "Enterprise Member",
      disabled: "Coming Soon!",
    },
    comingSoon: "More Content Coming Soon!",
    moreContent: [
      "4 Recorded Courses",
      "2 Live Analysis",
      "WinwinGPT Analysis",
    ],
    anyQuestions: "Any questions about the subscription service?",
    currentPlan: "Current Plan",
    reachBrowsingLimit: (times: number) =>
      `The daily browsing limit has been reached ${times} times`,
    unlockBrowsingLimit:
      "Unlocking browsing restrictions is available for a fee.",
    reachScreenerLimit: (times: number) =>
      `The weekly filtering limitation of ${times} times has been reached.`,
    unlockScreenerLimit: "You can pay to unlock the filtering limit.",
    vipTitle: "Friendly reminder to our VIP member:",
    vipContent: ({
      basicPlanName,
      basicPrice,
      premiumPlanName,
      premiumPrice,
    }: {
      basicPlanName: string;
      basicPrice: number;
      premiumPlanName: string;
      premiumPrice: number;
    }) =>
      `Due to restrictions from the third-party payment system partnered with Growin, you may see yourself listed under the "$${basicPrice} ${basicPlanName} Plan" in the upcoming backend system. No worries! As our VIP member, without unsubscribing, you'll permanently enjoy the **"Upgrade to ${premiumPlanName} at $${premiumPrice}"** offer. Your access in Growin Stock Mining remains unaffected.`,
    ok: "OK",
    goPayment: "No, go to payment directly",
    findOutNow: "Find out now",
    reminder: "Subscription Reminder:",
    reminderContent:
      "The annual subscription of the Premium Plan also provides trading course services and offers. Would you like to check it out?",
    continueWatch: "Continue watching?",
    continue: "Continue",
    cancel: "Cancel",
  },
};
