export const technical = {
  zh: {
    title: {
      sma20Day: "20 日均線",
      sma50Day: "50 日均線",
      sma100Day: "100 日均線",
      sma150Day: "150 日均線",
      sma200Day: "200 日均線",
      highLow20Day: "20 日高/低",
      highLow50Day: "50 日高/低",
      highLow52Week: "52 周高/低",
      highLowAllTime: "歷史高/低",
      atr: "ATR",
      high20Day: "20 日高",
      high50Day: "50 日高",
      high52Week: "52 周高",
      highAllTime: "歷史高",
      low20Day: "20 日低",
      low50Day: "50 日低",
      low52Week: "52 周低",
      lowAllTime: "歷史低",
      dailyMaAlignment: "日線均線排列",
      weeklyMaAlignment: "週線均線排列",
      smaSlope: "均線斜率",
      maroc50Day: "50 日均線變動率",
      maroc100Day: "100 日均線變動率",
      maroc200Day: "200 日均線變動率",
    },
    dailyMaAlignment: {
      bullish8EMA: "多頭排列（8 EMA > 21 EMA > 55 EMA）",
      bullish50MA: "多頭排列（50 MA > 150 MA > 200 MA）",
      bearish8EMA: "空頭排列（8 EMA < 21 EMA < 55 EMA）",
      bearish50MA: "空頭排列（50 MA < 150 MA < 200 MA）",
    },
    weeklyMaAlignment: {
      bullish8EMA: "多頭排列（8 EMA > 21 EMA > 55 EMA）",
      bullish50MA: "多頭排列（50 MA > 150 MA > 200 MA）",
      bearish8EMA: "空頭排列（8 EMA < 21 EMA < 55 EMA）",
      bearish50MA: "空頭排列（50 MA < 150 MA < 200 MA）",
    },
    smaSlope: {
      maroc50Above0: "50 日 MAROC(20) >0",
      maroc100Above0: "100 日 MAROC(20) >0",
      maroc200Above0: "200 日 MAROC(20) >0",
    },
  },
  en: {
    title: {
      sma20Day: "20-day SMA",
      sma50Day: "50-day SMA",
      sma100Day: "100-day SMA",
      sma150Day: "150-day SMA",
      sma200Day: "200-day SMA",
      highLow20Day: "20-day High/Low",
      highLow50Day: "50-day High/Low",
      highLow52Week: "52-Week High/Low",
      highLowAllTime: "All-Time High/Low",
      atr: "ATR",
      high20Day: "20-day High",
      high50Day: "50-day High",
      high52Week: "52-Week High",
      highAllTime: "All-Time High",
      low20Day: "20-day Low",
      low50Day: "50-day Low",
      low52Week: "52-Week Low",
      lowAllTime: "All-Time Low",
      dailyMaAlignment: "Daily MA Alignment",
      weeklyMaAlignment: "Weekly MA Alignment",
      smaSlope: "SMA Slope",
      maroc50Day: "50-Day MAROC",
      maroc100Day: "100-Day MAROC",
      maroc200Day: "200-Day MAROC",
    },
    dailyMaAlignment: {
      bullish8EMA: "Bullish ( 8 EMA > 21 EMA > 55 EMA)",
      bullish50MA: "Bullish ( 50 MA > 150 MA > 200 MA)",
      bearish8EMA: "Bearish ( 8 EMA < 21 EMA < 55 EMA)",
      bearish50MA: "Bearish ( 50 MA < 150 MA < 200 MA)",
    },
    weeklyMaAlignment: {
      bullish8EMA: "Bullish ( 8 EMA > 21 EMA > 55 EMA)",
      bullish50MA: "Bullish ( 50 MA > 150 MA > 200 MA)",
      bearish8EMA: "Bearish ( 8 EMA < 21 EMA < 55 EMA)",
      bearish50MA: "Bearish ( 50 MA < 150 MA < 200 MA)",
    },
    smaSlope: {
      maroc50Above0: "50-day MAROC(20) >0",
      maroc100Above0: "100-day MAROC(20) >0",
      maroc200Above0: "200-day MAROC(20) >0",
    },
  },
};
