import { Oriented } from "@/data/constants/oriented";

import { shared } from "./shared";

export const relatedSymbol = {
  zh: {
    title: ({ symbol, oriented }: { symbol: string; oriented: string }) =>
      `${symbol} 同產業標的${oriented}分數`,
    symbolName: "標的名稱",
    marketCap: "市值",
    relevance: ({ symbol }: { symbol: string }) => `與 ${symbol} 的相關性`,
    force: ({ orientedName }: { orientedName: string }) =>
      `個股${orientedName}力道`,
    description: ({
      symbol,
      oriented,
    }: {
      symbol: string;
      oriented: Oriented;
    }) =>
      `以下列出與 ${symbol} 同產業且相關性較高的 10 檔標的${shared.zh.oriented[oriented]}力道狀態，提供相似類型股票進行比較，若有興趣的標的可透過加入追蹤清單，或進入該股票頁面查看相關的資訊與評分。`,
    viewAll: "查看全部",
    viewLess: "顯示較少",
  },
  en: {
    title: ({ symbol, oriented }: { symbol: string; oriented: string }) =>
      `Top 10 High Relevance to ${symbol} ${oriented} Score`,
    symbolName: "Symbol",
    marketCap: "Market Cap",
    relevance: ({ symbol }: { symbol: string }) => `Relevance with ${symbol}`,
    force: ({ orientedName }: { orientedName: string }) => orientedName,
    description: ({
      symbol,
      oriented,
    }: {
      symbol: string;
      oriented: Oriented;
    }) =>
      `Stocks with high relevance and similar industry as ${symbol} for comparing their ${shared.en.oriented[oriented]} score.`,
    viewAll: "View All",
    viewLess: "View Less",
  },
};
