import { buyingSellingLatestStatus } from "./buying-selling-latest-status";

export const institutionalInvestors = {
  zh: {
    title: "三大法人",
    price: "股價",
    foreign: "外資",
    dealer: "自營商",
    trust: "投信",
    holdingRatio: "持股比例",
    BuySellSuperiority: "買賣超狀況",
    days: "日",
    date: "日期",
    sum: "合計",
    showAll: "查看全部",
    showLess: "顯示較少",
    buyingSellingLatestStatus: buyingSellingLatestStatus.zh,
    accumulatedShares: ({ days }: { days: string }) => `近 ${days} 日累積張數`,
    overboughtDays: ({ days }: { days: string }) => `${days} 日中買超天數`,
  },
  en: {
    title: "Institutional Investors",
    price: "Price",
    foreign: "Foreign Investors",
    dealer: "Dealer",
    trust: "Investment Trust",
    holdingRatio: "Holding Percentage",
    BuySellSuperiority: "Net Buying & Selling",
    days: " Days",
    date: "Date",
    sum: "Total",
    showAll: "Read More",
    showLess: "Read Less",
    buyingSellingLatestStatus: buyingSellingLatestStatus.en,
    accumulatedShares: ({ days }: { days: string }) =>
      `Accumulated K Shares in Last ${days} days`,
    overboughtDays: ({ days }: { days: string }) =>
      `Days of Net Buying in Last ${days} days`,
  },
};
