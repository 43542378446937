export const roboAdvisor = {
  zh: {
    header: [
      "您的積蓄可以更有效率地被運用",
      "Growin 正為此而存在",
      "Growin 以被動投資為基礎，搭配獨家的機器學習技術，讓您的每分錢都投資在對的資產上，不只為您分散投資風險，也讓您參與股市成長機會。",
    ],
    introduction: [
      "我們幫您準備好理財一切所需",
      "您只需要設定好投資目標與人生願景，其他的 Growin 幫您打點，從複雜的市場研究、數據分析到資產配置、下單贖回，都不用你來操心！",

      "智能演算法說了算",
      "Growin 智能演算法的紀律與自動化投資決策過程，不需人為介入",

      "節省不必要的成本",
      "從市場分析到實際交易一切自動化，省下大部分時間與費用成本",

      "專門為您量身打造",
      "風險屬性、投資目標、市場狀況都納入考量，打造真正適合您的投資組合",
    ],
    program: {
      text: [
        "您的投資組合是什麼？",
        "我的風險級數",
        "預期年化報酬",
        "僅收",
        "% 年管理費",
        "投資組合策略分佈",
        "ETF 資產配置",
        "個股價值投資",
        "投資組合資產比例",
        "現金",
        "債券",
        "股票",
        "抗通膨",
      ],
      lv: [
        "保守型投資組合",
        "我們根據諾貝爾得獎之投資理論設計這組投資策略，我們將在風險程度設定為低的情形下，追求最佳的投資報酬。這組策略主要投資在有穩定殖利率且本身價值波動低的投資標的，如此確保您的投資本金不易被侵蝕，且能定期貢獻穩定的利息報酬。如果您是為了您的退休生活準備、或想要讓您的資金比待在銀行帳戶裡有更好的運用，這會是您最好的選擇。",
        "穩健型投資組合",
        "我們根據諾貝爾得獎之投資理論設計這組投資策略，這組策略主要將投資分散於全球各主要股市、債券指數及原物料等抗通膨資產，我們將在風險程度設定為中等偏低的情形下，追求最佳的投資報酬。這組策略是以能穩建地累積獲利且同時著眼於標的未來可能的增值。",
        "成長型投資組合",
        "我們運用多重投資策略，一部分策略進行指數型基金的投資，以達到分散風險的效果；一部分則加入積極的價值型個股投資策略，由 Growin 人工智能引擎挑選最具潛力的股票，讓你在穩健的投資方法下仍有機會參與優質企業成長所帶來的價值。由於對風險管控的靈活反應，一定程度地降低了潛在損失，這組策略能提供你在追求報酬及保本間一個最好的平衡。",
        "積極型投資組合",
        "我們運用多重投資策略，此投資組合著重於價值型個股投資策略，由 Growin 人工智能引擎挑選最具潛力的股票，最大限度的提升投資報酬，當然伴隨而來的是相對較高的風險，因此我們將其中一部分資產進行指數型基金的配置，以合理得調控投資風險。",
      ],
    },
  },
  en: {
    header: [
      "Your Savings Could Be More Wisely!",
      "",
      "We bring passive investing, plus self-derived machine learning techniques to Growin. Every dollar of yours will be invested properly. Not just diversifying investment risk, but also catch the opportunity of growth. ",
    ],
    introduction: [
      "We Get All You Need Ready",
      "The only thing we need is your financial goals, you don't have to worry about doing market research, data analysis, asset allocation, or even trade. Growin is all set.",

      "Algorithm Does All",
      "Growin's intelligent algorithm makes all the investing processes disciplined and automated. No human intervention.",

      "No Unnecessary Cost",
      "From market analysis to trading, all processes are automated, so that we can keep the costs on time and money low.",

      "Tailor-made for You",
      "We consider your risk, investment goals, and current market environment to design a portfolio right for you.",
    ],
    program: {
      text: [
        "What's Your Portfolio Look Like?",
        "My Risk Level",
        "Expected Return:",
        "Annual Fee:",
        "%",

        "Strategy Mix",
        "ETF Asset Allocation",
        "Stock Value Investing",

        "Portfolio Asset Weight",
        "Cash",
        "Bond",
        "Stock",
        "Inflation Protected",
      ],
      lv: [
        "Conservative Portfolio",
        "Built on Nobel Prize-winning research, this portfolio is a modern fixed income portfolio, chosen to help provide optimal returns at low risk. This portfolio focuses on getting exposure to different asset classes with high yields to keep your portfolio incomes stable, reduce risk and stay ahead of inflation. If you are preparing for your retirement or tired of low interest rate from your bank, it's a perfect alternative to a cash savings account.",
        "Moderate Portfolio",
        "Built on Nobel Prize-winning research, this portfolio is a globally diversified mix of stock and bond index funds, chosen to help provide optimal returns at moderate risk. This portfolio is nimble, not particularly in favor of stocks or bonds market. It will base on both markets sentiment to decide the allocation weights on each asset classes. Although this portfolio only takes moderate risk, it can effectively assist you to accomplish your financial goals by mean of growing and preserving capital over time.",
        "Growth Portfolio",
        "We use multiple investing strategies, combined AI-driven asset allocation and value investing in stocks, to help you participate the stock market growth but in the meantime to smooth out possible bumps. Want to excel in life? This portfolio can color your life if you want to grow your capital quickly.",
        "Aggressive Portfolio",
        "We use multiple investing strategies, focused on value investing strategies in stocks with proper hedge mechanism in place. This portfolio is aim for higher return along with higher risk.  If you want to grow your capital aggressively, this is the one for you!",
      ],
    },
  },
};
