export const dialog = {
  zh: {
    detectChanges: "主動為您偵測上市公司變化",
    detectChangesDescription:
      "透過推播通知功能，讓系統主動為您偵測標的變化。每日達到您設定的條件時，會立即通知您！",
    guest: "未註冊",
    free: "免費方案",
    beginning: "入門方案",
    basic: "基本方案",
    premium: "進階方案",
    signUp: "前往註冊",
    viewPlans: "查看方案",
    ok: "確定",
    close: "關閉",
    currentPlan: "當前方案",
    sets: (count: number) => `${count} 組策略`,

    bindLine: "請先綁定 Line 好友",
    paymentFunction: "此功能為付費功能",
    lineNotify: "Line 推播室",
    realTimeNotification: "透過 LINE 接收即時推播通知",
    linePipeline: "LINE 通知策略管道",
    upgrade: "查看方案",
    goBinding: "前往 Line 綁定",

    resumeRobot: "重新啟用推播策略",
    resumeRobotDescription: "確定要重新啟用",
    cancel: "取消",
    confirm: "確認",

    deleteRobot: "刪除推播策略",
    deleteRobotDescription: "確定要刪除",
  },
  en: {
    detectChanges: "Detect Corporate Changes",
    detectChangesDescription:
      "Get real-time notifications through robot alerts. Be instantly notified when your set conditions are met!",
    guest: "Guest",
    free: "Freemium",
    beginning: "Beginning",
    basic: "Basic",
    premium: "Premium",
    signUp: "Start Now",
    viewPlans: "Upgrade",
    ok: "OK",
    close: "Close",
    currentPlan: "Current Plan",
    sets: (count: number) => `${count} Sets`,

    bindLine: "Please bind Line first",
    paymentFunction: "User Charge",
    lineNotify: "Line Notify",
    realTimeNotification: "Real-time Notification via LINE",
    linePipeline: "Get Notifications via LINE",
    upgrade: "Upgrade",
    goBinding: "Go to Line Binding",

    resumeRobot: "Resume notification strategy",
    resumeRobotDescription: "Are you sure to resume",
    cancel: "Cancel",
    confirm: "Confirm",

    deleteRobot: "Delete notification strategy",
    deleteRobotDescription: "Are you sure to delete",
  },
};
