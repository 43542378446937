export const historicalRating = {
  zh: {
    title: (symbol: string, orientedName: string) =>
      `${symbol} ${orientedName}歷史分數`,
    tip: "可以點選下方的分數選項，選擇只想要看到的評分狀態",
    maskMessage:
      "透過歷史分數了解過往的股價與評分之間的關係，進而找到突出的股價表現，建立屬於自己的投資策略",
    maskButton: "升級方案以解鎖內容",
    description:
      "投資人可透過 AI 歷史分數於 3 分以上時對應股價的走勢，觀察是否有比較突出的投資表現。",
  },
  en: {
    title: (symbol: string, orientedName: string) =>
      `${symbol} Historical ${orientedName} Score`,
    tip: "You can click the score below to show the status you want to see.",
    maskMessage:
      "Understand the relationship between past stock prices and scores through historical scores, and then find outstanding stock price performance to establish your own investment strategy",
    maskButton: "Upgrade to unlock content",
    description:
      "You can use historical scores of 3 or above to  observe potential performance and make investment decisions.",
  },
};
