export const insider = {
  zh: {
    tradeAt: "交易日期",
    close: "收盤價",
    tradingAmount: "交易金額",
    tradingShares: "交易股數",
    fillingAt: "申報日期",
    buyerName: "購買人",
    buyerTitle: "購買人職稱",
    tradeNumAfter: "交易後持股數",
    stockCategory: "證券類型",
    tradingOverview: "內部人員交易概況",
    top5InsiderOwnerShipChange: "交易量前五大內部人員持股變化",
    detailsOfInsiderTrading: "近一年內部人員交易明細",
    details: "詳細資料",
    dialogDescription:
      "以下我們列出近一季的內部人員交易資訊，包含了這間公司中董事、持股逾 10% 的股東與高階主管的購買紀錄。",
    shares: "股",
    total: "共",
    viewAll: "查看全部",
    viewLess: "顯示較少",
  },
  en: {
    tradeAt: "Transaction Date",
    close: "Close",
    tradingAmount: "Amount",
    tradingShares: "Shares",
    fillingAt: "Filing Date",
    buyerName: "Buyer",
    buyerTitle: "Position",
    tradeNumAfter: "Holding Shares",
    stockCategory: "Type",
    tradingOverview: "Insider Transactions",
    top5InsiderOwnerShipChange: "Top 5 Insider Shareholders",
    detailsOfInsiderTrading: "Insider Transactions Details",
    details: "Data",
    dialogDescription:
      "The following presents insider transactions over the past year, including directors, shareholders with holdings exceeding 10%, and senior executives.",
    shares: "Shares",
    total: "Total",
    viewAll: "View All",
    viewLess: "View Less",
  },
};
