type AnalysisInfo = {
  symbol: string;
  companyName: string;
};

export const analysis = {
  zh: {
    title: {
      search: "Growin AI 美股分析 | 美股推薦",
      overview: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - 即時股價 & AI 分析 - Growin`,
      value: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - AI 價值面分析 | 價值 - Growin`,
      trend: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - AI 趨勢面分析 | 趨勢 - Growin`,
      swing: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - AI 波段面分析 | 波段 - Growin`,
      chip: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - AI 籌碼面分析 | 籌碼 - Growin`,
      dividend: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - AI 股利面分析 | 股利 - Growin`,
    },
    description: {
      search:
        "Growin AI 美股分析平台，使用獨家量化指標、深入個股分析，視覺化圖表呈現，並提供財報資訊與機構動向，數據導向美股推薦系統，找到理想投資標的！",
      overview: ({ symbol }: { symbol: string }) =>
        `${symbol} 即時股價、AI 個股分析。我們透過 AI 統整財報基本面、技術面、籌碼面等數據，轉化為直觀的五力面向評分，幫助投資人找到強勢潛力股。`,
      value: ({ symbol }: { symbol: string }) =>
        `${symbol} 的 AI 價值面分析提供了該公司價值面的多種數據及因子資訊（如：本益比、ROE），並透過獨家的 AI 演算法統合成直觀的評分。`,
      trend: ({ symbol }: { symbol: string }) =>
        `${symbol} 的 AI 趨勢面分析提供了該公司技術分析、趨勢面的詳細數據，如：獨家 AI 技術分析量化數值、均線、ATR、波動率，並透過獨家的 AI 演算法統合成直觀的評分。`,
      swing: ({ symbol }: { symbol: string }) =>
        `${symbol} 的 AI 波段面分析考慮了量價累積現況與波段起漲、回跌、盤整，並透過進出場回測，有效評估多頭空頭力道和波段行情，幫助波段交易策略。`,
      chip: ({ symbol }: { symbol: string }) =>
        `${symbol} 的 AI 籌碼面分析參考 13F 大型機構策略佈局、資金流向，並搭配內部人持股評估交易資訊，了解大戶機構持倉與籌碼動向！`,
      dividend: ({ symbol }: { symbol: string }) =>
        `${symbol} 的 AI 股利面分析提供了該公司股利面的詳細數據，如：該公司的殖利率、填息天數、填權息率等，並透過獨家的 AI 演算法統合成直觀的評分。`,
    },
  },
  en: {
    title: {
      search: "Growin AI Analysis | AI-Powered Stock Analysis for Investor",
      overview: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - Stock Quote & AI Analysis - Growin`,
      value: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - Stock Value & PE Ratio Analysis - Growin`,
      trend: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - Stock Trend Analysis & Technical Analysis - Growin`,
      swing: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - Stock Swing Trading Analysis - Growin`,
      chip: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - Stock Whale Interest Analysis - Growin`,
      dividend: ({ symbol, companyName }: AnalysisInfo) =>
        `${symbol} | ${companyName} - Stock Dividend Analysis - Growin`,
    },
    description: {
      search:
        "Growin AI Analysis elevates your investment strategies with 5-Dimensional Analysis, offering profound insights and game-changing market perspectives.",
      overview: ({ symbol }: { symbol: string }) =>
        `${symbol} | Stock analysis includes stock price, stock chart, key statistics, and 5-dimensional analysis to assist your stock trading and investing.`,
      value: ({ symbol }: { symbol: string }) =>
        `${symbol} | Growin AI Value Analysis provides a range of data and factor information, such as YoY revenue growth and ROE, which is then synthesized into a score using our proprietary AI algorithms.`,
      trend: ({ symbol }: { symbol: string }) =>
        `${symbol} | By utilizing Growin Trend Analysis, we can manage our risk by capturing significant profits when favorable trends emerge and implementing stop-loss strategies when unfavorable trends occur.`,
      swing: ({ symbol }: { symbol: string }) =>
        `${symbol} | With the accumulation of vast technical analysis data and the advancement of AI technology, Growin’s Swing Trading Analysis can effectively assist swing traders in their investment decisions.`,
      chip: ({ symbol }: { symbol: string }) =>
        `${symbol} | Growin's Whale Interest analysis provides a succinct summary of crucial information about institutional activities in individual stocks and employs AI algorithms to calculate an intuitive AI Whale Interest score based on this information.`,
      dividend: ({ symbol }: { symbol: string }) =>
        `${symbol} | Stock Dividend Analysis includes metrics like dividend yield, dividend payout dates, weighted dividend yield, and more. Growin employs these statistics as reference points for assessment.`,
    },
  },
};
