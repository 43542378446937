export const tw = {
  zh: {
    title: {
      sector: "板塊",
      industry: "產業",
      marketCap: "市值",
      retailLongMarginUsage: "融資使用率",
      retailMarginRatio: "券資比",
      rsRating: "RS Rating (Relative Strength)",
    },
    marketCap: {
      microCap: "微型股 (<10B)",
      smallCap: "小型股 (10B-50B)",
      midCap: "中型股 (50B-200B)",
      largeCap: "大型股（>200B)",
      lteMidCap: "≤ 中型股 (低於 50B)",
      lteSmallCap: "≤ 小型股 (低於 10B)",
      gteSmallCap: "≥ 小型股 (超過 10B)",
      gteMidCap: "≥ 中型股 (超過 50B)",
    },
    retailLongMarginUsage: {
      under30: "低於 30%",
      under25: "低於 25%",
      under20: "低於 20%",
      under15: "低於 15%",
      under10: "低於 10%",
      under5: "低於 5%",
      over5: "超過 5%",
      over10: "超過 10%",
      over15: "超過 15%",
      over20: "超過 20%",
      over25: "超過 25%",
      over30: "超過 30%",
    },
    rsRating: {
      oneToForty: "1 到 40",
      fortyToSeventy: "40 到 70",
      seventyToNinety: "70 到 90",
      overFifty: "超過 50",
      overSeventy: "超過 70",
      overNinety: "超過 90",
    },
    volume20MA: {
      under50K: "低於 50K",
      under50Kto1M: "50K - 1M",
      under1Mto5M: "1M - 5M",
      under5Mto10M: "5M - 10M",
      under10Mto50M: "10M - 50M",
      over1M: "超過 1M",
      over5M: "超過 5M",
      over10M: "超過 10M",
      over50M: "超過 50M",
    },
  },
  en: {
    title: {
      sector: "Sector",
      industry: "Industry",
      marketCap: "Market Cap",
      retailLongMarginUsage: "Retail Long Margin Usage",
      retailMarginRatio: "Retail Margin Ratio",
      rsRating: "RS Rating (Relative Strength)",
    },
    marketCap: {
      microCap: "Micro (<10B)",
      smallCap: "Small (10B to 50B)",
      midCap: "Mid (50B to 200B)",
      largeCap: "Large (Over 200B)",
      lteMidCap: "≤ Mid (under 50B)",
      lteSmallCap: "≤ Small (under 10B)",
      gteSmallCap: "≥ Small (over 10B)",
      gteMidCap: "≥ Mid (over 50B)",
    },
    retailLongMarginUsage: {
      under30: "Under 30%",
      under25: "Under 25%",
      under20: "Under 20%",
      under15: "Under 15%",
      under10: "Under 10%",
      under5: "Under 5%",
      over5: "Over 5%",
      over10: "Over 10%",
      over15: "Over 15%",
      over20: "Over 20%",
      over25: "Over 25%",
      over30: "Over 30%",
    },
    rsRating: {
      oneToForty: "1 to 40",
      fortyToSeventy: "40 to 70",
      seventyToNinety: "70 to 90",
      overFifty: "over 50",
      overSeventy: "over 70",
      overNinety: "over 90",
    },
    volume20MA: {
      under50K: "Under 50K",
      under50Kto1M: "50K to 1M",
      under1Mto5M: "1M to 5M",
      under5Mto10M: "5M to 10M",
      under10Mto50M: "10M to 50M",
      over1M: "Over 1M",
      over5M: "Over 5M",
      over10M: "Over 10M",
      over50M: "Over 50M",
    },
  },
};
