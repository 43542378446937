export const home = {
  zh: {
    title: "Growin | 全方位投資理財工具",
    description:
      "Growin 致力於將 AI 人工智慧與交易投資策略進行結合，透過大數據收集及分析，為投資人打造被動穩健的智能理財服務及主動積極的投資分析工具。",
  },
  en: {
    title: "Growin | Lift your financial future with the power of AI",
    description:
      "Growin combines AI and trading strategies, utilizing big data to offer passive financial services and active investment tools for investors.",
  },
};
