export const account = {
  zh: {
    title: "Growin | 我的帳戶",
    description:
      "Growin 個股健診、個股走勢、個股分析工具：財報基本面及量價技術面分析，診斷美國個股標的價值、籌碼、股利、走勢、波段五大面向，掌握美股標的表現，找出投資潛力價值股！",
  },
  en: {
    title: "Growin | My Account",
    description:
      "Growin combines AI and trading strategies, utilizing big data to offer passive financial services and active investment tools for investors.",
  },
};
