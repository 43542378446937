export const earnings = {
  zh: {
    title: (symbol: string) => `${symbol} 收益表`,
    header: {
      quarter: "季度",
      eps: "Non-GAAP EPS",
      epsYoy: "EPS 年增率",
      epsSurprise: "EPS Surprise %",
      income: "營收",
      incomeYoy: "營收年增率",
      incomeSurprise: "營收 Surprise %",
      netProfitMargin: "淨利率",
    },
  },
  en: {
    title: (symbol: string) => `${symbol} Earnings Table`,
    header: {
      quarter: "QTR",
      eps: "Non-GAAP EPS",
      epsYoy: "EPS YoY",
      epsSurprise: "EPS Surprise %",
      income: "Sales",
      incomeYoy: "Sales YoY",
      incomeSurprise: "Sales Surprise %",
      netProfitMargin: "NPM",
    },
  },
};
